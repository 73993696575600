import { FormEvent, useCallback, useState } from "react";
import useTransaction from "../../../services/transaction";
import "./ThuaDat.css";
import { Map } from "ol";
import { useNavigate } from "react-router-dom";
import Button from "../../../Shared/Button/Button";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import SelectFloatLabel, { Option } from "../../../Shared/select/SelectFloatLabel";
import { toastService } from "../../../services/toast.service";
import { LayerName } from "../../../common/constants";
import { DomainPhuongXa } from "../../../common/domain";
import useQuery from "../../../hooks/useQuery";
import useIntegrateAPI from "../../../services/integrate-api";
import { HienTrangSDD } from "../../../common/definitions";
import useTransactionHienTrang from "../../../services/hienTrang.service";
import { and, equalTo } from "ol/format/filter";

function ThuaDat({ map }: { map?: Map }) {
  const navigate = useNavigate();
  const { searchParams } = useQuery();
  const { logGis } = useIntegrateAPI();

  const { getFeatures } = useTransactionHienTrang();
  const [to, setTo] = useState<string>();
  const [soThua, setThua] = useState<string>();
  const [maXaPhuong, setMaXaPhuong] = useState<string>();
  const { warning } = toastService();

  const onSearch = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault();

      if (map) {
        if (!maXaPhuong || !soThua || !to) {
          return;
        }
        getFeatures({
          filter: and(equalTo("SoTo", to), equalTo("SoThua", soThua), equalTo("MaPX", maXaPhuong)),
        }).then(({ features }) => {
          const [feature] = features;

          if (feature) {
            const {
              SoNha: soNha,
              SoTo: soTo,
              MaPX: tenPhuongXa,
              SoThua: soThua,
              TenDuong: tenDuong,
            } = feature.properties as HienTrangSDD;
            logGis({ soNha, soThua, soTo, tenDuong, tenPhuongXa });
            const id = feature.id;
            if (id) {
              searchParams.set("fid", id.toString());
              navigate({
                pathname: "/map/place",
                search: searchParams.toString(),
              });
            }
          } else {
            warning("Không tìm thấy kết quả phù hợp!");
          }
        });
      }
    },
    [getFeatures, logGis, maXaPhuong, map, navigate, searchParams, soThua, to, warning],
  );

  return (
    <form action="" onSubmit={onSearch}>
      <div>
        <SelectFloatLabel
          onChange={(e) => {
            setMaXaPhuong(e.target.value);
          }}
          placeholder="Phường/xã"
          required
          value={maXaPhuong}
        >
          <Option value={""}>Tất cả</Option>
          {DomainPhuongXa.map((m) => (
            <Option value={m.code} key={m.code}>
              {m.name}
            </Option>
          ))}
        </SelectFloatLabel>
        {maXaPhuong === "" && <p className="text-error">Vui lòng chọn phường xã</p>}
        <InputFloatLabel
          value={to}
          onChange={(e) => setTo(e.target.value)}
          type="number"
          placeholder="Số tờ bản đồ"
          required
        />
        {to === "" && <p className="text-error">Vui lòng nhập số tờ</p>}
        <InputFloatLabel
          value={soThua}
          onChange={(e) => setThua(e.target.value)}
          type="number"
          placeholder="Số thửa đất"
          required
        />
        {soThua === "" && <p className="text-error">Vui lòng nhập số thửa</p>}
      </div>
      <div style={{ marginTop: 8, display: "grid" }}>
        <Button type="submit" className="button-primary">
          Tìm kiếm
        </Button>
      </div>
    </form>
  );
}

export default ThuaDat;
