import classNames from "classnames";
import { cloneElement } from "react";
import "./style.css";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element;
}

const Button: React.FC<Props> = ({ children, onClick, className, icon, style, ...props }) => {
  return (
    <button
      {...props}
      onClick={onClick}
      className={classNames("button-shared", { "btn-icon": children === undefined }, className)}
      style={style}
    >
      {icon !== undefined && (
        <span style={{ marginRight: 2, display: "inherit" }}>{cloneElement(icon, { size: 16 })}</span>
      )}
      {children}
    </button>
  );
};
export default Button;
