import classNames from "classnames";
import { Map } from "ol";
import Feature from "ol/Feature";
import Geometry from "ol/geom/Geometry";
import VectorSource from "ol/source/Vector";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VectorImageLayer from "ol/layer/VectorImage";
import { FeatureCollection, Geometry as GeometryGeoJson } from "geojson";
import { LayerName, MaSddEsc, PARAMS, SRSNAME, WORKSPACES } from "../../common/constants";
import "./popup.css";
import { HienTrangSDD, QuyHoachSDD, ThongTinDoAn } from "../../common/definitions";
import { MoTaHienTrang } from "../map/MapComponent";
import { dmMucDichSdd, dmPhuongXa } from "../../common/domain";
import instanceAxios, { formatNumber } from "../../common/utils";
import WFS from "ol/format/WFS";
import Intersects from "ol/format/filter/Intersects";
import { varConfig } from "../../common/var-config";
import InfoDoAn from "./InfoDoAn";
import useWriteTransaction from "../../services/transaction.service";
import BackComponent from "./BackComponent";
import { readFeature } from "../../common/map.util";
import { FaFileExport } from "react-icons/fa";
import { BsZoomIn } from "react-icons/bs";
import InfoGPXD from "./info-external/InfoGPXD";
import useQuery from "../../hooks/useQuery";
import InfoQuyHoach from "./quy-hoach/InfoQuyHoach";
import InfoLoGioi from "./InfoLoGioi";
import SLDParser from "geostyler-sld-parser";
import { Rule, ComparisonFilter } from "geostyler-style";
import InfoNCCDMDSSD from "./info-external/InfoNCCDMDSDD";
import InfoGCNQSDD from "./info-external/InfoGCNQSDD";
import InfoSoNha from "./info-external/SoNha";
import LogBienDong from "./LogBienDong";
import useTransactionHienTrang from "../../services/hienTrang.service";

type Props = {
  map?: Map;
  data: Feature[];
  desc: Array<MoTaHienTrang>;
};

type PopupDetail = { data: QuyHoachSDD & HienTrangSDD; thongTinDoAn?: Feature<Geometry> };

export type ContentPopup = {
  layerId: string;
  title: string;
  subTitle: string;
  fid: string;
};

enum InfoTypes {
  GPXD = "GPXD",
  NCCDMDSDD = "NCCDMDSDD",
  GCNSDD = "GCNSDD",
  QHCSuDungDat = "QHCSuDungDat",
  QHTNMT = "QHTNMT",
  DoAn = "DoAn",
}

// eslint-disable-next-line consistent-return
const getLayerId = (fid: string | null) => {
  if (fid) {
    return fid.split(".")[0];
  }
};

function PopupComponent({ map, data, desc }: Props) {
  const { param } = useParams();
  const { getFeatures } = useWriteTransaction<ThongTinDoAn>(LayerName.DO_AN);
  const { searchParams } = useQuery();
  const [isExpand, setIsExpand] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [tenDoAn, setTenDoAn] = useState("");
  const [domainQhpk, setDomainQhpk] = useState<{ [key: string]: any }>({});

  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);
  const [content, setContent] = useState<PopupDetail>();

  const getDomainQhpk = useCallback(() => {
    instanceAxios.get(`rest/workspaces/cuchi/styles/qhpk_sudungdat.sld`).then(({ data }) => {
      const parser = new SLDParser();
      parser.readStyle(data).then(({ output }) => {
        if (output) {
          const domain: { [key: string | number]: any } = {};
          output.rules.forEach((rule) => {
            if (rule.filter) {
              const filter = rule.filter as ComparisonFilter;
              const code = filter[2] as string | number;
              if (["string", "number"].includes(typeof code)) {
                domain[code] = rule.name;
              }
            }
          });
          setDomainQhpk(domain);
        }
      });
    });
  }, []);

  useEffect(() => {
    getDomainQhpk();
  }, []);

  useEffect(() => {
    // setIsEditing(false);
    const parameters = {
      request: "GetFeature",
      version: "1.1.0",
      typeName: `${WORKSPACES}:${LayerName.HIEN_TRANG},${WORKSPACES}:${LayerName.QUY_HOACH}`,
      outputFormat: "JSON",
      FILTER: `(<Filter xmlns="http://www.opengis.net/ogc"><FeatureId fid="${fid}"/></Filter>)(<Filter xmlns="http://www.opengis.net/ogc"><FeatureId fid="${fid}"/></Filter>)`,
    };
    const searchParams = new URLSearchParams(parameters).toString();
    instanceAxios
      .get<FeatureCollection<GeometryGeoJson, QuyHoachSDD & HienTrangSDD>>(`/wfs?${searchParams}`, {
        headers: { "Content-Type": "text/xml" },
      })
      .then((res) => {
        const [feature] = res.data.features;
        if (feature) {
          const popupDetail: PopupDetail = { data: feature.properties };
          const featureReaded = readFeature(feature);
          const geometryFormat = featureReaded.getGeometry();
          if (geometryFormat) {
            var featureRequest = new WFS().writeGetFeature({
              srsName: SRSNAME,
              featureNS: varConfig.geoServerUrl,
              featurePrefix: WORKSPACES,
              featureTypes: [LayerName.DO_AN],
              outputFormat: "application/json",
              filter: new Intersects("geom", geometryFormat, SRSNAME),
            });
            getFeatures({ filter: new Intersects("geom", geometryFormat, SRSNAME), propertyNames: ["TenDoAn"] }).then(
              ({ features }) => {
                let name = "";
                if (features.length) {
                  const [feature] = features;
                  name = feature.properties.TenDoAn;
                }
                setTenDoAn(name);
              },
            );
            instanceAxios
              .post<FeatureCollection<GeometryGeoJson, ThongTinDoAn>>(
                "/wfs",
                new XMLSerializer().serializeToString(featureRequest),
                { headers: { "Content-Type": "text/xml" } },
              )
              .then((result) => {
                const { features } = result.data;
                const [feature] = features;
                popupDetail.thongTinDoAn = undefined;
                if (feature) {
                  popupDetail.thongTinDoAn = readFeature(feature);
                }
                setContent(popupDetail);
              });
          }
        }
      });
  }, [fid, map]);

  const paramSearch = useMemo(() => param, [param]);

  return paramSearch === PARAMS.search || paramSearch === PARAMS.place ? (
    <div className={classNames("popup custom-scrollbar", { collapse: !isExpand })}>
      {paramSearch === PARAMS.place && !!fid && !isEditing && (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <Info info={data} desc={desc} content={content} map={map} domainQhpk={domainQhpk} />
      )}
    </div>
  ) : null;
}

PopupComponent.defaultProps = {
  map: null,
};

export default PopupComponent;

function DetailHienTrang({
  map,
  desc,
  domainQhpk,
}: {
  map: Map;
  desc: Array<MoTaHienTrang>;
  domainQhpk: { [k: string]: any };
}) {
  const { searchParams } = useQuery();
  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);
  const [viewInfo, setViewInfo] = useState(false);
  const [keyShow, setKeyShow] = useState<InfoTypes>();
  const [tenDoAn, setTenDoAn] = useState("");
  const [hienTrang, setHienTrang] = useState<{ properties?: HienTrangSDD; geometry?: Geometry }>({});
  const { getFeatures } = useWriteTransaction<ThongTinDoAn>(LayerName.DO_AN);
  const transactionHienTrang = useTransactionHienTrang();
  const ref = useRef<Feature<Geometry>>();
  const navigate = useNavigate();

  useEffect(() => {
    setViewInfo(false);
    if (fid) {
      transactionHienTrang.getFeature(fid).then(({ features }) => {
        const [feature] = features;
        if (feature) {
          const olFeature = readFeature(feature);
          const geometryFormat = olFeature.getGeometry();
          setHienTrang({ properties: feature.properties, geometry: geometryFormat });
          if (geometryFormat) {
            getFeatures({ filter: new Intersects("geom", geometryFormat, SRSNAME), propertyNames: ["TenDoAn"] }).then(
              ({ features }) => {
                let name = "";
                if (features.length) {
                  const [feature] = features;
                  name = feature.properties.TenDoAn;
                }
                setTenDoAn(name);
              },
            );
          }
        }
      });
    }
  }, [fid, getFeatures, map]);

  const propertiesHienTrang = useMemo(() => hienTrang.properties, [hienTrang.properties]);

  const highlightLayer = useMemo(() => {
    if (map) {
      const interactionLayer = map
        .getAllLayers()
        .find((layer) => layer.get("id") === "HIGHLIGHT-LAYER") as VectorImageLayer<VectorSource>;
      return interactionLayer;
    }
  }, [map]);

  const fitExent = useCallback(
    (geometry: Geometry) => {
      const extent = geometry.getExtent();
      if (extent) {
        map.getView().fit(extent, {
          duration: 300,
        });
      }
    },
    [map],
  );

  const handleLoadEndDoAn = useCallback(
    (feature: Feature<Geometry>) => {
      ref.current = feature;
      if (highlightLayer) {
        highlightLayer.getSource()?.addFeature(feature);
        const geometry = feature.getGeometry();
        geometry && fitExent(geometry);
      }
    },
    [fitExent, highlightLayer],
  );

  const zoomToHienTrang = useCallback(() => {
    if (ref.current) {
      highlightLayer?.getSource()?.removeFeature(ref.current);
      hienTrang.geometry && fitExent(hienTrang.geometry);
    }
  }, [fitExent, hienTrang.geometry, highlightLayer]);

  const onCloseInfo = useCallback(() => {
    setKeyShow(undefined);
  }, []);

  const onCloseInfoLayer = useCallback(() => {
    onCloseInfo();
    zoomToHienTrang();
  }, [onCloseInfo, zoomToHienTrang]);

  const handleClickQuyHoach = useCallback(
    (id: string) => {
      if (id) {
        searchParams.set("fid", id);
        navigate({ pathname: "/map/place", search: searchParams.toString() });
      }
    },
    [navigate, searchParams],
  );

  const onExport = useCallback(() => {
    window.open("/map/place/export/" + fid, "_blank");
  }, [fid]);

  const onZoomIn = useCallback(() => {
    hienTrang.geometry && fitExent(hienTrang.geometry);
  }, [fitExent, hienTrang.geometry]);

  const onClose = useCallback(() => {
    searchParams.delete("fid");
    navigate({ pathname: "/map", search: searchParams.toString() });
  }, [navigate, searchParams]);

  const infoRelative = useCallback(() => {
    const infoComponent: { [key: string]: React.ReactElement } = {
      [InfoTypes.DoAn]: <InfoDoAn loadEnd={handleLoadEndDoAn} onClose={onCloseInfoLayer} />,
      [InfoTypes.NCCDMDSDD]: <InfoNCCDMDSSD onClose={onCloseInfo} data={propertiesHienTrang} />,
      [InfoTypes.GCNSDD]: <InfoGCNQSDD onClose={onCloseInfo} data={propertiesHienTrang} />,
      [InfoTypes.GPXD]: <InfoGPXD onClose={onCloseInfo} data={propertiesHienTrang} />,
      // [InfoTypes.QHCSuDungDat]: <InfoQHCSuDungDat loadEnd={handleLoadEndDoAn} onClose={onCloseInfoLayer} />,
      // [InfoTypes.QHTNMT]: <InfoQHTNMT loadEnd={handleLoadEndDoAn} onClose={onCloseInfoLayer} />,
    };
    return keyShow ? infoComponent[keyShow] : null;
  }, [handleLoadEndDoAn, keyShow, onCloseInfo, onCloseInfoLayer, propertiesHienTrang]);

  return keyShow ? (
    infoRelative()
  ) : (
    <>
      <BackComponent title="Thông tin thửa đất" />
      <div className="detail-wrapper">
        <div className="detail-group">
          <div className="detail-label">Mục đích sử dụng đất</div>
          <div className="detail-text">
            {propertiesHienTrang?.KyHieuLoaiDat && dmMucDichSdd[propertiesHienTrang?.KyHieuLoaiDat]
              ? dmMucDichSdd[propertiesHienTrang?.KyHieuLoaiDat]
              : propertiesHienTrang?.KyHieuLoaiDat}
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Diện tích</div>
          <div className="detail-text">
            {propertiesHienTrang?.DienTich ? formatNumber(propertiesHienTrang?.DienTich) : 0} m<sup>2</sup>
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số tờ</div>
          <div className="detail-text">{propertiesHienTrang?.SoTo}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số thửa</div>
          <div className="detail-text">{propertiesHienTrang?.SoThua}</div>
        </div>
        {/* <div className="detail-group">
          <div className="detail-label">Số nhà</div>
          <div className="detail-text">{propertiesHienTrang?.SoNha}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Tên đường</div>
          <div className="detail-text">{propertiesHienTrang?.TenDuong}</div>
        </div> */}
        <div className="detail-group">
          <div className="detail-label">Phường/xã</div>
          <div className="detail-text">
            {propertiesHienTrang?.MaPX ? dmPhuongXa[propertiesHienTrang.MaPX] : ""}, huyện Củ Chi, Tp Hồ Chí Minh
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ghi chú</div>
          <div className="detail-text">{propertiesHienTrang?.GhiChu}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Nhu cầu chuyển đổi mục đích sử đụng đất</div>
          <div className="detail-text">
            <span className="more-detail" onClick={() => setKeyShow(InfoTypes.NCCDMDSDD)}>
              Xem chi tiết
            </span>
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Giấy chứng nhận sử dụng đất</div>
          <div className="detail-text">
            <span className="more-detail" onClick={() => setKeyShow(InfoTypes.GCNSDD)}>
              Xem chi tiết
            </span>
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Giấy phép xây dựng</div>
          <div className="detail-text">
            <span className="more-detail" onClick={() => setKeyShow(InfoTypes.GPXD)}>
              Xem chi tiết
            </span>
          </div>
        </div>
        <InfoSoNha data={propertiesHienTrang} />
        <InfoLoGioi />
        <h4 style={{ textAlign: "center", padding: "26px 0 16px 0" }}>Thông tin quy hoạch</h4>
        <div className="detail-group">
          <div className="detail-label">Đồ án</div>
          <div className="detail-text">
            <span className="more-detail" onClick={() => setKeyShow(InfoTypes.DoAn)}>
              {tenDoAn}
            </span>
          </div>
        </div>
        <div className="map-actions">
          <div className="map-action-item" onClick={onExport}>
            <div className="map-action-icon">
              <FaFileExport color="#2196f3" size={22} />
            </div>
            <div className="map-action-text">Xuất thông tin</div>
          </div>
          <div className="map-action-item" onClick={onZoomIn}>
            <div className="map-action-icon">
              <BsZoomIn color="#2196f3" size={22} />
            </div>
            <div className="map-action-text">Phóng tới</div>
          </div>
        </div>
        <div className="wrap-hientrang">
          {desc.length > 0 &&
            desc.map((m, i) => (
              <div key={m.loaiDat + i}>
                <div data-color={m.color} className="desc-hientrang">
                  <div className="symbol" style={{ background: m.color }} onClick={() => handleClickQuyHoach(m.id)}>
                    {m.index}
                  </div>
                  <div className="content">
                    <h4>Mục đích sử dụng đất: {domainQhpk[m.loaiDat]}</h4>
                    <p>
                      Diện tích: {m.dienTich} m<sup>2</sup>
                    </p>
                    {!!m.kyHieuO && <p>Ô chức năng: {m.kyHieuO}</p>}
                    {!MaSddEsc.includes(m.loaiDat) && (
                      <p className="more-detail" style={{ fontSize: 12 }} onClick={() => handleClickQuyHoach(m.id)}>
                        Xem chi tiết
                      </p>
                    )}
                  </div>
                </div>
                <hr />
              </div>
            ))}
        </div>
        <LogBienDong />
      </div>
    </>
  );
}

type PropsInfo = {
  info: Feature[];
  children?: React.ReactNode;
  desc: Array<MoTaHienTrang>;
  content: PopupDetail;
  map: Map;
  domainQhpk: { [k: string | number]: any };
};

function Info({ info, children = null, desc, content, map, domainQhpk }: PropsInfo) {
  const [selected, setSelected] = useState<PopupDetail>();
  const { searchParams } = useQuery();

  const isQhSuDungDat = useMemo(() => getLayerId(searchParams.get("fid")) === LayerName.QUY_HOACH, [searchParams]);

  useEffect(() => {
    if (content) {
      setSelected(content);
    }
  }, [content]);

  return (
    <div id="detail-p">
      {children}
      {selected?.data !== undefined &&
        (isQhSuDungDat ? (
          <InfoQuyHoach map={map} domainQhpk={domainQhpk} />
        ) : (
          <DetailHienTrang map={map} desc={desc} domainQhpk={domainQhpk} />
        ))}
    </div>
  );
}

Info.defaultProps = {
  children: null,
} as PropsInfo;
