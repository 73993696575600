import { FC } from "react";
import { TileLayer } from "../Layers";
import tileWMS from "../Source/tile-wms";

interface QhcSuDungDatProps {}

const featureType = "qhc_sudungdat";

const QhcSuDungDat: FC<QhcSuDungDatProps> = () => {
  const source = tileWMS(featureType);
  return <TileLayer source={source} />;
};

export default QhcSuDungDat;
