import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FaBan, FaEdit, FaSave, FaTrashAlt } from "react-icons/fa";
import instanceAxios from "../../../common/utils";
import JSZip from "jszip";
import SLDParser from "geostyler-sld-parser";
import { Style, Rule, IconSymbolizer } from "geostyler-style";
import Button from "../../../Shared/Button/Button";
import AddIcon from "./AddIcon";
import { varConfig } from "../../../common/var-config";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";

type TienIchItem = { iconUrl: string; title: string; code: string; imageName: string; iconBase64?: string };

interface ListTienIchProps {}

const stylesName = "congtrinhtienich";

const ListTienIch: React.FC<ListTienIchProps> = () => {
  const [rules, setRules] = useState<Array<Rule>>([]);
  const [isAdd, setIsAdd] = useState(false);
  const [sldParser] = useState(new SLDParser());
  const anotherRules = useRef<string | undefined>();

  const getStyles = useCallback(() => {
    instanceAxios.get<string>(`rest/workspaces/cuchi/styles/${stylesName}.sld`).then(({ data }) => {
      anotherRules.current = data.split("<Rule>").pop()?.split("</Rule>")?.[0];

      sldParser.readStyle(data).then(({ output: sldObject }) => {
        if (sldObject) {
          const rulesFilter = [];

          for (const rule of sldObject.rules) {
            if (rule.filter !== undefined) {
              rulesFilter.push(rule);
            }
          }
          setRules(rulesFilter);
        }
      });
    });
  }, [sldParser]);

  useEffect(() => {
    getStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = useCallback(
    (params: { rule: Rule; resource?: string; imageName: string; index: number }) => {
      const { rule, resource, imageName, index } = params;
      const rulesClone = [...rules];
      rulesClone[index] = rule;
      const style: Style = {
        name: "Công trình tiện ích",
        rules: rulesClone,
      };
      sldParser
        .writeStyle(style)
        .then(({ output: sld }) => {
          if (sld) {
            const zip = new JSZip();
            const slds = sld.split("</FeatureTypeStyle>");
            slds[0] += `<Rule>${anotherRules.current}</Rule>`;
            slds[0] += "</FeatureTypeStyle>";
            zip.file("congtrinhtienich.sld", slds.join(""));
            if (resource) {
              const base64 = resource.split(",").pop() as string;
              imageName && zip.file(imageName, base64, { base64: true });
            }
            zip.generateAsync({ type: "blob" }).then((content) => {
              instanceAxios
                .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, content, {
                  headers: {
                    "Content-Type": "application/zip",
                  },
                })
                .then(({ status }) => {
                  if (status === 200) {
                    alert("Cập nhật chú giải thành công!");
                    getStyles();
                  }
                });
            });
          }
        })
        .catch((error) => console.log(error));
    },
    [getStyles, rules, sldParser],
  );

  const handleDelete = useCallback(
    (rule: Rule) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm("Bạn có muốn xóa hàng này không?")) {
        const index = rules.indexOf(rule);
        if (index > -1) {
          const style: Style = {
            name: "Công trình tiện ích",
            rules: rules.filter((_, i) => i !== index),
          };
          sldParser.writeStyle(style).then(({ output }) => {
            if (output) {
              const zip = new JSZip();
              const slds = output.split("</FeatureTypeStyle>");
              slds[0] += `<Rule>${anotherRules.current}</Rule>`;
              slds[0] += "</FeatureTypeStyle>";
              zip.file("congtrinhtienich.sld", slds.join(""));
              zip.generateAsync({ type: "blob" }).then((content) => {
                instanceAxios
                  .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, content, {
                    headers: {
                      "Content-Type": "application/zip",
                    },
                  })
                  .then(({ status }) => {
                    if (status === 200) {
                      alert("Xóa chú giải thành công!");
                      getStyles();
                    }
                  });
              });
            }
          });
        }
      }
    },
    [getStyles, rules, sldParser],
  );

  return (
    <div style={{ width: "100%", paddingTop: 18 }}>
      <div>
        <Button
          style={{ position: "relative", top: 0, left: "73%", marginBottom: 6 }}
          onClick={() => setIsAdd((add) => !add)}
        >
          Thêm mới
        </Button>
      </div>
      {isAdd && <AddIcon onAfterSave={() => getStyles()} onCancel={() => setIsAdd(false)} />}
      <table style={{ margin: "auto" }}>
        <thead>
          <tr>
            <th style={{ width: 110 }}>Hình ảnh</th>
            <th style={{ width: 160 }}>Ký hiệu loại đất</th>
            <th style={{ width: 500 }}>Tên công trình, tiện ích</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule, index) => (
            <TrTienIch
              key={index}
              rule={rule}
              onDelete={(item: Rule) => handleDelete(item)}
              onSave={(rule, imageName, resource) => handleSave({ rule, resource, imageName, index })}
              index={index}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListTienIch;

interface TrLegendProps {
  onSave: (rule: Rule, imageName: string, iconBase64?: string) => void;
  onDelete: (row: Rule) => void;
  rule: Rule;
  index: number;
}

export const TrTienIch: React.FC<TrLegendProps> = ({ onSave, onDelete, rule, index }) => {
  const [rowData, setRowData] = useState<TienIchItem>({
    iconUrl: "",
    title: "",
    code: "",
    imageName: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  const destructuringRule = useCallback(() => {
    const { name, filter, symbolizers } = rule;
    const { image } = symbolizers[0] as IconSymbolizer;
    const iconUrl = `${varConfig.geoServerUrl}/rest/resource/workspaces/cuchi/styles/${image}`;
    setRowData({
      title: name,
      iconUrl,
      code: (filter && (filter[2] as string)) || "",
      imageName: (image as string) || "",
    });
  }, [rule]);

  useEffect(() => {
    destructuringRule();
  }, [destructuringRule]);

  const onChangeInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = evt.target;
      setRowData({ ...rowData, [name]: value });
    },
    [rowData],
  );

  const handleEdit = useCallback(() => {
    // getResoureStyle();
    setIsEdit(true);
  }, []);

  const handleSave = useCallback(() => {
    const { title, iconBase64, code, imageName } = rowData;
    if (!title) {
      alert("Vui lòng nhập tên công trình tiện ích!");
      return;
    }
    const ruleNew: Rule = {
      name: title,
      filter: ["==", "MaQuyUoc", code],
      scaleDenominator: { max: 16000 },
      symbolizers: [{ kind: "Icon", image: imageName }],
    };

    onSave(ruleNew, imageName, iconBase64);
    setIsEdit(false);
  }, [onSave, rowData]);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    destructuringRule();
  }, [destructuringRule]);

  const handleDelete = useCallback(() => {
    onDelete(rule);
  }, [onDelete, rule]);

  const toBase64 = useCallback(
    (file: any): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }),
    [],
  );

  const onChangeInputFile = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      const files = evt.target.files;
      if (files && files.length) {
        const file = files[0];
        const base64 = await toBase64(file);
        setRowData({ ...rowData, imageName: file.name, iconBase64: base64 });
      }
    },
    [rowData, toBase64],
  );

  return (
    <tr>
      <td style={{ textAlign: "center" }}>
        <img src={rowData.iconBase64 || rowData.iconUrl} alt={"icon"} style={{ width: 24 }} id={"icon-" + index} />
        {isEdit && (
          <div>
            <input type="file" id={"upload-" + index} hidden onChange={onChangeInputFile} accept="image/png" />
            <label
              htmlFor={"upload-" + index}
              style={{
                display: "inline-block",
                backgroundColor: "indigo",
                color: "white",
                padding: "0.5rem",
                fontFamily: "sans-serif",
                borderRadius: "0.3rem",
                cursor: "pointer",
                marginTop: "1rem",
              }}
            >
              Chọn file
            </label>
          </div>
        )}
      </td>
      <td style={{ fontWeight: 600, textAlign: "center" }}>
        <InputFloatLabel onChange={onChangeInput} value={rowData.code} name="code" disabled={!isEdit} />
      </td>
      <td>
        <InputFloatLabel onChange={onChangeInput} disabled={!isEdit} value={rowData.title} name="title" />
      </td>
      <td className="actions-legend">
        {!isEdit ? (
          <>
            <button onClick={handleEdit}>
              <FaEdit color="blue" size={18} /> Sửa
            </button>{" "}
            <button onClick={handleDelete}>
              <FaTrashAlt color="red" size={18} /> Xóa
            </button>
          </>
        ) : (
          <>
            <button onClick={handleSave}>
              <FaSave color="green" size={18} /> Lưu
            </button>{" "}
            <button onClick={() => handleCancel()}>
              <FaBan color="#000" size={18} /> Hủy
            </button>
          </>
        )}
      </td>
    </tr>
  );
};
