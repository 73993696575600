import classNames from "classnames";
import WFS, { WriteGetFeatureOptions } from "ol/format/WFS";
import { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from "react";
import { SRSNAME, WORKSPACES } from "../../common/constants";
import { varConfig } from "../../common/var-config";
import "./table.css";
import instanceAxios from "../../common/utils";
import { MultiPolygon } from "geojson";
import { ResponseGetFeatures } from "../../common/definitions";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { and as andFilter, equalTo, like as likeFilter } from "ol/format/filter";
import InputFloatLabel from "../../Shared/input/InputFloatLabel";
import SelectFloatLabel, { Option } from "../../Shared/select/SelectFloatLabel";
import { Domain } from "../../common/domain";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import useWriteTransaction from "../../services/transaction.service";
import { Feature } from "ol";
import { AxiosResponse } from "axios";

type TableCol = {
  name: string;
  label: string;
  frozen?: boolean;
  fronzenDirection?: "left" | "right";
  width?: string;
  domain?: { [key: string]: any };
};

export type TableCols = Array<TableCol>;

export type FilterTable = {
  propertyName: string;
  value: string;
  operator: "like" | "equal";
  type?: "text" | "select";
  domain?: Array<Domain>;
};

type Props = {
  layerName: string;
  columns: Array<TableCol>;
  filter?: Array<FilterTable>;
  title?: string;
};

const TableComponent: FC<Props> = ({ layerName, columns, filter, title }) => {
  const [rows, setRows] = useState<Array<{ id: string } & { [k: string]: any }>>([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemPerPage = 10;
  const [filterTable, setFilterTable] = useState<Array<FilterTable>>();
  const transaction = useWriteTransaction(layerName);
  const [pageCurrent, setPageCurrent] = useState<number>();

  useEffect(() => {
    if (filter) {
      setFilterTable(filter);
    }
  }, []);

  // set page is depends on set item offset
  // const updatePagination = useCallback(() => {
  //   setItemOffset(() => {
  //     return;
  //   });
  // }, []);

  const convertFilter = useCallback((filter: Array<FilterTable>) => {
    const fa = filter.filter((f) => !!f.value);
    const ftr = [];
    for (const ff of fa) {
      if (ff.operator === "equal") {
        ftr.push(equalTo(ff.propertyName, ff.value));
      }
      if (ff.operator === "like") {
        ftr.push(likeFilter(ff.propertyName, `%${ff.value}%`, undefined, undefined, undefined, false));
      }
    }
    if (ftr.length < 2) {
      return ftr[0];
    }
    return andFilter(...ftr);
  }, []);

  const fillDataTable = useCallback((response: AxiosResponse<ResponseGetFeatures<MultiPolygon, any>>) => {
    const { features, totalFeatures } = response.data;
    setPageCount(Math.ceil(totalFeatures / itemPerPage));
    const r = features.map((feature) => ({ id: feature.id, ...feature.properties }));
    setRows(r as any);
  }, []);

  const getFeatures = useCallback(
    (filterFeature: Array<FilterTable> | undefined = filter, startIndex: number = itemOffset) => {
      const writeTransactionOptions: WriteGetFeatureOptions = {
        srsName: SRSNAME,
        featureNS: varConfig.geoServerUrl,
        featurePrefix: WORKSPACES,
        featureTypes: [layerName],
        outputFormat: "application/json",
        maxFeatures: itemPerPage,
        startIndex,
        viewParams: "sortBy:created_at DESC", // not working
      };
      if (filterFeature) {
        writeTransactionOptions.filter = convertFilter(filterFeature);
      }
      var featureRequest = new WFS().writeGetFeature(writeTransactionOptions);

      const xs = new XMLSerializer();
      const payload = xs.serializeToString(featureRequest);
      instanceAxios.post<ResponseGetFeatures<MultiPolygon, any>>("wfs", payload).then(fillDataTable);
    },
    [itemOffset, layerName],
  );

  const getFeaturesBySearchParams = useCallback(
    (filterFeature: Array<FilterTable> | undefined = filter, startIndex: number = itemOffset) => {
      const parameters: Record<string, string> = {
        request: "GetFeature",
        version: "1.1.0",
        service: "wfs",
        typeName: layerName,
        outputFormat: "json",
        maxFeatures: `${itemPerPage}`,
        startIndex: `${startIndex}`,
        sortBy: "created_at DESC",
      };

      if (filterFeature) {
        const filterExitValue = filterFeature.filter((ff) => !!ff.value);
        if (filterExitValue.length) {
          parameters.cql_filter = filterExitValue
            .map((f) => {
              const propertyName = f.operator === "equal" ? f.propertyName : `strToLowerCase(${f.propertyName})`;
              const operator = f.operator === "equal" ? "=" : f.operator;
              const value = f.operator === "equal" ? f.value : `'%${f.value}%'`;
              return `${propertyName} ${operator} ${value}`;
            })
            .join(" and ");
        }
      }
      const search = new URLSearchParams(parameters).toString();
      instanceAxios.get("wfs?" + search).then(fillDataTable);
    },
    [fillDataTable, filter, itemOffset, layerName],
  );

  useEffect(() => {
    getFeaturesBySearchParams(filter);
  }, []);

  const handlePageClick = (event: { selected: number }) => {
    const pageSelected = event.selected;
    const newOffset = pageSelected * itemPerPage;
    getFeaturesBySearchParams(filterTable, newOffset);
    setItemOffset(newOffset);
  };

  const onChange = useCallback(
    (index: number, event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (filterTable) {
        const itemOffsetZero = 0;

        const filterClone = [...filterTable];
        const { value } = event.target;
        filterClone[index].value = value;
        getFeaturesBySearchParams(filterClone, itemOffsetZero);
        setItemOffset(itemOffsetZero);
        setPageCurrent(0); // BUG not force page
        setFilterTable(filterClone);
      }
    },
    [filterTable, getFeaturesBySearchParams],
  );

  const onDelete = useCallback(
    (id: string) => {
      // eslint-disable-next-line no-restricted-globals
      const cf = confirm("Bạn chắc chắn muốn xóa dòng này?");
      if (!cf) return;
      const feature = new Feature();
      feature.setId(id);
      transaction.deleteFeatures([feature]);
      getFeaturesBySearchParams(filterTable, itemOffset);
      // const restFeatures = rows.filter((row) => row.id !== id);
      // if (!restFeatures.length) {
      //   const itemOffsetCurrent = itemOffset - itemPerPage;
      //   const pagePrev = Math.floor(itemOffsetCurrent / itemPerPage);
      //   getFeaturesBySearchParams(filterTable, itemOffsetCurrent);
      //   setItemOffset(itemOffsetCurrent);
      //   setPageCurrent(pagePrev);
      // }
      // setRows(restFeatures);
    },
    [filterTable, getFeaturesBySearchParams, itemOffset, transaction],
  );

  return (
    <>
      <div
        style={{
          overflow: "auto",
          width: "90%",
          margin: "10px auto",
          padding: "6px 24px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          height: 654,
        }}
        className="table-ol"
      >
        <h2>{title}</h2>
        <div style={{ display: "flex", justifyContent: "end", marginBottom: 12, padding: "0px 372px" }}>
          {filter?.map((m, i) => (
            <Fragment key={i}>
              {!m.type && (
                <InputFloatLabel
                  placeholder={m.propertyName}
                  name={m.propertyName}
                  onChange={(evt) => onChange(i, evt)}
                  value={m.value}
                />
              )}
              {m.type === "select" && m.domain instanceof Array && (
                <SelectFloatLabel placeholder={m.propertyName} onChange={(evt) => onChange(i, evt)}>
                  <Option value={""}>Tất cả</Option>
                  {m.domain.map((option) => (
                    <Option value={option.code} key={option.code}>
                      {option.name}
                    </Option>
                  ))}
                </SelectFloatLabel>
              )}
            </Fragment>
          ))}
        </div>
        {/* <Button style={{ float: "right", marginTop: 12 }}>Tìm kiếm</Button> */}
        <table className="table-custom" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.name}
                  className={classNames(
                    "frozen-header",
                    column.frozen && `frozen-column frozen-column-${column.fronzenDirection || "left"}`,
                  )}
                >
                  <div style={{ width: column.width || 60 }}>{column.label}</div>
                </th>
              ))}
              <th className="frozen-header frozen-column frozen-column-left">
                <div style={{ width: 100 }}>Thao tác</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id}>
                {columns.map((column) => (
                  <td
                    key={column.name}
                    className={classNames(
                      column.frozen && `frozen-column frozen-column-${column.fronzenDirection || "left"}`,
                    )}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "start", width: "100%" }}>
                      {column.domain ? column.domain[row[column.name]] : row[column.name]}
                      {typeof row[column.name] === "boolean" && row[column.name] === true && (
                        <span className="badge active">Hoạt động</span>
                      )}
                      {typeof row[column.name] === "boolean" && row[column.name] === false && (
                        <span className="badge danger">Ngừng hoạt động</span>
                      )}
                    </div>
                  </td>
                ))}
                <td className="frozen-column frozen-column-left">
                  <div style={{ width: 100 }}>
                    <Link to={"./" + row.id} style={{ textDecorationLine: "none" }}>
                      <FaEdit /> Sửa
                    </Link>
                    <button className="btn-delete" onClick={() => onDelete(row.id)}>
                      <FaTrashAlt color="red" /> Xóa
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          nextLabel="tiếp theo >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< trang trước"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageCurrent}
        />
      </div>
    </>
  );
};

export default TableComponent;
