/* eslint-disable react/require-default-props */
import React, { Children, cloneElement, useState, useCallback, useEffect } from "react";
import "./index.css";
import classNames from "classnames";
import Tooltip from "../../Shared/Tooltip";
import { FcPrevious } from "react-icons/fc";
type TabProps = {
  children: React.ReactNode;
  selected?: number;
  onSelected?: (index: number) => void;
};

type TabItemProps = {
  children: React.ReactElement;
  color?: string;
  isActive?: boolean;
  titleBtn?: string;
  icon: React.ReactElement;
  observableShowValue?: unknown;
  onObservable?: () => void;
};

function TabComponent({ children, selected = 0, onSelected }: TabProps) {
  const [index, setIndex] = useState(selected);
  const [show, setShow] = useState(false);

  const arrayChildren = Children.toArray(children);

  const handleSelected = useCallback(
    (idx: number) => {
      if (idx === index) {
        setShow((showPrev) => !showPrev);
      } else {
        setIndex(idx);
      }
      if (!show) {
        setShow(true);
      }
      onSelected?.(idx);
    },
    [index, onSelected, show],
  );

  const onObservableChild = useCallback((i: number) => {
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 200);
    setIndex(i);
  }, []);

  return (
    <div className={classNames("tab-wrapper", { "collapse-tab": !show })}>
      <div className="tab-content">
        {Children.map(arrayChildren, (child, indexChild: number) => {
          if (React.isValidElement(child) && child.type === TabItem) {
            const { observableShowValue } = child.props as TabItemProps;
            const childProps: Partial<TabItemProps> = {
              isActive: indexChild === index,
            };
            if (observableShowValue) {
              childProps.onObservable = () => onObservableChild(indexChild);
            }
            return <>{cloneElement(child, childProps)}</>;
          }
          return child;
        })}
      </div>
      {show && (
        <button className="btn-collapse" onClick={() => setShow(false)}>
          <FcPrevious size={18} />
        </button>
      )}
      <div className="wrapper-btn">
        {Children.map(arrayChildren, (child, indexChild: number) => {
          if (React.isValidElement(child) && child.type === TabItem) {
            const { titleBtn = "tooltip", icon } = child.props as TabItemProps;
            return (
              <Tooltip text={titleBtn} className="tooltip" xsDirection="top">
                <button
                  className={classNames("btn-control", { "btn-active": index === indexChild })}
                  type="button"
                  onClick={() => {
                    handleSelected(indexChild);
                  }}
                >
                  {cloneElement(icon, {
                    size: 25,
                  })}
                </button>
              </Tooltip>
            );
          }
          return child;
        })}
      </div>
      {/* <SwitchBasemap /> */}
    </div>
  );
}

function TabItem({ children, isActive, onObservable, observableShowValue }: TabItemProps): React.ReactElement {
  useEffect(() => {
    onObservable?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observableShowValue]);

  return <div className={classNames("tab-item", { "tab-show": isActive })}>{children}</div>;
}

export default TabComponent;
export { TabItem };

function SwitchBasemap() {
  const [active, setActive] = useState(true);
  return (
    <div className="switch-basemap" onClick={() => setActive((s) => !s)}>
      <div className={classNames("street", { "behavior-expected": active })}></div>
      <div className={classNames("lite", { "behavior-expected": !active })}></div>
      <div className="layer-more"></div>
    </div>
  );
}
