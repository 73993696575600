import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

interface BackComponentProps {
  title: string;
  onBack?: () => void;
}

const BackComponent: React.FC<BackComponentProps> = ({ title, onBack }) => {
  return (
    <div
      style={{
        fontSize: "18px",
        marginBottom: 4,
        color: "#fff",
        backgroundColor: "#2196f3",
        padding: "8px 8px 8px 16px",
        position: "relative",
        textAlign: "center",
      }}
    >
      {onBack !== undefined && (
        <div
          onClick={() => onBack()}
          style={{ position: "absolute", top: 0, left: 0, width: 40, height: 40, padding: 8 }}
        >
          <FaArrowLeft />
        </div>
      )}

      <div>{title}</div>
    </div>
  );
};

export default BackComponent;
