import { useRef, useState, useEffect, PropsWithChildren, FC } from "react";
import "./Map.css";
import MapContext from "./MapContext";
import MapOl from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

interface MapProps {
  zoom: number;
  center: number[];
}

const Map: FC<PropsWithChildren<MapProps>> = ({ zoom, center, children }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<MapOl>();
  const [highlightLayer] = useState(new VectorLayer({ source: new VectorSource(), zIndex: 999 }));

  // on component mount
  useEffect(() => {
    let options = {
      view: new View({ zoom, center }),
      layers: [highlightLayer],
      controls: [],
      overlays: [],
    };

    let mapObject = new MapOl(options);
    mapRef.current && mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // zoom change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setZoom(zoom);
  }, [map, zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;

    map.getView().setCenter(center);
  }, [center, map]);

  return (
    <MapContext.Provider value={{ map, highlightLayer }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
