import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FaBan, FaEdit, FaSave, FaTrashAlt } from "react-icons/fa";
import instanceAxios, { hexToRgbA, rgbToHex } from "../../../common/utils";
import Button from "../../../Shared/Button/Button";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import SLDParser from "geostyler-sld-parser";
import { Style } from "geostyler-style";

type LegendItem = { color: Array<number>; title: string; code: string };

interface ListCongVanProps {}

const rgbName = ["R", "G", "B"];
const stylesName = "quyhoachsddcaphuyen";

const ListCongVan: React.FC<ListCongVanProps> = () => {
  const [featureTypeStyle, setFeatureTypeStyle] = useState<Array<any>>([]);
  const [isAdd, setIsAdd] = useState(false);
  const documentRef = useRef<Document>();
  const getStyles = useCallback(() => {
    instanceAxios.get(`rest/workspaces/cuchi/styles/${stylesName}.sld`).then(({ data }) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(data, "text/xml");
      documentRef.current = xml;
      const featureTypeStyle = xml.querySelector("FeatureTypeStyle");
      if (featureTypeStyle) {
        setFeatureTypeStyle(Array.from(featureTypeStyle.children));
      }
    });
  }, []);
  useEffect(() => {
    getStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStyle = useCallback(
    (data: string) => {
      instanceAxios
        .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, data, {
          headers: {
            "Content-Type": "application/vnd.ogc.sld+xml",
          },
        })
        .then(({ status }) => {
          if (status === 200) {
            alert("Cập nhật chú giải thành công!");
            getStyles();
          }
        });
    },
    [getStyles],
  );

  const handleSave = useCallback(
    (rule: Element, index: number) => {
      if (documentRef.current) {
        const featureTypeStyle = documentRef.current.querySelector("FeatureTypeStyle");
        if (featureTypeStyle) {
          const children = rule.children;
          featureTypeStyle.children[index].innerHTML = Array.from(children)
            .map((m) => m.outerHTML)
            .join("");
        }
        const documentElement = documentRef.current.documentElement.outerHTML;
        const data = `${documentElement}`;
        instanceAxios
          .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, data, {
            headers: {
              "Content-Type": "application/vnd.ogc.sld+xml",
            },
          })
          .then(({ status }) => {
            if (status === 200) {
              alert("Cập nhật chú giải thành công!");
              getStyles();
            }
          });
      }
    },
    [getStyles],
  );

  const handleDelete = useCallback(
    (item: Element, index: number) => {
      // eslint-disable-next-line no-restricted-globals
      const cf = confirm("Bạn có chắc chắn xóa hàng này không?");
      if (cf) {
        if (documentRef.current) {
          const featureTypeStyle = documentRef.current.querySelector("FeatureTypeStyle");
          if (featureTypeStyle) {
            const c = Array.from(featureTypeStyle.children).find((f, i) => i === index);
            if (c) {
              featureTypeStyle.removeChild(c);
              const documentElement = documentRef.current.documentElement.outerHTML;
              updateStyle(documentElement);
            }
          }
        }
      }
    },
    [updateStyle],
  );

  const handleAdd = useCallback(() => {
    setIsAdd(true);
  }, []);

  const onCancelAdd = useCallback(() => {
    setIsAdd(false);
  }, []);

  return (
    <div style={{ width: "100%", paddingTop: 18 }}>
      <div style={{ display: "flex", padding: "8px 72px", justifyContent: "space-between" }}>
        <div style={{ padding: "0 122px 0 72px" }}>
          Phân loại chức năng sử dụng đất dựa theo quy định trong Phụ lục III của Thông tư số 75/2015/TT-BTNMT ngày 28
          tháng 12 năm 2015 của Bộ trưởng Bộ Tài nguyên và Môi trường
        </div>
        <div style={{ padding: "0 72px" }}>
          <Button onClick={handleAdd}>Thêm</Button>
        </div>
      </div>
      {isAdd && <Add onCancel={onCancelAdd} onAfterSave={getStyles} />}
      <table style={{ margin: "auto" }}>
        <thead>
          <tr>
            <th>Ký hiệu loại đất</th>
            <th style={{ width: 400 }}>Mã màu</th>
            <th style={{ width: 100 }}>Màu sắc</th>
            <th style={{ minWidth: 500 }}>Tên loại đất</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {featureTypeStyle !== undefined &&
            featureTypeStyle.map((rule, index) => (
              <TrLegend
                key={index}
                node={rule}
                onDelete={(item: Element) => handleDelete(item, index)}
                onSave={(rule) => handleSave(rule, index)}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListCongVan;

interface TrLegendProps {
  onSave: (rule: Element) => void;
  onDelete: (row: Element) => void;
  node: Element;
}

export const TrLegend: React.FC<TrLegendProps> = ({ onSave, onDelete, node }) => {
  const [rowData, setRowData] = useState<LegendItem>({
    color: [],
    title: "",
    code: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  const destructuringNode = useCallback(() => {
    const title = node.querySelector("Name")?.textContent || "";
    const code = node.querySelector("Literal")?.textContent || "";
    const fillElement = node.querySelector("PolygonSymbolizer")?.querySelector("Fill");
    const colorText =
      fillElement?.querySelector("CssParameter")?.textContent ||
      fillElement?.querySelector("CssParameter")?.querySelector("Literal")?.textContent ||
      "#000000";
    const color = colorText ? hexToRgbA(colorText.replaceAll(/[/\n/\s]/g, "")) : [];
    setRowData({
      title,
      color,
      code,
    });
  }, [node]);

  useEffect(() => {
    destructuringNode();
  }, [destructuringNode]);

  const onChangeInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = evt.target;
      setRowData({ ...rowData, [name]: value });
    },
    [rowData],
  );

  const onChangeInputColor = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, index: number) => {
      const { value, name } = evt.target;
      if (rgbName.includes(name) && value.length < 4 && +value <= 255) {
        const color = [...rowData.color];
        color[index] = +value;
        setRowData({ ...rowData, color });
      }
    },
    [rowData],
  );

  const handleEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleSave = useCallback(() => {
    const { title, color, code } = rowData;
    const [r, g, b] = color;
    if (!title) {
      alert("Vui lòng tên loại đất!");
      return;
    }
    if (!code) {
      alert("Vui lòng tên ký hiệu loại đất!");
      return;
    }
    const nodeClone = node.cloneNode(true) as Element;
    const titleElement = nodeClone.querySelector("Name");
    const literalCodelElement = nodeClone.querySelector("Literal");
    const literalColorElement = nodeClone
      .querySelector("PolygonSymbolizer")
      ?.querySelector("Fill")
      ?.querySelector("CssParameter");
    if (titleElement) {
      titleElement.textContent = title;
    }
    if (literalCodelElement) {
      literalCodelElement.textContent = code;
    }
    if (literalColorElement) {
      literalColorElement.textContent = rgbToHex(r, g, b);
    }
    onSave(nodeClone);
    setIsEdit(false);
  }, [node, onSave, rowData]);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
    destructuringNode();
  }, [destructuringNode]);

  const handleDelete = useCallback(() => {
    setIsEdit(false);
    onDelete(node);
  }, [node, onDelete]);

  return (
    <tr>
      <td style={{ fontWeight: 600, textAlign: "center" }}>
        <InputFloatLabel onChange={onChangeInput} value={rowData.code} name="code" disabled={!isEdit} />
      </td>
      <td>
        <div style={{ display: "flex", backgroundColor: "inherit", columnGap: 10 }}>
          {rowData.color.map((c, i) => {
            return (
              <InputFloatLabel
                onChange={(evt: ChangeEvent<HTMLInputElement>) => onChangeInputColor(evt, i)}
                name={rgbName[i]}
                value={c}
                placeholder={rgbName[i]}
                disabled={!isEdit}
                key={i}
              />
            );
          })}
        </div>
      </td>
      <td style={{ width: 100, backgroundColor: `rgb(${rowData.color.join(",")})` }}></td>
      <td>
        <InputFloatLabel onChange={onChangeInput} disabled={!isEdit} value={rowData.title} name="title" />
      </td>
      <td className="actions-legend">
        {!isEdit ? (
          <>
            <button onClick={handleEdit}>
              <FaEdit color="blue" size={18} /> Sửa
            </button>{" "}
            <button onClick={handleDelete}>
              <FaTrashAlt color="red" size={18} /> Xóa
            </button>
          </>
        ) : (
          <>
            <button onClick={handleSave}>
              <FaSave color="green" size={18} /> Lưu
            </button>{" "}
            <button onClick={() => handleCancel()}>
              <FaBan color="#000" size={18} /> Hủy
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

interface AddProps {
  onAfterSave: () => void;
  onCancel: () => void;
}

const Add: React.FC<AddProps> = ({ onCancel, onAfterSave }) => {
  const [rule, setRule] = useState<LegendItem & { r: string; g: string; b: string }>({
    code: "",
    title: "",
    color: [],
    r: "",
    g: "",
    b: "",
  });

  const onChangeInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = evt.target;
      if (["r", "g", "b"].includes(name) && (value.length > 3 || +value > 255)) {
        return;
      }
      setRule({ ...rule, [name]: value });
    },
    [rule],
  );

  const handleAdd = useCallback(() => {
    const { code, title, r, g, b } = rule;
    instanceAxios.get(`rest/workspaces/cuchi/styles/${stylesName}.sld`).then(({ data }) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(data, "text/xml");
      const featureTypeStyle = xml.querySelector("FeatureTypeStyle");
      if (featureTypeStyle) {
        const pointSimplePoint: Style = {
          name: "",
          rules: [
            {
              name: title,
              scaleDenominator: { max: 36000 },
              filter: ["==", "MucDichSuDungDat", code],
              symbolizers: [
                {
                  kind: "Fill",
                  color: rgbToHex(+r, +g, +b),
                },
              ],
            },
          ],
        };

        const parser = new SLDParser();

        parser
          .writeStyle(pointSimplePoint)
          .then(({ output: sld }) => {
            if (sld) {
              const parser = new DOMParser();
              const xmlSld = parser.parseFromString(sld, "text/xml");
              const rule = xmlSld.querySelector("Rule") as Element;
              featureTypeStyle.appendChild(rule);

              instanceAxios
                .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, xml.documentElement.outerHTML, {
                  headers: {
                    "Content-Type": "application/vnd.ogc.sld+xml",
                  },
                })
                .then(({ status }) => {
                  if (status === 200) {
                    alert("Cập nhật chú giải thành công!");
                    onAfterSave();
                    setRule({ code: "", title: "", r: "", g: "", b: "", color: [] });
                  }
                });
            }
          })
          .catch((error) => console.log(error));
      }
    });
  }, [onAfterSave, rule]);

  return (
    <div style={{ textAlign: "right", padding: "12px 150px" }}>
      <div style={{ display: "flex", width: "100%", justifyContent: "center", columnGap: 12 }}>
        <div style={{ width: 320 }}>
          <InputFloatLabel placeholder="Ký hiệu loại đất" name="code" onChange={onChangeInput} value={rule.code} />
        </div>

        <fieldset style={{ border: "none", textAlign: "left" }}>
          <legend>Mã màu:</legend>
          <div style={{ display: "flex", width: 320, marginTop: -18 }}>
            <InputFloatLabel placeholder="R" name="r" onChange={onChangeInput} type="number" value={rule.r} />
            <InputFloatLabel placeholder="G" name="g" onChange={onChangeInput} type="number" value={rule.g} />
            <InputFloatLabel placeholder="B" name="b" onChange={onChangeInput} type="number" value={rule.b} />
          </div>
        </fieldset>

        <div style={{ width: 600 }}>
          <InputFloatLabel placeholder="Tên loại đất" name="title" onChange={onChangeInput} value={rule.title} />
        </div>
      </div>
      <div style={{ paddingTop: 12 }}>
        <Button style={{ marginRight: 4 }} onClick={onCancel} className="outline">
          Hủy
        </Button>
        <Button onClick={handleAdd}>Lưu</Button>
      </div>
    </div>
  );
};
