import { useCallback, useMemo, useState } from "react";
import useTransaction from "../../services/transaction";
import Button from "../../Shared/Button/Button";

import { Feature, Map } from "ol";
import "./search-spatial.css";
import { useNavigate } from "react-router-dom";
import InputFloatLabel from "../../Shared/input/InputFloatLabel";
import { toastService } from "../../services/toast.service";
import { FcAddRow, FcDeleteRow } from "react-icons/fc";
import { LayerName } from "../../common/constants";
import proj4 from "proj4";
import { MultiPoint } from "ol/geom";
import FormatWKT from "ol/format/WKT";
import { Coordinate } from "ol/coordinate";
import { Fill, Style } from "ol/style";
import Stroke from "ol/style/Stroke";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import useQuery from "../../hooks/useQuery";
import useIntegrateAPI from "../../services/integrate-api";
import { HienTrangSDD } from "../../common/definitions";

type Props = {
  map?: Map;
};

type Coordinates = { x: string; y: string };

const SearchSpatialComponent = ({ map }: Props) => {
  const { getAllFeature } = useTransaction(LayerName.HIEN_TRANG);
  const { logGis } = useIntegrateAPI();
  const [geometriesInput, setGeometriesInput] = useState<Array<{ x: string; y: string }>>([{ x: "", y: "" }]);
  const navigate = useNavigate();
  const { searchParams } = useQuery();
  const { warning } = toastService();

  const proj = proj4(
    "+proj=tmerc +lat_0=0 +lon_0=105.45 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs",
    "EPSG:3857",
    [614996.1919999998, 1214032.0502000004],
  );

  const onClickAddGeo = useCallback(() => {
    const geos = [...geometriesInput];
    geos.push({ x: "", y: "" });
    setGeometriesInput(geos);
  }, [geometriesInput]);

  const onClickRemoveGeo = useCallback(
    (index: number) => {
      const geos = geometriesInput.filter((geo, i) => i !== index);
      setGeometriesInput(geos);
    },
    [geometriesInput],
  );

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const geos = [...geometriesInput];
      const { name, value } = evt.target;
      const n = name as keyof Coordinates;
      geos[index][n] = value;
      setGeometriesInput(geos);
    },
    [geometriesInput],
  );
  const handleSearch = useCallback(() => {
    if (map) {
      const projectedCoords: Coordinate[] = [];
      let invalid = true;
      const roundDecimal = 6;
      for (let i = 0; i < geometriesInput.length; i++) {
        const { x, y } = geometriesInput[i];
        if (x && y) {
          // const [long, lat] = olProj.fromLonLat([Number(x), Number(y)]);
          const coords = proj4(
            'PROJCS["Transverse_Mercator",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS84",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["false_easting",500000.0],PARAMETER["false_northing",0.0],PARAMETER["central_meridian",105.45],PARAMETER["scale_factor",0.9999],PARAMETER["latitude_of_origin",0.0],UNIT["Meter",1.0], AUTHORITY["EPSG","41003"]]',
            "EPSG:3857",
            [Number(x), Number(y)],
          );
          const [longitude, latitude] = coords;
          if (isNaN(Number(longitude)) || isNaN(Number(latitude))) {
            invalid = false;
            break;
          }

          projectedCoords.push([+longitude.toFixed(roundDecimal), +latitude.toFixed(roundDecimal)]);
        }
      }

      if (!invalid) {
        warning("Tọa độ không hợp lệ!");
        return;
      }

      if (projectedCoords.length) {
        const geometry = new MultiPoint(projectedCoords);
        const feature = new Feature();
        feature.setGeometry(geometry);
        feature.setStyle(
          new Style({
            stroke: new Stroke({ color: "red", width: 5 }),
            fill: new Fill({ color: "red" }),
          }),
        );
        const interactionLayer = map
          .getAllLayers()
          .find((layer) => layer.get("id") === "interaction-layer") as VectorLayer<VectorSource>;
        interactionLayer.getSource()?.addFeature(feature);

        const wkt = new FormatWKT();
        const wktGeometry = wkt.writeFeature(feature);
        const filter = `CONTAINS(geometry, ${wktGeometry}) or EQUALS(geometry, ${wktGeometry})`;
        getAllFeature({ filter }).then(({ features }) => {
          const [feature] = features;
          if (feature) {
            const {
              SoNha: soNha,
              SoTo: soTo,
              MaPX: tenPhuongXa,
              SoThua: soThua,
              TenDuong: tenDuong,
            } = feature.properties as HienTrangSDD;
            logGis({ soNha, soThua, soTo, tenDuong, tenPhuongXa });
            const id = feature.id;
            if (id) {
              searchParams.set("fid", id.toString());
              navigate({
                pathname: "/map/place",
                search: searchParams.toString(),
              });
            }
          } else {
            warning("Không tìm thấy kết quả phù hợp!");
          }
        });
      } else {
        warning("Vui lòng nhập ít nhất một tọa độ!");
      }
    }
  }, [geometriesInput, getAllFeature, map, navigate, searchParams, warning]);

  const lengthSpatial = useMemo(() => geometriesInput.length > 1, [geometriesInput]);

  return (
    <div className="wrap-spatial">
      {geometriesInput.map(({ x, y }, i) => (
        <div className="group-spatial" key={"geos" + i}>
          <div className="order-spatial" style={{ visibility: lengthSpatial ? "visible" : "hidden" }}>
            {i + 1}
          </div>
          <div className="spatial">
            <InputFloatLabel placeholder="Nhập tọa độ x" name="x" onChange={(evt) => handleChange(evt, i)} value={x} />
          </div>
          <div className="spatial">
            <InputFloatLabel placeholder="Nhập tọa độ y" name="y" onChange={(evt) => handleChange(evt, i)} value={y} />
          </div>
          {/* <Tooltip text="Xóa hàng" style={{ visibility: lengthSpatial ? "visible" : "hidden" }}> */}
          <button
            type="button"
            className="btn-spatial btn-remove"
            onClick={() => onClickRemoveGeo(i)}
            style={{ visibility: lengthSpatial ? "visible" : "hidden" }}
          >
            <FcDeleteRow size={24} />
          </button>
          {/* </Tooltip> */}
        </div>
      ))}
      <div className="group-spatial">
        <div className="order-spatial" style={{ visibility: "hidden" }}>
          {geometriesInput.length + 1}
        </div>
        <div className="spatial">
          <InputFloatLabel className="pseudo" disabled placeholder="Tọa độ x" name="x" />
        </div>
        <div className="spatial">
          <InputFloatLabel className="pseudo" disabled placeholder="Tọa độ y" name="y" />
        </div>
        {/* <Tooltip text="Thêm hàng mới"> */}
        <button type="button" className="btn-spatial btn-add" onClick={onClickAddGeo}>
          <FcAddRow size={24} />
        </button>
        {/* </Tooltip> */}
      </div>
      <div style={{ display: "grid", marginTop: 16 }}>
        {/* <Button onClick={onClickAddGeo} className="outline">
          Thêm tọa độ
        </Button> */}
        <Button onClick={handleSearch} className="button-primary">
          Tìm kiếm
        </Button>
      </div>
    </div>
  );
};

export default SearchSpatialComponent;
