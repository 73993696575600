export type Domain = {
  code: string | number;
  name: string;
};

export const DmLoaiQuyHoach: Array<Domain> = [
  { code: 10, name: "Quy hoạch chung" },
  { code: 20, name: "Quy hoạch phân khu" },
  { code: 30, name: "Quy hoạch nông thôn mới" },
  { code: 40, name: "Quy hoạch cụm công nghiệp" },
  { code: 50, name: "Quy hoạch chi tiết" },
  { code: 60, name: "Quy hoạch chung đô thị" },
  { code: 70, name: "Quy hoạch vùng" },
];

export const DomainPhuongXa: Array<Domain> = [
  { name: "Thị trấn Củ Chi", code: 27496 },
  { name: "Xã Phú Mỹ Hưng", code: 27499 },
  { name: "Xã An Phú", code: 27502 },
  { name: "Xã Trung Lập Thượng", code: 27505 },
  { name: "Xã An Nhơn Tây", code: 27508 },
  { name: "Xã Nhuận Đức", code: 27511 },
  { name: "Xã Phạm Văn Cội", code: 27514 },
  { name: "Xã Phú Hòa Đông", code: 27517 },
  { name: "Xã Trung Lập Hạ", code: 27520 },
  { name: "Xã Trung An", code: 27523 },
  { name: "Xã Phước Thạnh", code: 27526 },
  { name: "Xã Phước Hiệp", code: 27529 },
  { name: "Xã Tân An Hội", code: 27532 },
  { name: "Xã Phước Vĩnh An", code: 27535 },
  { name: "Xã Thái Mỹ", code: 27538 },
  { name: "Xã Tân Thạnh Tây", code: 27541 },
  { name: "Xã Hòa Phú", code: 27544 },
  { name: "Xã Tân Thạnh Đông", code: 27547 },
  { name: "Xã Bình Mỹ", code: 27550 },
  { name: "Xã Tân Phú Trung", code: 27553 },
  { name: "Xã Tân Thông Hội", code: 27556 },
];

export const DomainHienTrang = [
  { code: "LUC", name: "Đất chuyên trồng lúa nước" },
  { code: "LUK", name: "Đất trồng lúa nước còn lại" },
  { code: "LUN", name: "Đất lúa nương" },
  { code: "BHK", name: "Đất bằng trồng cây hàng năm khác" },
  { code: "NHK", name: "Đất nương rẫy trồng cây hàng năm khác" },
  { code: "CLN", name: "Đất trồng cây lâu năm" },
  { code: "RSX", name: "Đất rừng sản xuất" },
  { code: "RPH", name: "Đất rừng phòng hộ" },
  { code: "RDD", name: "Đất rừng đặc dụng" },
  { code: "NTS", name: "Đất nuôi trồng thủy sản" },
  { code: "LMU", name: "Đất làm muối" },
  { code: "NKH", name: "Đất nông nghiệp khác" },
  { code: "ONT", name: "Đất ở tại nông thôn" },
  { code: "ODT", name: "Đất ở tại đô thị" },
  { code: "TSC", name: "Đất xây dựng trụ sở cơ quan" },
  { code: "DTS", name: "Đất xây dựng trụ sở của tổ chức sự nghiệp" },
  { code: "DVH", name: "Đất xây dựng cơ sở văn hóa" },
  { code: "DYT", name: "Đất xây dựng cơ sở y tế" },
  { code: "DGD", name: "Đất xây dựng cơ sở giáo dục và đào tạo" },
  { code: "DTT", name: "Đất xây dựng cơ sở thể dục thể thao" },
  { code: "DKH", name: "Đất xây dựng cơ sở khoa học và công nghệ" },
  { code: "DXH", name: "Đất xây dựng cơ sở dịch vụ xã hội" },
  { code: "DNG", name: "Đất xây dựng cơ sở ngoại giao" },
  { code: "DSK", name: "Đất xây dựng công trình sự nghiệp khác" },
  { code: "CQP", name: "Đất quốc phòng" },
  { code: "CAN", name: "Đất an ninh" },
  { code: "SKK", name: "Đất khu công nghiệp" },
  { code: "SKT", name: "Đất khu chế xuất" },
  { code: "SKN", name: "Đất cụm công nghiệp" },
  { code: "SKC", name: "Đất cơ sở sản xuất phi nông nghiệp" },
  { code: "TMDV", name: "Đất thương mại, dịch vụ" },
  { code: "SKS", name: "Đất sử dụng cho hoạt động khoáng sản" },
  { code: "SKX", name: "Đất sản xuất vật liệu xây dựng, làm đồ gốm" },
  { code: "DGT", name: "Đất giao thông" },
  { code: "DTL", name: "Đất thủy lợi" },
  { code: "DNL", name: "Đất công trình năng lượng" },
  { code: "DBV", name: "Đất công trình bưu chính, viễn thông" },
  { code: "DSH", name: "Đất sinh hoạt cộng đồng" },
  { code: "DKV", name: "Đất khu vui chơi, giải trí công cộng" },
  { code: "DCH", name: "Đất chợ" },
  { code: "DDT", name: "Đất có di tích lịch sử - văn hóa" },
  { code: "DDL", name: "Đất danh lam thắng cảnh" },
  { code: "DRA", name: "Đất bãi thải, xử lý chất thải" },
  { code: "DCK", name: "Đất công trình công cộng khác" },
  { code: "TON", name: "Đất cơ sở tôn giáo" },
  { code: "TIN", name: "Đất cơ sở tín ngưỡng" },
  { code: "NTD", name: "Đất làm nghĩa trang, nghĩa địa, nhà tang lễ, nhà hỏa táng" },
  { code: "SON", name: "Đất sông, ngòi, kênh, rạch, suối" },
  { code: "MNC", name: "Đất có mặt nước chuyên dùng" },
  { code: "PNK", name: "Đất phi nông nghiệp khác" },
  { code: "BCS", name: "Đất bằng chưa sử dụng" },
  { code: "DCS", name: "Đất đồi núi chưa sử dụng" },
  { code: "NCS", name: "Núi đá không có rừng cây" },
  { code: "LNK", name: "Đất trồng cây lâu năm khác" },
  { code: "CTC", name: "Công trình công cộng" },
  { code: "HNK", name: "Đất trồng cây hằng năm khác" },
  { code: "KXD", name: "Không xác định" },
];

const DomainQuyHoach = [
  { code: "DGT", name: "Đất giao thông" },
  { code: "SON", name: "Đất sông, kênh mương, hồ thủy lợi, thủy điện" },
  { code: "DYT", name: "Đất y tế" },
  { code: "DGD", name: "Đất giáo dục" },
  { code: "DAN", name: "Đất an ninh quốc phòng" },
  { code: "DHT", name: "Đất phát triển hạ tầng kỹ thuật" },
  { code: "KDC", name: "Đất khu dân cư" },
  { code: "TSC", name: "Đất xây dựng trụ sở cơ quan, công trình sự nghiệp" },
  { code: "SKC", name: "Đất cơ sở sản xuất kinh doanh" },
  { code: "CTC", name: "Đất công trình công cộng" },
  { code: "TMD", name: "Đất dịch vụ, thương mại, du lịch" },
  { code: "KCN", name: "Đất khu công nghiệp" },
  { code: "DAQ", name: "Đất dự án quy hoạch" },
  { code: "DKH", name: "Đất khác" },
  { code: "KDT", name: "Đất trung tâm đô thị" },
  { code: "DTH", name: "Đất làng đô thị hóa" },
  { code: "NTD", name: "Đất nghĩa trang, nghĩa địa" },
  { code: "OCL", name: "Đất nhà ở chia lô" },
  { code: "OCC", name: "Đất nhà ở chung cư" },
  { code: "OPT", name: "Đất nhà ở liên kế phố-thương mại" },
  { code: "NOV", name: "Đất nhà ở vườn" },
  { code: "NVB", name: "Đất nhà vườn, biệt thự" },
  { code: "CTD", name: "Đất ở cao tầng kết hợp công trình dịch vụ đô thị" },
  { code: "ODC", name: "Đất ở hiện hữu tự điều chỉnh, cải tạo, chỉnh trang" },
  { code: "KON", name: "Đất ở làng nghề" },
  { code: "OTB", name: "Đất ở mật độ trung bình" },
  { code: "TDC", name: "Đất ở tự điều chỉnh" },
  { code: "DKV", name: "Đất Golf, giải trí" },
  { code: "TON", name: "Đất tôn giáo, tín ngưỡng" },
  { code: "TDU", name: "Đất trường đua" },
  { code: "DVH", name: "Đất văn hóa" },
  { code: "DTC", name: "Đất khu đô thị hiện hữu" },
  { code: "CSD", name: "Đất chưa sử dụng" },
  { code: "ONC", name: "Đất ở nhà phố cải tạo" },
  { code: "DDS", name: "Đường sắt" },
  { code: "OTT", name: "Đất ở mới thấp tầng" },
  { code: "ODA", name: "Đất ở dự án" },
  { code: "NTS", name: "Đất nuôi trồng thủy sản tập trung" },
  { code: "BDX", name: "Đất bến xe, bến bãi, đậu xe, ga" },
  { code: "DKB", name: "Đất kho bãi" },
  { code: "LUA", name: "Đất trồng lúa" },
  { code: "CLN", name: "Đất trồng cây lâu năm" },
  { code: "HNK", name: "Đất trồng cây hằng năm" },
  { code: "DRA", name: "Đất bãi thải, xử lý chất thải" },
  { code: "MNC", name: "Đất có mặt nước chuyên dùng" },
  { code: "DDT", name: "Đất di tích thắng cảnh" },
  { code: "LMU", name: "Đất làm muối" },
  { code: "NNK", name: "Đất nông nghiệp khác" },
  { code: "PNK", name: "Đất phi nông nghiệp khác" },
  { code: "RDT", name: "Đất rừng đặc trưng" },
  { code: "RPH", name: "Đất rừng phòng hộ" },
  { code: "RSX", name: "Đất rừng sản xuất" },
  { code: "SKX", name: "Đất sản xuất vật liệu xây dựng, gốm sứ" },
  { code: "ONT", name: "Đất ở thuần" },
  { code: "DCH", name: "Đất bãi bồi ven sông" },
  { code: "CHH", name: "Đất canh tác hỗn hợp" },
  { code: "OMC", name: "Đất ở mật độ cao" },
  { code: "SKS", name: "Đất cho hoạt động khoán sản" },
  { code: "COC", name: "Đất chăn nuôi" },
  { code: "CQH", name: "Đất chưa quy hoạch" },
  { code: "OCT", name: "Đất ở cao tầng" },
  { code: "DXH", name: "Đất xã hội" },
  { code: "DCX", name: "Đất cây xanh công viên" },
  { code: "CNK", name: "Đất công nghiệp khác" },
  { code: "MDT", name: "Đất ở mật độ thấp" },
  { code: "DBV", name: "Đất bệnh viện" },
  { code: "DTT", name: "Đất cây xanh, thể dục thể thao" },
  { code: "HLA", name: "Hành lang bảo vệ" },
  { code: "OLK", name: "Đất nhà ở liên kế xây mới" },
  { code: "HCD", name: "Đất ở hỗn hợp kết hợp công trình dịch vụ đô thị" },
  { code: "OTD", name: "Đất nhà ở tái định cư" },
  { code: "DNN", name: "Đất nông nghiệp" },
  { code: "OHH", name: "Đất ở hỗn hợp" },
  { code: "OLX", name: "Đất ở làng xóm" },
  { code: "CVO", name: "Đất công cộng đơn vị ở" },
  { code: "THP", name: "Đất trường THPT" },
  { code: "THT", name: "Đất trường THCS, tiểu học, mầm non" },
  { code: "DVO", name: "Đất đơn vị ở" },
  { code: "DKT", name: "Đất kho tàng" },
  { code: "NCD", name: "Đất trung tâm nghiên cứu, đào tạo" },
  { code: "CXC", name: "Đất cây xanh chuyên đề" },
  { code: "CXL", name: "Đất cây xanh cách ly" },
  { code: "DLN", name: "Đất lâm nghiệp" },
  { code: "OLX", name: "Đất ở làng xóm" },
  { code: "NNO", name: "Đất nhóm nhà ở" },
  { code: "CCDT", name: "Đất công cộng đô thị" },
  { code: "CCDVO", name: "Đất công cộng đơn vị ở" },
  { code: "GD2", name: "Đất trường THPT" },
  { code: "GD1", name: "Đất trường THCS, tiểu học, mầm non" },
  { code: "CXDT", name: "Đất cây xanh đô thị" },
  { code: "DVO", name: "Đất đơn vị ở" },
  { code: "CN", name: "Đất công nghiệp" },
  { code: "KTBB", name: "Đất kho tàng, bến bãi" },
  { code: "NCDT", name: "Đất trung tâm nghiên cứu, đào tạo" },
  { code: "CQCT", name: "Đất cơ quan, công ty" },
  { code: "TTYT", name: "Đất trung tâm y tế" },
  { code: "DL", name: "Đất du lịch" },
  { code: "TGDT", name: "Đất tôn giáo, di tích" },
  { code: "HTKT", name: "Đất công trình đầu mối HTKT" },
  { code: "CXCD", name: "Đất cây xanh chuyên đề" },
  { code: "TDTT", name: "Đất trung tâm TDTT" },
  { code: "CXCL", name: "Đất cây xanh cách ly" },
  { code: "ANQP", name: "Đất an ninh quốc phòng" },
  { code: "NT", name: "Đất nghĩa trang" },
  { code: "NN", name: "Đất nông nghiệp" },
  { code: "OLX", name: "Đất ở làng xóm" },
  { code: "NNO", name: "Đất nhóm nhà ở" },
  { code: "CCDT", name: "Đất công cộng đô thị" },
  { code: "CCDVO", name: "Đất công cộng đơn vị ở" },
  { code: "GD2", name: "Đất trường THPT" },
  { code: "GD1", name: "Đất trường THCS, tiểu học, mầm non" },
  { code: "CXDT", name: "Đất cây xanh đô thị" },
  { code: "DVO", name: "Đất đơn vị ở" },
  { code: "CN", name: "Đất công nghiệp" },
  { code: "KTBB", name: "Đất kho tàng, bến bãi" },
  { code: "NCDT", name: "Đất trung tâm nghiên cứu, đào tạo" },
  { code: "CQCT", name: "Đất cơ quan, công ty" },
  { code: "TTYT", name: "Đất trung tâm y tế" },
  { code: "DL", name: "Đất du lịch" },
  { code: "TGDT", name: "Đất tôn giáo, di tích" },
  { code: "HTKT", name: "Đất công trình đầu mối HTKT" },
  { code: "CXCD", name: "Đất cây xanh chuyên đề" },
  { code: "TDTT", name: "Đất trung tâm TDTT" },
  { code: "CXCL", name: "Đất cây xanh cách ly" },
  { code: "ANQP", name: "Đất an ninh quốc phòng" },
  { code: "NT", name: "Đất nghĩa trang" },
  { code: "NN", name: "Đất nông nghiệp" },
  { code: "LN", name: "Đất lâm nghiệp" },
  { code: "MN", name: "Mặt nước" },
  { code: "DGT", name: "Đất giao thông" },
  { code: "OLK", name: "Đất ở liền kề" },
  { code: "OCC", name: "Đất ở chung cư" },
  { code: "OHH", name: "Đất ở hỗn hợp" },
  { code: "OBT", name: "Đất ở biệt thự" },
  { code: "TMDV", name: "Đất thương mại - dịch vụ" },
  { code: "HH", name: "Đất hỗn hợp" },
  { code: "VH", name: "Đất văn hóa" },
  { code: "GD", name: "Đất trường học" },
  { code: "CXDVO", name: "Đất cây xanh đơn vị ở" },
  { code: "CX", name: "Đất cây xanh" },
  { code: "DK", name: "Đất khác" },
];

export const DomainAll: Array<Domain> = [...DomainHienTrang, ...DomainQuyHoach];

const reducefn = (dmObj: { [k: string]: string }, dm: Domain) => {
  dmObj[dm.code] = dm.name;
  return dmObj;
};

export const dmMucDichSdd = DomainAll.reduce(reducefn, {});

export const dmLoaiQuyHoach = DmLoaiQuyHoach.reduce(reducefn, {});

export const dmPhuongXa = DomainPhuongXa.reduce(reducefn, {});
