import React from "react";
import "./select.css";

export interface SelectFloatLabelProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

const SelectFloatLabel: React.FC<SelectFloatLabelProps> = ({ placeholder, children, required, ...props }) => {
  return (
    <div className="select-float-label">
      <select {...props}>{children}</select>
      <label>
        {required && <span>* </span>}
        {placeholder}
      </label>
    </div>
  );
};

const Option: React.FC<React.OptionHTMLAttributes<HTMLOptionElement>> = ({ children, ...props }) => {
  return <option {...props}>{children}</option>;
};

export default SelectFloatLabel;
export { Option };
