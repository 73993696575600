import { useCallback } from "react";
import { LayerName } from "../common/constants";
import { Domain } from "../common/domain";
import useWriteTransaction from "./transaction.service";

const useDistrict = () => {
  const transaction = useWriteTransaction(LayerName.RANHGIOI_PHUONGXA);

  const getDistrict = useCallback(async (): Promise<Array<Domain>> => {
    const { features } = await transaction.getFeatures({ propertyNames: ["TenPhuong", "MaPX"] });
    const phuongXas = features.map((m) => ({ name: m.properties.TenPhuong, code: m.properties.MaPX }));
    return phuongXas;
  }, []);

  return {
    ...transaction,
    getDistrict,
  };
};

export default useDistrict;
