import React, { FunctionComponent } from "react";

interface TabPanelProps {
  id: string;
  tabId: string;
  tabIndex: number;
  selectedTab: number;
  children?: any;
}

const TabPanel: FunctionComponent<TabPanelProps> = ({ children, id, tabId, tabIndex, selectedTab }) => (
  <section
    role="tabpanel"
    className="section-tab custom-scrollbar"
    id={id}
    aria-labelledby={tabId}
    hidden={selectedTab !== tabIndex}
    tabIndex={0}
  >
    {children}
  </section>
);

export default TabPanel;
