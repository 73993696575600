import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { LayerName, SRSNAME } from "../../../common/constants";
import { FeatureGeoJson, HienTrangSDD, QHCSuDungDat, QHSuDungDatTNMT } from "../../../common/definitions";
import { readFeature } from "../../../common/map.util";
import useWriteTransaction from "../../../services/transaction.service";
import { intersects } from "ol/format/filter";
import { Feature, Map } from "ol";
import Detail, { RowDetails } from "../Detail";
import BackComponent from "../BackComponent";
import useQuery from "../../../hooks/useQuery";
import { MultiPolygon } from "ol/geom";
import Intersects from "ol/format/filter/Intersects";
import Contains from "ol/format/filter/Contains";
import { within } from "ol/format/filter";
import FormatGeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";
import { Fill, Stroke, Style } from "ol/style";
import LayerGroup from "ol/layer/Group";
import { GroupLayerOptions } from "ol-layerswitcher";

interface InfoQHCSuDungDatProps {
  // loadEnd: (feature: Feature) => void;
  // onClose?: () => void;
  feature: Feature<MultiPolygon>;
  map: Map;
}

const InfoQHCSuDungDat: FC<InfoQHCSuDungDatProps> = ({ feature, map }) => {
  const writeTransactionQhc = useWriteTransaction<QHCSuDungDat>(LayerName.QHC_SUDUNGDAT);
  const [properties, setProperties] = useState<Partial<QHCSuDungDat>>({});
  const [layerGroup] = useState(
    new LayerGroup({
      layers: [
        new VectorLayer({
          source: new Vector(),
          style: new Style({
            stroke: new Stroke({ color: "#000", width: 2 }),
            fill: new Fill({ color: "transparent" }),
          }),
        }),
      ],
      displayInLayerSwitcher: false,
      zIndex: 9,
    } as GroupLayerOptions),
  );
  const rows: RowDetails = [
    { name: "DienTich", title: "Diện tích" },
    { name: "MaQuyUoc", title: "Mã quy ước" },
    { name: "ChucNang", title: "Chức năng" },
    { name: "MaSo", title: "Mã số" },
    { name: "GhiChu", title: "Ghi chú" },
    { name: "MucDichSuDungDat", title: "Mục đích sử dụng đất" },
  ];

  const fitExtentQuyHoachChung = useCallback(
    (featureFixExtent: Feature<MultiPolygon>) => {
      const geometry = featureFixExtent.getGeometry();
      if (geometry) {
        map.getView().fit(geometry.getExtent());
        (layerGroup.getLayersArray()[0] as VectorLayer<Vector>).getSource()?.addFeature(featureFixExtent);
      }
    },
    [layerGroup, map],
  );

  const getQhcSuDungDat = useCallback(() => {
    const geometry = feature?.getGeometry();
    if (geometry)
      writeTransactionQhc.getFeatures({ filter: new Intersects("geom", geometry, SRSNAME) }).then(({ features }) => {
        const featureQHchung = features.find((f) => f.properties.MucDichSuDungDat !== "DGT");
        if (featureQHchung) {
          const featureReaded = readFeature<MultiPolygon>(featureQHchung);
          setProperties(featureReaded.getProperties());
          fitExtentQuyHoachChung(featureReaded);
        }
      });
  }, [feature, fitExtentQuyHoachChung, writeTransactionQhc]);

  useEffect(() => {
    map.addLayer(layerGroup);
    getQhcSuDungDat();
    return () => {
      map.removeLayer(layerGroup);
    };
  }, []);

  return (
    <>
      <Detail rows={rows} data={properties} />
    </>
  );
};

export default InfoQHCSuDungDat;
