import { intersects } from "ol/format/filter";
import { MultiPolygon, MultiLineString as MultiLineStringOl } from "ol/geom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LayerName } from "../../common/constants";
import useQuery from "../../hooks/useQuery";
import useWriteTransaction from "../../services/transaction.service";
import { QHPKTimDuong } from "../../common/definitions";
import { fromExtent } from "ol/geom/Polygon";
import { readFeature } from "../../common/map.util";
import { MultiLineString } from "geojson";
import { getCenter } from "ol/extent";
import { lineString, pointToLineDistance } from "@turf/turf";

interface InfoLoGioiProps {}

const InfoLoGioi: React.FC<InfoLoGioiProps> = () => {
  const { searchParams } = useQuery();
  const transactionHienTrang = useWriteTransaction(LayerName.HIEN_TRANG);
  const transactionTimDuong = useWriteTransaction<QHPKTimDuong, MultiLineString>(LayerName.QHPK_TIMDUONG);
  const [timDuongs, setTimDuongs] = useState<Array<QHPKTimDuong>>([]);
  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);

  const getInfoLoGioi = useCallback(async (id: string | null) => {
    if (id) {
      transactionHienTrang.getFeature(id).then(({ features: [feature] }) => {
        if (feature) {
          const featureReaded = readFeature<MultiPolygon>(feature);
          const extent = featureReaded.getGeometry()?.getExtent();
          featureReaded.getGeometry()?.getFlatCoordinates();
          if (extent) {
            const center = getCenter(extent);
            let newPolygon = fromExtent(extent);
            newPolygon.scale(2);
            transactionTimDuong.getFeatures({ filter: intersects("geom", newPolygon) }).then(({ features }) => {
              const distanceLess: Array<QHPKTimDuong> = [];
              features.forEach((f) => {
                const ft = readFeature<MultiLineStringOl>(f);
                const geo = ft.getGeometry();
                if (geo) {
                  const lines = geo.getLineStrings();
                  lines.forEach((ln) => {
                    const lineStr = lineString(ln.getCoordinates());
                    const distance = pointToLineDistance(center, lineStr);
                    if (distance < 3000) {
                      distanceLess.push(f.properties);
                    }
                  });
                }
              });
              // const properties = features.map((m) => m.properties as QHPKTimDuong);
              const lineLessFiveThousandFilter = distanceLess.reduce<Array<QHPKTimDuong>>((arr, curr) => {
                const ln = arr.find((f) => f.TenDuong === curr.TenDuong && f.LoGioi === curr.LoGioi);
                if (!ln) {
                  arr.push(curr);
                }
                return arr;
              }, []);
              setTimDuongs(lineLessFiveThousandFilter);
            });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    getInfoLoGioi(fid);
  }, [fid, getInfoLoGioi]);

  return (
    <div style={{ paddingTop: 6 }}>
      {timDuongs.length > 0 && (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th colSpan={2}>Lộ giới</th>
            </tr>
          </thead>
          <tbody>
            {timDuongs.map((timDuong, index) => (
              <tr key={index}>
                <td width={250}>{timDuong.TenDuong}</td>
                <td align="center">{timDuong.LoGioi}m</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InfoLoGioi;
