import { FC } from "react";
import { TileLayer } from "../Layers";
import tileImage from "../Source/tile-image";

interface GoogleSatelliteProps {}

const url = "https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}";

const GoogleSatellite: FC<GoogleSatelliteProps> = () => {
  const source = tileImage(url);
  return <TileLayer source={source} visible={false} />;
};

export default GoogleSatellite;
