import { Feature } from "ol";
import * as turf from "@turf/turf";
import FormatGeoJSON from "ol/format/GeoJSON";
import FormatWKT from "ol/format/WKT";
import { Geometry, MultiPolygon } from "ol/geom";
import { FeatureGeoJson } from "./definitions";
import { GeoJsonProperties } from "geojson";
import { Fill, Stroke, Style } from "ol/style";

export const wktFormat = <T>(feature: FeatureGeoJson<T>) => {
  const geoJsonFormat = new FormatGeoJSON();
  const featureReaded = geoJsonFormat.readFeature(feature);
  const wkt = new FormatWKT();
  return wkt.writeFeature(featureReaded);
};

export const readFeature = <T extends Geometry = MultiPolygon>(feature: any): Feature<T> => {
  const geoJsonFormat = new FormatGeoJSON();
  const featureReaded = geoJsonFormat.readFeature(feature) as Feature<T>;
  return featureReaded;
};

export const intersectFeatures = <T extends GeoJsonProperties, P extends GeoJsonProperties>(
  feature: FeatureGeoJson<T>,
  features: FeatureGeoJson<P>[],
) => {
  const intersects: Feature<Geometry>[] = [];
  features.forEach((feat) => {
    const intersection = turf.intersect(feature, feat);
    if (intersection) {
      intersection.properties = feat.properties;
      intersection.id = feat.id;
    }
    intersects.push(readFeature(intersection) as Feature<Geometry>);
  });
  return intersects;
};

export const highLightStyle = new Style({
  fill: new Fill({ color: "#00f6ff33" }),
  stroke: new Stroke({ color: "#FF1700", width: 2.5 }),
});
