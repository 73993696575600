import classNames from "classnames";
import React, { FunctionComponent, LegacyRef } from "react";

interface TabProps {
  id: string;
  title: string;
  selectedTab: number;
  index: number;
  tabPanelId: string;
  handleChange: (event: any) => void;
  tabRef: LegacyRef<HTMLButtonElement>;
}

const Tab: FunctionComponent<TabProps> = ({ id, title, selectedTab, index, tabPanelId, handleChange, tabRef }) => {
  const handleClick = () => handleChange(index);
  return (
    <li className={classNames({ "li-active": selectedTab === index })} role="presentation">
      <button
        role="tab"
        id={id}
        aria-selected={selectedTab === index}
        aria-controls={tabPanelId}
        tabIndex={selectedTab === index ? 0 : -1}
        onClick={handleClick}
        ref={tabRef}
      >
        {title}
      </button>
    </li>
  );
};
export default Tab;
