import LayerGroup from "ol/layer/Group";
import { useEffect, useState } from "react";
import { useMapContext } from "../Map/MapContext";
import LayerGroupsContext from "./LayerGroupContext";

interface LayerGroupsProps {}

const LayerGroups: React.FC<React.PropsWithChildren<LayerGroupsProps>> = ({ children }) => {
  const { map } = useMapContext();
  const [layerGroup, setLayerGroup] = useState<LayerGroup>();
  useEffect(() => {
    if (!map) return;

    const layerGroupObj = new LayerGroup();
    setLayerGroup(layerGroupObj);
    map.addLayer(layerGroupObj);

    return () => {
      map.removeLayer(layerGroupObj);
    };
  }, [map]);

  return <LayerGroupsContext.Provider value={{ layerGroup }}>{children}</LayerGroupsContext.Provider>;
};

export default LayerGroups;
