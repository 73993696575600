import React, { useRef } from "react";
import SearchSpatialComponent from "../search-spatial/SearchSpatialComponent";
import DiaChiComponent from "./DiaChi";
import ThuaDat from "./ThuaDat";
import Map from "ol/Map";
import Tabs from "../../Shared/Tabs/Tabs";

function TabSearch({ map }: { map?: Map }) {
  const tabValues = {
    1: { title: "Địa chỉ", ref: useRef(null) },
    2: { title: "Tọa độ", ref: useRef(null) },
    3: { title: "Thửa đất", ref: useRef(null) },
  };
  const content = [<DiaChiComponent map={map} />, <SearchSpatialComponent map={map} />, <ThuaDat map={map} />];
  return <Tabs tabValues={tabValues} content={content} />;
}

export default TabSearch;
