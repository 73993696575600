import { useEffect, useState } from "react";
import BackComponent from "../BackComponent";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import useIntegrateAPI from "../../../services/integrate-api";
import { GiayPhepXayDung, HienTrangSDD } from "../../../common/definitions";

interface InfoGPXDProps {
  loadEnd?: (feature: Feature<Geometry>) => void;
  onClose?: (isOpen: false) => void;
  data?: HienTrangSDD;
}

const InfoGPXD: React.FC<InfoGPXDProps> = ({ loadEnd, onClose, data }) => {
  const [gpxd, setGpxd] = useState<GiayPhepXayDung>();
  const { getGiayPhepXayDung } = useIntegrateAPI();

  useEffect(() => {
    if (data) {
      const { SoTo, SoThua, MaPX } = data;
      getGiayPhepXayDung(SoTo, SoThua, MaPX).then(setGpxd);
    }
  }, [data, getGiayPhepXayDung]);

  return (
    <>
      <BackComponent title="Thông tin giấy phép xây dựng" onBack={() => onClose && onClose(false)} />
      <div className="detail-wrapper">
        <div className="detail-group">
          <div className="detail-label">Số tờ</div>
          <div className="detail-text">{gpxd?.SoTo}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số thửa</div>
          <div className="detail-text">{gpxd?.SoThua}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số giấy phép xây dựng</div>
          <div className="detail-text">{gpxd?.SoGPXD}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ngày cấp</div>
          <div className="detail-text">{gpxd?.NgayCapGPXD}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Chủ đầu tư</div>
          <div className="detail-text">{gpxd?.ChuDauTu}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">
            Diện tích xây dựng (m<sup>2</sup>)
          </div>
          <div className="detail-text">{gpxd?.DienTichXayDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">
            Tổng diện tích sàn xây dựng (m<sup>2</sup>)
          </div>
          <div className="detail-text">{gpxd?.TongDienTichSanXayDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số tầng</div>
          <div className="detail-text">{gpxd?.SoTang}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ghi chú</div>
          <div className="detail-text">{gpxd?.GhiChu}</div>
        </div>
      </div>
    </>
  );
};

export default InfoGPXD;
