import classNames from "classnames";
import { useCallback, useState, FocusEvent, useEffect } from "react";
import "./input.css";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputFloatLabel: React.FC<Props> = ({ placeholder, className, onBlur, onFocus, required, value, ...props }) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement, Element>) => {
      if (!event.target.value) {
        setIsFocus(false);
      }
      onBlur?.(event);
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement, Element>) => {
      setIsFocus(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  useEffect(() => {
    if (value && !isFocus) {
      setIsFocus(true);
    }
  }, [isFocus, value]);

  return (
    <div className="input-float-label">
      <input
        {...props}
        className={classNames("input", className, { focus: isFocus })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value || ""}
      />
      <label>
        {required && <span>* </span>}
        {placeholder}
      </label>
    </div>
  );
};
export default InputFloatLabel;
