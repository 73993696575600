import TileWMS from "ol/source/TileWMS";
import { WORKSPACES } from "../../common/constants";
import { varConfig } from "../../common/var-config";

const tileWMS = (featureType: string, styles: string = "") =>
  new TileWMS({
    url: `${varConfig.geoServerUrl}/cuchi/wms`,
    params: {
      FORMAT: "image/png",
      VERSION: "1.1.1",
      tiled: true,
      STYLES: styles,
      LAYERS: `${WORKSPACES}:${featureType}`,
      exceptions: "application/vnd.ogc.se_inimage",
    },
    crossOrigin: "anonymous",
  });

export default tileWMS;
