import SldStyleParser from "geostyler-sld-parser";
import { ComparisonFilter } from "geostyler-style";
import { useCallback, useState } from "react";
import { Domain } from "../common/domain";
import instanceAxios from "../common/utils";

const useSldStyle = (styleName: string) => {
  const [parser] = useState(new SldStyleParser());
  const getStyleDOM = useCallback(async () => {
    const endPoint = `rest/workspaces/cuchi/styles/${styleName}.sld`;
    const { data } = await instanceAxios.get(endPoint);
    const parser = new DOMParser();
    const xml = parser.parseFromString(data, "text/xml");
    return xml;
  }, [styleName]);

  const getDomain = useCallback(async () => {
    const endPoint = `rest/workspaces/cuchi/styles/${styleName}.sld`;
    const { data } = await instanceAxios.get<string>(endPoint);
    const { output } = await parser.readStyle(data);
    if (output) {
      const filter: Array<Domain> = [];
      output.rules.forEach((rule) => {
        const name = rule.name;
        if (rule.filter) {
          const comparisonFilter = rule.filter as ComparisonFilter;
          const codeValue = comparisonFilter[2] as string | number;
          filter.push({ code: codeValue, name });
        }
      });
      return filter;
    }
    return [];
  }, [parser, styleName]);

  return { getStyleDOM, getDomain };
};

export default useSldStyle;
