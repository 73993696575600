import { Map } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { createContext, useContext } from "react";

const MapContext = createContext<{ map?: Map; highlightLayer?: VectorLayer<VectorSource> }>({});

// export default MapContext;

const useMapContext = () => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMapContext was used outside of its Provider");
  }

  return context;
};

export { MapContext as default, useMapContext };
