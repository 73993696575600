import { Map } from "ol";
import { Image } from "ol/layer";
import TileLayer from "ol/layer/Tile";
import VectorImageLayer from "ol/layer/VectorImage";
import TileSource from "ol/source/Tile";
import VectorSource from "ol/source/Vector";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import "./range-opacity.css";

type Props = {
  map: Map;
};

function RangeOpacityComponent({ map }: Props) {
  const [opacity, setOpacity] = useState(1);
  //     const opacityInput = document.getElementById('opacity-input');
  // const opacityOutput = document.getElementById('opacity-output');
  // function update() {
  //   const opacity = parseFloat(opacityInput.value);
  //   osm.setOpacity(opacity);
  //   opacityOutput.innerText = opacity.toFixed(2);
  // }
  // opacityInput.addEventListener('input', update);
  // opacityInput.addEventListener('change', update);
  // update();

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;
      // console.log(layer);

      const op = parseFloat(value);
      // setOpacity(op);
      // layer.setOpacity(op);
      setOpacity(op);
      const tileLayer = map
        .getAllLayers()
        .filter((layer) => (layer instanceof TileLayer || layer instanceof Image) && layer.get("type") !== "base");
      tileLayer.forEach((layer) => {
        layer.setOpacity(op);
      });
    },
    [map],
  );

  return (
    <div className="range-opacity">
      <label>
        <input id="opacity-input" type="range" min="0" max="1" step="0.1" value={opacity} onChange={handleChange} />
        <span id="opacity-output">{opacity}</span>
      </label>
    </div>
  );
}

export default RangeOpacityComponent;
