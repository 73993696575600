import { useEffect, useState } from "react";
import BackComponent from "../BackComponent";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import useIntegrateAPI from "../../../services/integrate-api";
import { GiayChungNhanQSDD, HienTrangSDD } from "../../../common/definitions";

interface InfoDoAnProps {
  loadEnd?: (feature: Feature<Geometry>) => void;
  onClose?: (isOpen: boolean) => void;
  data?: HienTrangSDD;
}

const InfoGCNQSDD: React.FC<InfoDoAnProps> = ({ onClose, data }) => {
  const [gcn, setGcn] = useState<GiayChungNhanQSDD>();
  const { getGiayChungNhanQSDD } = useIntegrateAPI();

  useEffect(() => {
    if (data) {
      const { SoTo, SoThua, MaPX } = data;
      getGiayChungNhanQSDD(SoTo, SoThua, MaPX).then(setGcn);
    }
  }, [data, getGiayChungNhanQSDD]);

  return (
    <>
      <BackComponent title="Thông tin giấy chứng nhận" onBack={() => onClose && onClose(false)} />
      <div className="detail-wrapper">
        <div className="detail-group">
          <div className="detail-label">Số hiệu</div>
          <div className="detail-text">{gcn?.SoHieu}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ngày cấp</div>
          <div className="detail-text">{gcn?.NgayCap}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Người sử dụng</div>
          <div className="detail-text">{gcn?.NguoiSuDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Căn cước công dân</div>
          <div className="detail-text">{gcn?.CCCD}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Địa chỉ thường trú</div>
          <div className="detail-text">{gcn?.DiaChiThuongTru}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Người ký</div>
          <div className="detail-text">{gcn?.NguoiKy}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số tờ</div>
          <div className="detail-text">{gcn?.SoTo}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số thửa</div>
          <div className="detail-text">{gcn?.SoThua}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Địa chỉ thửa đất</div>
          <div className="detail-text">{gcn?.DiaChiThuaDat}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Phường/xã</div>
          <div className="detail-text">{gcn?.TenPhuongXa}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">
            Diện tích (m<sup>2</sup>)
          </div>
          <div className="detail-text">{gcn?.DienTich}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Mục đích sử dụng</div>
          <div className="detail-text">{gcn?.MucDichSuDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Thời hạn sử dụng</div>
          <div className="detail-text">{gcn?.ThoiHanSuDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Nhà ở</div>
          <div className="detail-text">{gcn?.NhaO}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Công trình</div>
          <div className="detail-text">{gcn?.CongTrinh}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Rừng sản xuất</div>
          <div className="detail-text">{gcn?.RungSanXuat}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Cây lâu năm</div>
          <div className="detail-text">{gcn?.CayLauNam}</div>
        </div>
      </div>
    </>
  );
};

export default InfoGCNQSDD;
