import { useCallback, useEffect, useMemo, useState } from "react";
import { LayerName, SRSNAME } from "../../common/constants";
import useWriteTransaction from "../../services/transaction.service";
import { intersects } from "ol/format/filter";
import { readFeature } from "../../common/map.util";
import { HienTrangSDD, ThongTinDoAn } from "../../common/definitions";
// import { Feature, Geometry } from "geojson";
import { dmLoaiQuyHoach } from "../../common/domain";
import { formatNumber } from "../../common/utils";
import BackComponent from "./BackComponent";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import useQuery from "../../hooks/useQuery";

interface InfoDoAnProps {
  loadEnd?: (feature: Feature<Geometry>) => void;
  onClose?: () => void;
}

const InfoDoAn: React.FC<InfoDoAnProps> = ({ loadEnd, onClose }) => {
  const writeTransactionHienTrang = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);
  const writeTransactionDoAn = useWriteTransaction<ThongTinDoAn>(LayerName.DO_AN);
  const { searchParams } = useQuery();
  const [thongTinDoAn, setThongTinDoAn] = useState<ThongTinDoAn>();

  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);

  const getGeometryHienTrang = useCallback(
    async (id: string) => {
      if (id) {
        const { features } = await writeTransactionHienTrang.getFeature(id);
        const [featureHienTrang] = features;
        const featureReaded = readFeature(featureHienTrang);
        return featureReaded.getGeometry();
      }
    },
    [writeTransactionHienTrang],
  );

  const getThongTinDoAn = useCallback(() => {
    if (fid) {
      getGeometryHienTrang(fid).then((geometry) => {
        if (geometry) {
          writeTransactionDoAn.getFeatures({ filter: intersects("geom", geometry, SRSNAME) }).then(({ features }) => {
            const [feature] = features;
            setThongTinDoAn(feature.properties);
            loadEnd && loadEnd(readFeature(feature));
          });
        }
      });
    }
  }, [fid, getGeometryHienTrang, loadEnd, writeTransactionDoAn]);

  useEffect(() => {
    getThongTinDoAn();
  }, []);

  return (
    <>
      <BackComponent title="Thông tin đồ án" onBack={() => onClose && onClose()} />
      <div className="detail-wrapper">
        <div className="detail-group">
          <div className="detail-label">Loai quy hoạch</div>
          <div className="detail-text">
            {thongTinDoAn?.LoaiQuyHoach ? dmLoaiQuyHoach[thongTinDoAn?.LoaiQuyHoach] : ""}
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Chủ đầu tư</div>
          <div className="detail-text">{thongTinDoAn?.ChuDauTu}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Diện tích</div>
          <div className="detail-text">
            {thongTinDoAn?.DienTich ? formatNumber(thongTinDoAn?.DienTich) : 0} m<sup>2</sup>
          </div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Tỷ lệ</div>
          <div className="detail-text">{thongTinDoAn?.TyLe}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Dân số hiện hữu</div>
          <div className="detail-text">{thongTinDoAn?.DanSoHH || ""}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Dân số quy hoạch</div>
          <div className="detail-text">{thongTinDoAn?.DanSoQH || ""}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Đơn vị tư vấn kỹ thuật</div>
          <div className="detail-text">{thongTinDoAn?.DonViTVTK}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số quyết định</div>
          <div className="detail-text">{thongTinDoAn?.SoQD}</div>
        </div>

        <div className="detail-group">
          <div className="detail-label">Cơ quan phê duyệt</div>
          <div className="detail-text">{thongTinDoAn?.CoQuanPD}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ngày phê duyệt</div>
          <div className="detail-text">{thongTinDoAn?.NgayDuyet}</div>
        </div>
      </div>
    </>
  );
};

export default InfoDoAn;
