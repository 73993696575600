import { FeatureCollection, Geometry, MultiPolygon } from "geojson";
import { Feature } from "ol";
import Filter from "ol/format/filter/Filter";
import WFS, { FeatureType, WriteGetFeatureOptions, WriteTransactionOptions } from "ol/format/WFS";
import { useCallback, useState } from "react";
import { SRSNAME, WORKSPACES } from "../common/constants";
import instanceAxios from "../common/utils";
import { varConfig } from "../common/var-config";
import { xml2json } from "xml-js";

export type WFSVendorParameters = {
  cql_filter?: string;
  srsName?: string;
  strict?: string;
  namespace?: string;
  featureid?: string;
  propertyName?: string;
  request: "GetFeature";
  version: "1.1.0";
  typeName: string;
  outputFormat: "json" | "text/xml";
  service?: "wfs" | "wms";
  maxFeatures?: string;
  filter?: string;
};

export interface WriteTransaction<T extends Record<string, any>, P extends Geometry = MultiPolygon> {
  getFeatures: (params: {
    filter?: Filter;
    paginationOptions?: { featurePerPage: number; featureOffset: number };
    featureTypes?: (string | FeatureType)[];
    propertyNames?: Array<string>;
  }) => Promise<FeatureCollection<P, T>>;
  getFeature: (fid: string) => Promise<FeatureCollection<MultiPolygon, T>>;
  addFeatures: (features: Array<Feature>) => Promise<{ fid: string }>;
  updateFeatures: (features: Array<Feature>) => Promise<string>;
  deleteFeatures: (features: Array<Feature>) => Promise<string>;
}

enum transactionActions {
  insert,
  update,
  delete,
}
const options = {
  srsName: SRSNAME,
  featureNS: varConfig.geoServerUrl,
  featurePrefix: WORKSPACES,
};

const useWriteTransaction = <T extends Record<string, any>, P extends Geometry = MultiPolygon>(featureType: string) => {
  const [formatWfs] = useState(new WFS());
  const getFeatures = useCallback(
    async (params: {
      filter?: Filter;
      paginationOptions?: { featurePerPage: number; featureOffset: number };
      featureTypes?: (string | FeatureType)[];
      propertyNames?: Array<string>;
    }) => {
      try {
        const { filter, paginationOptions, featureTypes, propertyNames } = params;
        const writeGetFeatureOptions: WriteGetFeatureOptions = {
          ...options,
          featureTypes: [featureType],
          outputFormat: "application/json",
        };
        if (propertyNames) {
          writeGetFeatureOptions.propertyNames = propertyNames;
        }
        if (filter) {
          writeGetFeatureOptions.filter = filter;
        }
        if (paginationOptions) {
          const { featurePerPage, featureOffset } = paginationOptions;
          writeGetFeatureOptions.maxFeatures = featurePerPage;
          writeGetFeatureOptions.startIndex = featureOffset;
        }
        if (featureTypes) {
          writeGetFeatureOptions.featureTypes.push(...featureTypes);
        }
        const featureRequest = formatWfs.writeGetFeature(writeGetFeatureOptions);

        const { data } = await instanceAxios.post<FeatureCollection<P, T>>(
          "/wfs",
          new XMLSerializer().serializeToString(featureRequest),
          { headers: { "Content-Type": "text/xml" } },
        );
        return data;
      } catch (error) {
        throw Error("Đã xảy ra lỗi!");
      }
    },
    [featureType, formatWfs],
  );

  const getFeature = useCallback(
    async (
      fid: string,
      cql_filter?: { propertyName: string; litertal: string },
    ): Promise<FeatureCollection<MultiPolygon, T>> => {
      const parameters: WFSVendorParameters = {
        request: "GetFeature",
        version: "1.1.0",
        service: "wfs",
        typeName: `${WORKSPACES}:${featureType}`,
        outputFormat: "json",
        maxFeatures: "500",
        featureid: fid,
      };
      if (cql_filter) {
        parameters.filter = `(<Filter xmlns="http://www.opengis.net/ogc"><And><FeatureId fid="${fid}"/><PropertyIsEqualTo><PropertyName>${cql_filter.propertyName}</PropertyName><Literal>${cql_filter.litertal}</Literal></PropertyIsEqualTo></And></Filter>)`;
        delete parameters.featureid;
      }
      const search = new URLSearchParams(parameters).toString();
      const { data } = await instanceAxios.get<FeatureCollection<MultiPolygon, T>>(`/wfs?${search}`, {
        headers: { "Content-Type": "text/xml" },
      });
      return data;
    },
    [featureType],
  );

  const writeTransaction = useCallback(
    async (features: Array<Feature>, type: transactionActions) => {
      try {
        let featureRequest;
        const writeTransactionOptions: WriteTransactionOptions = {
          ...options,
          featureType: `${WORKSPACES}:${featureType}`,
          nativeElements: [],
          version: "1.1.0",
          gmlOptions: {
            featureNS: varConfig.geoServerUrl,
            featureType: `${WORKSPACES}:${featureType}`,
            srsName: SRSNAME,
          },
        };

        switch (type) {
          // case transactionActions.insert:
          //   features.forEach((ft) => {
          //     ft.setProperties({ ...ft.getProperties(), active: true, created_at: new Date().toISOString() });
          //   });
          //   featureRequest = formatWfs.writeTransaction(features, [], [], writeTransactionOptions);
          //   break;
          case transactionActions.update:
            featureRequest = formatWfs.writeTransaction([], features, [], writeTransactionOptions);
            break;
          case transactionActions.delete:
            featureRequest = formatWfs.writeTransaction([], [], features, writeTransactionOptions);
            break;
          default:
            features.forEach((ft) => {
              ft.setProperties({ ...ft.getProperties(), active: true, created_at: new Date().toISOString() });
            });
            featureRequest = formatWfs.writeTransaction(features, [], [], writeTransactionOptions);
            break;
        }
        const xs = new XMLSerializer();
        const payload = xs.serializeToString(featureRequest);
        const { data } = await instanceAxios.post<string>("/wfs", payload, {
          headers: { "Content-Type": "text/xml" },
        });
        return data;
      } catch (error) {
        throw Error("error");
      }
    },
    [featureType, formatWfs],
  );

  const addFeatures = useCallback(
    async (features: Array<Feature>) => {
      const result = await writeTransaction(features, transactionActions.insert);
      const jsonStringfy = xml2json(result, { compact: true });
      const obj = JSON.parse(jsonStringfy);
      const feature = obj["wfs:TransactionResponse"]["wfs:InsertResults"]["wfs:Feature"];
      if (feature instanceof Array) {
        return feature.map((m) => ({ fid: m["ogc:FeatureId"]["_attributes"]["fid"] }));
      }
      return [{ fid: feature["ogc:FeatureId"]["_attributes"]["fid"] }];
    },
    [writeTransaction],
  );

  const updateFeatures = useCallback(
    (features: Array<Feature>) => {
      return writeTransaction(features, transactionActions.update);
    },
    [writeTransaction],
  );

  const deleteFeatures = useCallback(
    (features: Array<Feature>) => {
      return writeTransaction(features, transactionActions.delete);
    },
    [writeTransaction],
  );

  return { getFeature, getFeatures, addFeatures, updateFeatures, deleteFeatures };
};

export default useWriteTransaction;
