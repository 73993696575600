import { FeatureCollection, MultiPolygon } from "geojson";
import { and, equalTo } from "ol/format/filter";
import Filter from "ol/format/filter/Filter";
import { FeatureType } from "ol/format/WFS";
import { useCallback } from "react";
import { LayerName } from "../common/constants";
import { HienTrangSDD } from "../common/definitions";
import useWriteTransaction from "./transaction.service";

const useTransactionHienTrang = () => {
  const { getFeature: get, getFeatures: getAll, ...rest } = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);

  const getFeature = useCallback(async (fid: string): Promise<FeatureCollection<MultiPolygon, HienTrangSDD>> => {
    return get(fid, { propertyName: "active", litertal: "true" });
  }, []);

  const getFeatures = useCallback(
    (params: {
      filter?: Filter;
      paginationOptions?: { featurePerPage: number; featureOffset: number };
      featureTypes?: (string | FeatureType)[];
      propertyNames?: Array<string>;
    }) => {
      params.filter = params.filter ? and(equalTo("active", "true"), params.filter) : equalTo("active", "true");
      return getAll(params);
    },
    [],
  );
  return { getFeature, getFeatures, ...rest };
};

export default useTransactionHienTrang;
