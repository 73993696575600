import { useEffect, useState } from "react";
import { useMapContext } from "../Map/MapContext";

interface SwitchLayerProps {}

const SwitchLayer: React.FC<SwitchLayerProps> = () => {
  const [layers, setLayers] = useState(["b1", "b2"]);
  const { map } = useMapContext();
  useEffect(() => {
    return () => {};
  }, []);

  return layers.length === 2 ? (
    <div className="switch-layer">
      {layers.map((layer) => (
        <div></div>
      ))}
    </div>
  ) : null;
};

export default SwitchLayer;
