export enum PARAMS {
  search = "search",
  place = "place",
}

export enum LayerName {
  HIEN_TRANG = "ht_sudungdat",
  QUY_HOACH = "qhpk_sudungdat",
  DO_AN = "qhpk_ranhdoan",
  RANHGIOI_QUANHUYEN = "ranhgioiquanhuyen",
  RANHGIOI_PHUONGXA = "ranhgioiphuongxa",
  QHC_SUDUNGDAT = "qhc_sudungdat",
  QHSUDUNGDAT_TNMT = "quyhoachsddcaphuyen",
  QHPK_TIMDUONG = "qhpk_timduong",
}

export const ZOOM = 14;

export const CENTER = [106.52290928328458, 11.033200848395749]; //11.033200848395749, 106.52290928328458

export const WORKSPACES = "cuchi";

export const SRSNAME = "EPSG:3857";

export const LAYER_ID = {
  HIGHLIGHT: "HIGHLIGHT-LAYER",
};

export const MaSddEsc = ["DGT", "CXDT", "CXDVO", "CXCD", "DTL"];

export const PRJ_VN2000 =
  'PROJCS["Transverse_Mercator",GEOGCS["GCS_VN_2000",DATUM["D_Vietnam_2000",SPHEROID["WGS84",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["false_easting",500000.0],PARAMETER["false_northing",0.0],PARAMETER["central_meridian",105.45],PARAMETER["scale_factor",0.9999],PARAMETER["latitude_of_origin",0.0],UNIT["Meter",1.0], AUTHORITY["EPSG","41003"]]';

export const EPSG3857 = "EPSG:3857";

export const fieldsHtSuDungDat = [
  { prop: "MaMucDichSDD", name: "Mã mục đích sử dụng đất", type: "text" },
  { prop: "DienTich", name: "Diện tích", type: "text" },
  { prop: "GhiChu", name: "Ghi chú", type: "text" },
  { prop: "ChuSuDungDat", name: "Chủ sử dụng đất", type: "text" },
  { prop: "SoTo", name: "Số tờ", type: "text" },
  { prop: "SoThua", name: "Số thửa", type: "text" },
];

export const fielsQhSuDungDat = [
  { prop: "MaMucDichSDD", name: "Mã mục đích sử dụng đất", type: "string" },
  { prop: "TangCaoXay", name: "Tầng cao xây", type: "string" },
  { prop: "MatDoXayDung", name: "Mật độ xây dựng", type: "string" },
  { prop: "HeSoSuDung", name: "Hệ số xây dựng", type: "string" },
  { prop: "QuyMoDanSo", name: "Quy mô dân số", type: "string" },
  { prop: "TenMucDichSDD", name: "Tên mục đích sử dụng đất", type: "string" },
  { prop: "TenCongTrinh", name: "Tên công trình", type: "string" },
  { prop: "LoaiQuyHoach", name: "Loại quy hoạch", type: "string" },
  { prop: "KyHieuO", name: "Ký hiệu ô", type: "string" },
];

export const FIELD_LAYER = {
  [LayerName.HIEN_TRANG]: [
    { prop: "MaMucDichSDD", name: "Mã mục đích sử dụng đất", type: "text" },
    { prop: "DienTich", name: "Diện tích", type: "text" },
    { prop: "GhiChu", name: "Ghi chú", type: "text" },
    { prop: "ChuSuDungDat", name: "Chủ sử dụng đất", type: "text" },
    { prop: "SoTo", name: "Số tờ", type: "text" },
    { prop: "SoThua", name: "Số thửa", type: "text" },
  ],
  [LayerName.QUY_HOACH]: [
    { prop: "MaMucDichSDD", name: "Mã mục đích sử dụng đất", type: "string" },
    { prop: "TangCaoXay", name: "Tầng cao xây", type: "string" },
    { prop: "MatDoXayDung", name: "Mật độ xây dựng", type: "string" },
    { prop: "HeSoSuDung", name: "Hệ số xây dựng", type: "string" },
    { prop: "QuyMoDanSo", name: "Quy mô dân số", type: "string" },
    { prop: "TenMucDichSDD", name: "Tên mục đích sử dụng đất", type: "string" },
    { prop: "TenCongTrinh", name: "Tên công trình", type: "string" },
    { prop: "LoaiQuyHoach", name: "Loại quy hoạch", type: "string" },
    { prop: "KyHieuO", name: "Ký hiệu ô", type: "string" },
  ],
};

const color = {
  DVO: "rgb(255,127,0)", // #FF7F00
  NNO: "rgb(204,153,0)", // #CC9900
  OLK: "rgb(204,102,0)", // #CC6600
  OCC: "rgb(255,191,0)", // #FFBF00
  OHH: "rgb(153,144,0)", // #999000
  OBT: "rgb(127,127,0)", // #7F7F00
  OLX: "rgb(127,127,63)", // #7F7F3F
  CCDT: "rgb(255,0,0)", // #FF0000
  CCDVO: "rgb(255,0,0)", // #FF0000
  TMDV: "rgb(255,127,127)", // #FF7F7F
  HH: "rgb(255,159,127)", // #FF9F7F
  VH: "rgb(255,0,255)", // #FF00FF
  GD2: "rgb(153,38,0)", // #992600
  GD1: "rgb(153,76,76)", // #994C4C
  GD: "rgb(153,38,0)", // #992600
  CXDT: "rgb(102,204,0)", // #66CC00
  CXDVO: "rgb(63,127,0)", // #3F7F00
  CXCD: "rgb(0,152,0)", // #009800
  TDTT: "rgb(0,152,0)", // #009800
  CXCL: "rgb(0,127,95)", // #007F5F
  CX: "rgb(0,152,0)", // #009800
  CN: "rgb(102,0,204)", // #6600CC
  KTBB: "rgb(63,63,127)", // #3F3F7F
  NCDT: "rgb(0,144,152)", // #009098
  CQCT: "rgb(153,76,0)", // #994C00
  TTYT: "rgb(255,0,191)", // #FF00BF
  DL: "rgb(255,0,255)", // #FF00FF
  TGDT: "rgb(127,0,0)", // #7F0000
  HTKT: "rgb(114,76,152)", // #72B098
  ANQP: "rgb(66,76,38)", // #424C26
  NT: "rgb(91,91,91)", // #5B5B5B
  NN: "rgb(0,255,0)", // #00FF00
  LN: "rgb(0,76,76)", // #004C4C
  MN: "rgb(0,102,204)", // #0066CC
  DGT: "rgb(255,255,255)", // #FFFFFF
  DK: "rgb(214,214,214)", // #D6D6D6
};
