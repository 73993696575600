import React, { useRef, useState, KeyboardEvent, FunctionComponent } from "react";
import TabPanel from "./TabPanel";
import Tab from "./Tab";
import "./style.css";

interface PropsTabs {
  tabValues: any;
  content: any;
}

const Tabs: FunctionComponent<PropsTabs> = ({ tabValues, content }) => {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleClick = (index: number) => {
    setSelectedTab(index);
  };
  const handleKeyPress = (event: KeyboardEvent<HTMLUListElement>) => {
    const tabCount = Object.keys(tabValues).length;

    if (event.key === "ArrowLeft") {
      const last = tabCount;
      const next = selectedTab - 1;
      handleNextTab(last, next, 1);
    }
    if (event.key === "ArrowRight") {
      const first = 1;
      const next = selectedTab + 1;
      handleNextTab(first, next, tabCount);
    }
  };

  const handleNextTab = (firstTabInRound: number, nextTab: number, lastTabInRound: number) => {
    const tabToSelect = selectedTab === lastTabInRound ? firstTabInRound : nextTab;
    setSelectedTab(tabToSelect);
    // tabValues[tabToSelect].ref.current.focus();
  };

  return (
    <section className="tabs-wrapper">
      <div className="switcher">
        <ul role="tablist" className="tablist switcher" aria-label="Cat tabs" onKeyDown={handleKeyPress}>
          <Tab
            id="1"
            tabPanelId="tab1"
            index={1}
            handleChange={handleClick}
            selectedTab={selectedTab}
            tabRef={tabValues[1].ref}
            title={tabValues[1].title}
          />
          <Tab
            id="2"
            tabPanelId="tab2"
            index={2}
            handleChange={handleClick}
            selectedTab={selectedTab}
            tabRef={tabValues[2].ref}
            title={tabValues[2].title}
          />
          <Tab
            id="3"
            tabPanelId="tab3"
            index={3}
            handleChange={handleClick}
            selectedTab={selectedTab}
            tabRef={tabValues[3].ref}
            title={tabValues[3].title}
          />
        </ul>
      </div>
      {content.map((tab: any, index: string) => (
        <TabPanel
          id={"tab" + index + 1}
          tabId={`${index + 1}`}
          tabIndex={parseInt(index + 1)}
          selectedTab={selectedTab}
          key={"tabKey" + index}
        >
          {content[index]}
        </TabPanel>
      ))}
    </section>
  );
};

export default Tabs;
