import { FC } from "react";
import { CENTER, ZOOM } from "../common/constants";
import Map from "./Map";
import { fromLonLat } from "ol/proj";
import { osm } from "./Source";
import { Layers, TileLayer } from "./Layers";
import { Controls, FullScreenControl, SwitchLayer } from "./Controls";
import { HienTrangSuDungDat, QhcSuDungDat } from "./LayerSources";
import LayerGroup from "./LayerGroup";
import { GoogleRoadmap, GoogleSatellite } from "./BaseMaps";

interface MapPageProps {}

const MapPage: FC<MapPageProps> = () => {
  return (
    <Map center={fromLonLat(CENTER)} zoom={ZOOM}>
      <Layers>
        {/* <TileLayer source={osm()} zIndex={0} /> */}
        <LayerGroup>
          <GoogleRoadmap />
          <GoogleSatellite />
        </LayerGroup>
        <LayerGroup>
          <QhcSuDungDat />
          <HienTrangSuDungDat />
        </LayerGroup>
      </Layers>
      <Controls>
        <FullScreenControl />
        <SwitchLayer />
      </Controls>
    </Map>
  );
};

export default MapPage;
