import { ChangeEvent, FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { LayerName, PRJ_VN2000, WORKSPACES, ZOOM } from "../../common/constants";
import { HienTrangSDD } from "../../common/definitions";
import Button from "../../Shared/Button/Button";
import InputFloatLabel from "../../Shared/input/InputFloatLabel";
import SelectDistrict from "../../Shared/select/SelectDistrict";
import Map from "ol/Map";
import "./tach-ghep-thua.css";
import { MapBrowserEvent, View } from "ol";
import { fromLonLat } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import { ImageWMS, OSM, TileWMS } from "ol/source";
import { varConfig } from "../../common/var-config";
import { defaults } from "ol/control";
import { BsSearch } from "react-icons/bs";
import { unByKey } from "ol/Observable";
import { Vector } from "ol/layer";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { IoMdCloseCircleOutline } from "react-icons/io";
import useWriteTransaction from "../../services/transaction.service";
import { equalTo, intersects } from "ol/format/filter";
import { MultiPolygon, Point } from "ol/geom";
import Feature from "ol/Feature";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import { Text } from "ol/style";
import Stroke from "ol/style/Stroke";
import ImageLayer from "ol/layer/Image";
import TachGhepThua, { DataTachGhep } from "./TachGhepThua";
import * as turf from "@turf/turf";
import { Coordinate } from "ol/coordinate";
import proj4 from "proj4";
import { multiPolygon, Position } from "@turf/turf";
import AndFilter from "ol/format/filter/And";
import useTransactionHienTrang from "../../services/hienTrang.service";

enum TypeBienDong {
  Tach,
  Ghep,
}
interface MapProps {}

const MapHienTrang: FC<PropsWithChildren<MapProps>> = ({}) => {
  const [data, setData] = useState<Array<HienTrangSDD>>([]);
  const transaction = useTransactionHienTrang();
  const [typeBienDong, setTypeBienDong] = useState(TypeBienDong.Ghep);
  const [coordsExample, setCoordsExample] = useState<Array<{ x: string; y: string }>>();
  const [search, setSearch] = useState<{ soTo: string; soThua: string; maPx: string }>({
    soTo: "",
    soThua: "",
    maPx: "",
  });
  const [map, setMap] = useState<Map>();
  const [layer] = useState<Vector<VectorSource<MultiPolygon>>>(new Vector({ source: new VectorSource() }));
  const [hienTrangLayer] = useState(
    new ImageLayer({
      source: new ImageWMS({
        ratio: 1,
        url: `${varConfig.geoServerUrl}/cuchi/wms`,
        params: {
          FORMAT: "image/png",
          VERSION: "1.1.1",
          STYLES: "ht_sudungdat_print",
          LAYERS: `cuchi:${LayerName.HIEN_TRANG}`,
          exceptions: "application/vnd.ogc.se_inimage",
          CQL_FILTER: "active=true",
        },
      }),
    }),
  );

  useEffect(() => {
    const view = new View({
      center: fromLonLat([106.53137277230698, 11.0184854564124]),
      zoom: ZOOM,
      resolution: 55,
      minResolution: 0.1,
      maxResolution: 55,
    });
    const mapObj = new Map({
      target: "mapThuaEl",
      view,
      controls: defaults({ zoom: false, attribution: false }),
      layers: [
        new TileLayer({ source: new OSM() }),
        hienTrangLayer,
        new TileLayer({
          source: new TileWMS({
            url: `${varConfig.geoServerUrl}/cuchi/wms`,
            params: {
              FORMAT: "image/png",
              VERSION: "1.1.1",
              tiled: true,
              STYLES: "",
              LAYERS: `${WORKSPACES}:${LayerName.RANHGIOI_PHUONGXA}`,
              exceptions: "application/vnd.ogc.se_inimage",
            },
            crossOrigin: "anonymous",
          }),
        }),
        layer,
      ],
    });
    setMap(mapObj);
    return () => map?.setTarget(undefined);
  }, []);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setSearch({ ...search, [name]: value });
  };

  const handleChangeSelect = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = evt.target;
      setSearch({ ...search, [name]: value });
    },
    [search],
  );

  const onCloseListItem = useCallback(
    (hienTrang: HienTrangSDD) => {
      const layerSource = layer.getSource();
      const ftExit = layerSource?.getFeatures().find((f) => f.getId() && f.getId() === hienTrang.id);
      ftExit && layerSource?.removeFeature(ftExit);
      const sl = data.filter((f) => hienTrang.id && f.id !== hienTrang.id);
      setData(sl);
    },
    [data, layer, setData],
  );

  const ref = useRef<any>();

  const handleClickMap = useCallback(
    (evt: MapBrowserEvent<UIEvent>, layer: VectorLayer<VectorSource>) => {
      const { coordinate } = evt;
      transaction.getFeatures({ filter: intersects("geometry", new Point(coordinate)) }).then(({ features }) => {
        const [feature] = features;
        if (feature) {
          const selectExit = data.find((f) => feature.id && feature.id === f.id);
          let s = [...data];
          const layerSource = layer.getSource();
          if (selectExit) {
            s = data.filter((f) => f.id !== selectExit.id);
            const ftExit = layerSource?.getFeatures().find((f) => f.getId() && f.getId() === feature.id);
            ftExit && layerSource?.removeFeature(ftExit);
          } else {
            s.push({ ...feature.properties, id: feature.id });
            const ft = new Feature(new MultiPolygon(feature.geometry.coordinates));
            ft.setProperties(feature.properties);
            if (ref.current === undefined) {
              ref.current = multiPolygon(feature.geometry.coordinates);
            } else {
              ref.current = turf.union(multiPolygon(feature.geometry.coordinates), ref.current);
            }
            console.log(ref.current);

            ft.setStyle(
              () =>
                new Style({
                  fill: new Fill({ color: "yellow" }),
                  stroke: new Stroke({ color: "#0006", width: 2 }),
                  text: new Text({
                    font: "bold 16px sans-serif",
                    text: `${feature.properties.SoTo || ""} \n ${feature.properties.SoThua || ""}`,
                    fill: new Fill({ color: "#000" }),
                    // stroke: new Stroke({ color: "#000", width: 1 }),
                    overflow: true,
                  }),
                }),
            );
            ft.setId(feature.id);
            layerSource?.addFeature(ft);
          }

          setData(s);
        }
      });
      console.log(evt);
    },
    [data],
  );
  useEffect(() => {
    if (!map) return;
    let evtKey = map.on("click", (evt) => handleClickMap(evt, layer));
    return () => unByKey(evtKey);
  }, [layer, map, handleClickMap]);

  const style = useCallback(
    (hienTrang: HienTrangSDD) =>
      new Style({
        fill: new Fill({ color: "transparent" }),
        stroke: new Stroke({ color: "#FF1700", width: 4 }),
        text: new Text({
          font: "bold 26px sans-serif",
          text: `${hienTrang.SoTo || ""}\n${hienTrang.SoThua || ""}`,
          fill: new Fill({ color: "#FA1E0E" }),
          stroke: new Stroke({ color: "#fff", width: 2 }),
          overflow: true,
        }),
      }),
    [],
  );

  const extent = useCallback(
    (coordinates: Position[][][]) => {
      if (!map) return;
      const multiPolygon = turf.multiPolygon(coordinates);
      const center = turf.centroid(multiPolygon);
      map.getView().setResolution(0.05);
      map.getView().setCenter(center.geometry.coordinates);
    },
    [map],
  );

  const onAdd = useCallback(
    (hienTrang: HienTrangSDD, coordinates: { x: string; y: string }[]) => {
      const projectedCoords: Coordinate[] = [];
      for (let i = 0; i < coordinates.length; i++) {
        const { x, y } = coordinates[i];
        const coordsPrj = proj4(PRJ_VN2000, "EPSG:3857", [Number(x), Number(y)]);
        projectedCoords.push(coordsPrj);
      }
      const geometry = new MultiPolygon([[projectedCoords]]);
      const feature = new Feature<MultiPolygon>();
      feature.setGeometry(geometry);

      feature.setProperties(hienTrang);
      feature.setId(hienTrang.id);
      feature.setStyle(style(hienTrang));
      layer.getSource()?.addFeature(feature);
      if (coordinates) {
        extent([[projectedCoords]]);
      }
    },
    [extent, layer, style],
  );

  const onLoad = useCallback(
    (dataTachGhep: Array<DataTachGhep>) => {
      for (const dt of dataTachGhep) {
        const projectedCoords: Coordinate[] = [];
        for (let i = 0; i < dt.coordinates.length; i++) {
          const { x, y } = dt.coordinates[i];
          const coordsPrj = proj4(PRJ_VN2000, "EPSG:3857", [Number(x), Number(y)]);
          projectedCoords.push(coordsPrj);
        }
        const geometry = new MultiPolygon([[projectedCoords]]);
        const feature = new Feature<MultiPolygon>();
        feature.setGeometry(geometry);

        feature.setProperties(dt.hienTrang);
        feature.setId(dt.hienTrang.id);
        feature.setStyle(style(dt.hienTrang));
        layer.getSource()?.addFeature(feature);
      }
    },
    [layer, style],
  );

  const onEdit = useCallback(
    (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>) => {
      if (hienTrang.id) {
        const features = layer.getSource()?.getFeatures();
        if (features) {
          const index = features.findIndex((f) => hienTrang.id && f.getId() === hienTrang.id);
          if (index > -1) {
            const projectedCoords: Coordinate[] = [];
            for (let i = 0; i < coordinates.length; i++) {
              const { x, y } = coordinates[i];
              const coordsPrj = proj4(PRJ_VN2000, "EPSG:3857", [Number(x), Number(y)]);
              projectedCoords.push(coordsPrj);
            }
            features[index].setGeometry(new MultiPolygon([[projectedCoords]]));
            features[index].setProperties(hienTrang);
            features[index].setStyle(style(hienTrang));
            layer.getSource()?.clear();
            layer.getSource()?.addFeatures(features);
          }
        }
      }
    },
    [layer, style],
  );

  const onDelete = useCallback(
    (hienTrang: HienTrangSDD) => {
      if (hienTrang.id) {
        const feature = layer.getSource()?.getFeatureById(hienTrang.id);
        if (feature) {
          layer.getSource()?.removeFeature(feature);
        }
      }
    },
    [layer],
  );

  const onClickItem = useCallback(
    (data: HienTrangSDD) => {
      const features = layer.getSource()?.getFeatures();
      if (features) {
        const feature = features.find((f) => f.getId() && f.getId() === data.id);
        const geometry = feature?.getGeometry();
        if (geometry) {
          const coords = geometry.getCoordinates();
          extent(coords);
        }
      }
    },
    [extent, layer],
  );

  const refreshMap = useCallback(() => {
    layer.getSource()?.clear();
    hienTrangLayer.getSource()?.refresh();
    setData([]);
  }, [hienTrangLayer, layer]);

  const onComplete = useCallback(
    async (dataTachGhep: Array<DataTachGhep>) => {
      const featuresNew: Array<Feature> = [];
      const featureUpdate: Array<Feature> = [];
      for (const dttg of dataTachGhep) {
        const coords = dttg.coordinates.map((m) => [+m.x, +m.y]);
        const feature = new Feature({
          geometry: new MultiPolygon([[coords]]),
        });
        feature.setProperties(dttg.hienTrang);
        featuresNew.push(feature);
      }
      // data seleted on map
      for (const dt of data) {
        const feature = new Feature();
        feature.setProperties({ active: false });
        feature.setId(dt.id);
        featureUpdate.push(feature);
      }
      // check intersects
      // const allFeatures = layer.getSource()?.getFeatures();
      // if (allFeatures) {
      //   for (const ft of allFeatures) {
      //     const coords = ft.getGeometry()?.getCoordinates();
      //     if (coords) {
      //       const mplg = turf.multiPolygon(coords);
      //     }
      //   }
      // }
      if (!dataTachGhep.length) {
        alert("Vui lòng nhập và thêm vào danh sách thửa cần thực hiện tách/ghép thửa");
        return false;
      }
      if (typeBienDong === TypeBienDong.Ghep) {
        if (dataTachGhep.length > 1) {
          alert("Danh sách thửa ghép chỉ nên thực hiện trên một thửa");
          return false;
        }
        if (data.length < 2) {
          alert("Vui lòng chọn trên bản đồ ít nhất 2 thửa cần thực hiện ghép thửa");
          return false;
        }
        const result = await transaction.addFeatures(featuresNew);
        if (result) {
          const [{ fid }] = result;
          if (fid) {
            const [_, id] = fid.split(".");
            featureUpdate.forEach((feature) => {
              feature.setProperties({ ...feature.getProperties(), parent_id: id });
            });
            await transaction.updateFeatures(featureUpdate).then(() => {
              refreshMap();
              alert("Ghép thửa thành công!");
            });
            return true;
          }
        }
      }
      if (typeBienDong === TypeBienDong.Tach) {
        if (dataTachGhep.length < 2) {
          alert("Danh sách những thửa đã được tách phải có ít nhất 2 thửa");
          return false;
        }
        if (!data.length) {
          alert("Vui lòng chọn một thửa cần tách!");
          return false;
        }
        if (data.length > 1) {
          alert("Vui lòng chỉ chọn một thửa cần tách!");
          return false;
        }
        const [_, id] = (featureUpdate[0].getId() as string)?.split(".");
        featuresNew.forEach((feature) => {
          feature.setProperties({ ...feature.getProperties(), parent_id: id });
        });
        await Promise.all([transaction.updateFeatures(featureUpdate), transaction.addFeatures(featuresNew)]).then(
          () => {
            refreshMap();
            alert("Tách thửa thành công!");
          },
        );
        return true;
      }
      return false;
    },
    [typeBienDong, data, refreshMap],
  );

  const onChangeRadio = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setTypeBienDong(+value);
    if (+value === 1) setCoordsExample(undefined);
  }, []);

  // const onClickExample = useCallback((type: string) => {
  //   if (type === "thua1") {
  //     setCoordsExample([
  //       { x: "614834.7728000004", y: "1213544.7434999999" },
  //       { x: "614824.8002000004", y: "1213544.4406000003" },
  //       { x: "614823.7407", y: "1213570.2917999998" },
  //       { x: "614833.6127000004", y: "1213570.6884000003" },
  //       { x: "614834.7728000004", y: "1213544.7434999999" },
  //     ]);
  //   }
  //   if (type === "thua2") {
  //     setCoordsExample([
  //       { x: "614844.8011999996", y: "1213545.1033999994" },
  //       { x: "614834.7728000004", y: "1213544.7434999999" },
  //       { x: "614833.6127000004", y: "1213570.6884000003" },
  //       { x: "614833.7313000001", y: "1213570.6931999996" },
  //       { x: "614843.6117000002", y: "1213571.0445000008" },
  //       { x: "614844.8011999996", y: "1213545.1033999994" },
  //     ]);
  //   }
  // }, []);

  const handlSearch = useCallback(() => {
    const { soThua, soTo, maPx } = search;
    if (!soTo || !soThua || !maPx) {
      alert("Vui lòng nhập đấy đủ thông tin cần tìm kiếm!");
      return;
    }
    transaction
      .getFeatures({
        filter: new AndFilter(equalTo("SoTo", +soTo), equalTo("SoThua", soThua), equalTo("MaPX", maPx)),
      })
      .then(({ features }) => {
        const [feature] = features;
        if (feature) {
          const coords = feature.geometry.coordinates;
          extent(coords);
        } else {
          alert("Không tìm thấy kết quả phù hợp!");
        }
      });
  }, [extent, search, transaction]);

  const onClick = useCallback(
    (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>) => {
      const projectedCoords: Coordinate[] = [];
      for (let i = 0; i < coordinates.length; i++) {
        const { x, y } = coordinates[i];
        const coordsPrj = proj4(PRJ_VN2000, "EPSG:3857", [Number(x), Number(y)]);
        projectedCoords.push(coordsPrj);
      }
      extent([[projectedCoords]]);
    },
    [extent],
  );

  return (
    <>
      <div className="group" style={{ padding: "12px 0px 0px 172px" }}>
        <input
          type="radio"
          name="typeBienDong"
          id="ghep"
          value={TypeBienDong.Ghep}
          checked={typeBienDong === TypeBienDong.Ghep}
          onChange={onChangeRadio}
        />
        <label htmlFor="ghep" style={{ marginRight: 14 }}>
          Ghép thửa
        </label>
        <input
          type="radio"
          name="typeBienDong"
          id="tach"
          value={TypeBienDong.Tach}
          checked={typeBienDong === TypeBienDong.Tach}
          onChange={onChangeRadio}
        />
        <label htmlFor="tach">Tách thửa</label>
      </div>
      {/* {typeBienDong === TypeBienDong.Tach && (
        <>
          <span
            style={{
              color: "#fff",
              padding: "4px 6px",
              backgroundColor: "green",
              marginRight: 4,
              marginLeft: 382,
            }}
            onClick={() => onClickExample("thua1")}
          >
            Thửa 1
          </span>
          <span
            style={{ color: "#fff", padding: "4px 6px", backgroundColor: "green" }}
            onClick={() => onClickExample("thua2")}
          >
            Thửa 2
          </span>
        </>
      )} */}
      <div className="wrapper-biendong">
        <TachGhepThua
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={onDelete}
          onComplete={onComplete}
          coords={coordsExample}
          onLoad={onLoad}
          onClick={onClick}
        />

        <div id="mapThuaEl">
          <div className="control">
            <div className="search">
              <InputFloatLabel
                type="number"
                value={search.soTo}
                placeholder="Số tờ"
                name="soTo"
                onChange={handleChange}
                required
              />
              <InputFloatLabel
                value={search.soThua}
                placeholder="Số thửa"
                name="soThua"
                onChange={handleChange}
                required
              />
              <SelectDistrict
                value={search.maPx}
                placeholder="Phường/xã"
                name="maPx"
                onChange={handleChangeSelect}
                required
              />
              <div>
                <Button type="submit" icon={<BsSearch height={16} />} onClick={handlSearch} />
              </div>
            </div>
            <div className="selected-list">
              {data.map((m) => (
                <ListItem key={m.id} data={m} onClose={onCloseListItem} onClick={onClickItem} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapHienTrang;

interface ListItemProps {
  data: HienTrangSDD;
  onClose: (data: HienTrangSDD) => void;
  onClick: (data: HienTrangSDD) => void;
}

const ListItem: FC<ListItemProps> = ({ data, onClose, onClick }) => {
  return (
    <div className="selected-list-item" onClick={() => onClick(data)}>
      <div>
        Tờ <strong>{data.SoTo}</strong> - Thửa <strong>{data.SoThua}</strong>
      </div>
      <div className="del-action" onClick={() => onClose(data)}>
        <IoMdCloseCircleOutline size={22} color="#ff0000" />
      </div>
    </div>
  );
};
