import { FC, useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../Shared/Button/Button";
import * as Excel from "exceljs";
import { dmMucDichSdd, dmPhuongXa } from "../../../common/domain";
import { Feature, View } from "ol";
import { Polygon, MultiPolygon } from "ol/geom";
import { HienTrangSDD } from "../../../common/definitions";
import { Coordinate } from "ol/coordinate";
import "./styles.css";
import * as olProj from "ol/proj";
import { EPSG3857, LayerName, PRJ_VN2000, WORKSPACES, ZOOM } from "../../../common/constants";
import { Map } from "ol";
import { defaults } from "ol/control";
import TileLayer from "ol/layer/Tile";
import { OSM, TileWMS } from "ol/source";
import { Vector } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import * as turf from "@turf/turf";
import proj4 from "proj4";
import classNames from "classnames";
import useWriteTransaction from "../../../services/transaction.service";
import { MultiPolygon as MultiPolygonGeo } from "geojson";
import { varConfig } from "../../../common/var-config";

interface ImportHienTrangProps {}

type DataExcel = Array<HienTrangSDD>;
type Keys = Array<keyof HienTrangSDD>;

const ImportHienTrang: FC<ImportHienTrangProps> = () => {
  const [data, setData] = useState<DataExcel>([]);
  const [map, setMap] = useState<Map>();
  const [selected, setSelected] = useState<number>();
  const [disabled, setDisabled] = useState(false);
  const { addFeatures } = useWriteTransaction<HienTrangSDD, MultiPolygonGeo>(LayerName.HIEN_TRANG);
  const [dataError, setDataError] = useState<{ errors: Array<{ [key: string]: boolean }>; isError: boolean }>({
    errors: [],
    isError: false,
  });
  const [dataKey, setDataKey] = useState<Keys>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [vectorLayer] = useState(
    new Vector({
      source: new VectorSource<MultiPolygon>(),
    }),
  );

  const featureRef = useRef<Feature<MultiPolygon>>();

  const createFeatures = useCallback(
    (data: Array<HienTrangSDD>, prj?: { fromProjection: string; toProjection: string }) => {
      const features: Feature<MultiPolygon>[] = [];
      let i = 0;
      for (const dt of data) {
        const { geometry, ...rest } = dt;
        const coordinates: Coordinate[] = [];
        if (geometry) {
          const coords = geometry.split(",");
          for (const coord of coords) {
            let [x, y] = coord
              .trim()
              .split(" ")
              .map((m) => +m);
            if (prj) {
              [x, y] = proj4(prj.fromProjection, prj.toProjection, [Number(x), Number(y)]);
            }

            coordinates.push([x, y]);
          }
        }
        if (coordinates.length) {
          const id = `${i}.${rest.SoTo}.${rest.SoThua}.${rest.MaPX}`;
          const polygon = new Polygon([coordinates]);
          const multiPolygon = new MultiPolygon([polygon]);
          const feature = new Feature<MultiPolygon>();
          feature.setGeometry(multiPolygon);
          feature.setGeometryName("geometry");
          feature.setProperties(rest);
          feature.setId(id);
          feature.setStyle(
            new Style({
              fill: new Fill({ color: "#FF0000" }),
              stroke: new Stroke({ color: "#fff" }),
            }),
          );
          features.push(feature);
        }
        i += 1;
      }
      vectorLayer.getSource()?.addFeatures(features);
    },
    [vectorLayer],
  );

  const checkData = useCallback((data: DataExcel, keys: Keys) => {
    const errors: Array<{ [key: string]: boolean }> = [];
    const fields: { [key: string]: { required?: boolean; isNumber?: boolean } } = {
      SoTo: { required: true, isNumber: true },
      SoThua: { required: true },
      DienTich: { isNumber: true },
      KyHieuLoaiDat: { required: true },
      MaPX: { required: true },
      geometry: { required: true },
    };
    let exitsError = false;
    for (const dt of data) {
      const error: { [key: string]: boolean } = {};
      for (const key of keys) {
        const value = dt[key];
        error[key] = false;
        const field = fields[key];
        if (field) {
          const { required, isNumber } = field;
          if ((required !== undefined && !value) || (isNumber !== undefined && !Number(value))) {
            error[key] = true;
            if (!exitsError) {
              exitsError = true;
            }
          }
        }
      }
      errors.push(error);
    }
    return { errors, isError: exitsError };
  }, []);

  const onImport = useCallback(async () => {
    const files = inputFileRef?.current?.files;
    if (!files || !files.length) {
      alert("Vui lòng chọn file!");
      return;
    }
    const wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(files[0]);
    reader.onload = () => {
      const buffer = reader.result;
      if (buffer instanceof ArrayBuffer) {
        wb.xlsx.load(buffer).then((workbook) => {
          let jsonData: Array<HienTrangSDD> = [];
          const sheet = workbook.getWorksheet(1);
          const firstRow = sheet.getRow(1);
          if (!firstRow.cellCount) return;
          // const keys = firstRow.values as Array<keyof HienTrangSDD>;
          const keys = [
            "SoTo",
            "SoThua",
            "DienTich",
            "KyHieuLoaiDat",
            "SoNha",
            "TenDuong",
            "MaPX",
            "GhiChu",
            "geometry",
          ] as Keys;
          if (Array.isArray(keys)) {
            sheet.eachRow((row, rowNumber) => {
              if (rowNumber === 1) return;
              let values = row.values as Array<string>;
              let obj: any = {};
              for (let i = 0; i < keys.length; i++) {
                const indexValue = i + 1;
                let key = keys[i];
                if (Array.isArray(values)) {
                  obj[key] = values[indexValue] || "";
                }
              }
              jsonData.push(obj);
            });
          }
          if (!jsonData.length) {
            alert("Không có dữ liệu để tải lên!");
            return;
          }
          setData(jsonData);
          setDataKey(keys);
          setDataError(checkData(jsonData, keys));
          createFeatures(jsonData, { fromProjection: PRJ_VN2000, toProjection: EPSG3857 });
        });
      }
    };
  }, [checkData, createFeatures]);

  const onSaveFeatures = useCallback(() => {
    if (dataError.isError) {
      alert("Dữ liệu không hợp lệ. Vui lòng kiểm tra lại tệp tin excel!");
      return;
    }
    const features: Array<Feature> = [];
    for (const dt of data) {
      const { geometry, ...rest } = dt;
      const coordinates: Coordinate[] = [];
      if (geometry) {
        const coords = geometry.split(",");
        for (const coord of coords) {
          const [x, y] = coord
            .trim()
            .split(" ")
            .map((m) => +m);
          coordinates.push([x, y]);
        }
      }
      if (coordinates.length) {
        const polygon = new Polygon([coordinates]);
        const multiPolygon = new MultiPolygon([polygon]);
        const feature = new Feature<MultiPolygon>();
        feature.setGeometry(multiPolygon);
        feature.setGeometryName("geometry");
        feature.setProperties(rest);
        features.push(feature);
      }
    }
    if (features) {
      addFeatures(features).then(() => {
        alert("Nhập dữ liệu thành công!");
        setDisabled(true);
      });
    }
  }, [addFeatures, data, dataError.isError]);

  const initialMap = useCallback(() => {
    const view = new View({
      center: olProj.fromLonLat([106.53137277230698, 11.0184854564124]),
      zoom: ZOOM,
      resolution: 55,
      minResolution: 0.06,
      // maxResolution: 55,
    });
    const mapView = new Map({
      target: "mapPreview",
      view,
      controls: defaults({ zoom: false, attribution: false }),
      layers: [
        new TileLayer({ source: new OSM() }),
        new TileLayer({
          source: new TileWMS({
            url: `${varConfig.geoServerUrl}/cuchi/wms`,
            params: {
              FORMAT: "image/png",
              VERSION: "1.1.1",
              tiled: true,
              STYLES: "ht_sudungdat_print",
              LAYERS: `${WORKSPACES}:${LayerName.HIEN_TRANG}`,
              exceptions: "application/vnd.ogc.se_inimage",
            },
            crossOrigin: "anonymous",
          }),
        }),
        new TileLayer({
          source: new TileWMS({
            url: `${varConfig.geoServerUrl}/cuchi/wms`,
            params: {
              FORMAT: "image/png",
              VERSION: "1.1.1",
              tiled: true,
              STYLES: "",
              LAYERS: `${WORKSPACES}:${LayerName.RANHGIOI_PHUONGXA}`,
              exceptions: "application/vnd.ogc.se_inimage",
            },
            crossOrigin: "anonymous",
          }),
        }),
        vectorLayer,
      ],
    });
    mapView.setView(view);
    setMap(mapView);
  }, [vectorLayer]);

  useEffect(() => {
    initialMap();
  }, [initialMap]);

  const onSelectRow = useCallback(
    (data: HienTrangSDD, index: number) => {
      const id = `${index}.${data.SoTo}.${data.SoThua}.${data.MaPX}`;
      const feature = vectorLayer.getSource()?.getFeatureById(id);
      if (featureRef.current) {
        vectorLayer.getSource()?.removeFeature(featureRef.current);
      }
      if (feature) {
        const featureClone = feature.clone();
        featureClone.setStyle(
          new Style({
            stroke: new Stroke({ color: "#31E1F7", width: 4 }),
            fill: new Fill({ color: "transparent" }),
          }),
        );

        featureRef.current = featureClone;
        vectorLayer.getSource()?.addFeature(featureClone);
        const coordinates = feature.getGeometry()?.getCoordinates();
        if (coordinates) {
          const multiPolygon = turf.multiPolygon(coordinates);
          const center = turf.centroid(multiPolygon);
          map?.getView().setResolution(0.2);
          map?.getView().setCenter(center.geometry.coordinates);
        }
      }
      setSelected(index);
    },
    [map, vectorLayer],
  );

  return (
    <div style={{ padding: "22px 16px" }}>
      <div>
        <span>Tải mẫu excel </span>{" "}
        <a href="/template.xlsm" download>
          tại đây
        </a>
      </div>
      <div>
        <input type="file" ref={inputFileRef} />
        <Button className="outline" onClick={onImport}>
          Tải lên
        </Button>
      </div>
      <div style={{ paddingTop: "16px", color: "#ff4a4a" }}>
        {dataError.isError && "Dữ liệu bị thiếu hoặc sai định dạng. Vui lòng kiểm tra lại dữ liệu tệp tin excel!"}
      </div>
      <div
        className="view-content"
        style={{
          width: "100%",
          height: 560,
          display: "flex",
          visibility: !!data.length ? "visible" : "hidden",
          marginTop: 8,
        }}
      >
        <div style={{ width: "60%", height: "100%", overflow: "auto", position: "relative" }}>
          <div
            style={{
              width: "100%",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">Số tờ</th>
                  <th scope="col">Số thửa</th>
                  <th scope="col">Diện tích</th>
                  <th scope="col">Mục đích sử dụng đất</th>
                  <th scope="col">Số nhà</th>
                  <th scope="col">Tên đường</th>
                  <th scope="col">Phường xã</th>
                  <th scope="col">Ghi chú</th>
                  <th scope="col" style={{ width: 100 }}>
                    Geometry
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((dt, index) => (
                  <tr
                    key={index}
                    onClick={() => onSelectRow(dt, index)}
                    className={classNames({ "row-actived": selected === index })}
                  >
                    <th scope="row">{index + 1}</th>
                    {dataKey.map((key, iKey) => (
                      <td
                        key={key + iKey}
                        style={{ boxShadow: dataError.errors[index][key] ? "0px 0px 4px #FF4A4A inset" : "none" }}
                      >
                        {key === "MaPX"
                          ? dmPhuongXa[dt[key]]
                          : key === "KyHieuLoaiDat"
                          ? dmMucDichSdd[dt[key]]
                          : key === "geometry"
                          ? dt[key]?.slice(0, 50) + "..."
                          : dt[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              width: "100%",
              height: "20%",
            }}
          >
            <Button onClick={onSaveFeatures} style={{ float: "right", marginTop: 8 }} disabled={disabled}>
              Lưu
            </Button>
          </div>
        </div>
        <div style={{ width: "40%", height: "100%" }}>
          <div id="mapPreview" style={{ width: "100%", height: "100%" }}></div>
        </div>
      </div>
    </div>
  );
};

export default ImportHienTrang;
