import Feature from "ol/Feature";
import { Coordinate } from "ol/coordinate";
import { MultiPolygon } from "ol/geom";
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { FcCancel, FcDeleteRow } from "react-icons/fc";
import { PRJ_VN2000 } from "../../common/constants";
import { HienTrangSDD } from "../../common/definitions";
import Button from "../../Shared/Button/Button";
import InputFloatLabel from "../../Shared/input/InputFloatLabel";
import SelectDistrict from "../../Shared/select/SelectDistrict";
import "./tach-ghep-thua.css";
import Style from "ol/style/Style";
import { Stroke } from "ol/style";
import proj4 from "proj4";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import * as Excel from "exceljs";
import SelectFloatLabel, { Option } from "../../Shared/select/SelectFloatLabel";
import { DomainHienTrang } from "../../common/domain";
import classNames from "classnames";
import { BsUpload } from "react-icons/bs";
import { IoIosAdd } from "react-icons/io";
import { MdOutlineUpdate } from "react-icons/md";

interface TachGhepThuaProps {
  onAdd: (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>) => void;
  onEdit: (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>) => void;
  onDelete: (hienTrang: HienTrangSDD) => void;
  onComplete: (data: Array<DataTachGhep>) => Promise<boolean>;
  coords?: Array<{ x: string; y: string }>;
  onLoad: (hienTrangs: Array<DataTachGhep>) => void;
  onClick: (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>) => void;
}

export type DataTachGhep = { hienTrang: HienTrangSDD; coordinates: Array<{ x: string; y: string }> };
const initHienTrang = {
  SoTo: 0,
  SoThua: "",
  SoNha: "",
  TenDuong: "",
  DienTich: 0,
  GhiChu: "",
  KyHieuLoaiDat: "",
  MaPX: "",
};

const initCoords = [
  { x: "", y: "" },
  { x: "", y: "" },
  { x: "", y: "" },
];

const TachGhepThua: FC<TachGhepThuaProps> = ({ onAdd, onEdit, onDelete, onComplete, coords, onLoad, onClick }) => {
  const [hienTrangSdd, setHienTrangSdd] = useState<HienTrangSDD>(initHienTrang);
  const [data, setData] = useState<Array<{ hienTrang: HienTrangSDD; coordinates: Array<{ x: string; y: string }> }>>(
    [],
  );
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState<number>();
  const [coordinates, setCoordinates] = useState<Array<{ x: string; y: string }>>(initCoords);

  // useEffect(() => {
  //   if (coords) {
  //     setCoordinates(coords);
  //   } else {
  //     setCoordinates([
  //       { x: "614823.740700078", y: "1213570.2917996482" },
  //       { x: "614824.800200488", y: "1213544.4405999172" },
  //       { x: "614834.7727999578", y: "1213544.7434998057" },
  //       { x: "614844.8011995329", y: "1213545.103399982" },
  //       { x: "614843.6117002248", y: "1213571.0445003523" },
  //       { x: "614833.7312995762", y: "1213570.693200246" },
  //       { x: "614833.6127004087", y: "1213570.688400061" },
  //       { x: "614823.740700078", y: "1213570.2917996482" },
  //     ]);
  //   }
  // }, [coords]);

  const refreshHienTrang = useCallback(() => {
    setHienTrangSdd(initHienTrang);
    setCoordinates(initCoords);
  }, []);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setHienTrangSdd({ ...hienTrangSdd, [name]: value });
  };

  const handleChangeSelect = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = evt.target;
      setHienTrangSdd({ ...hienTrangSdd, [name]: value });
    },
    [hienTrangSdd],
  );

  const addFeatureHienTrang = useCallback(
    (evt: any) => {
      const { SoTo, SoThua, MaPX, KyHieuLoaiDat } = hienTrangSdd;
      if (!SoTo || !SoThua || !MaPX || !KyHieuLoaiDat) {
        alert("Vui lòng nhập đầy đủ thông tin trường có dấu (*)");
        return;
      }
      const coordsReal = coordinates.filter(({ x, y }) => x && y);
      if (coordsReal.length < 3) {
        alert("Vui lòng nhập ít nhất 3 tọa độ!");
        return;
      }
      const projectedCoordsESPG: Coordinate[] = [];
      const projectedCoordsVN2000: Coordinate[] = [];
      let invalid = true;
      for (let i = 0; i < coordinates.length; i++) {
        const { x, y } = coordinates[i];
        if (x && y) {
          if (isNaN(Number(x)) || isNaN(Number(y))) {
            invalid = false;
            break;
          }
          const coordsPrj = proj4(PRJ_VN2000, "EPSG:3857", [Number(x), Number(y)]);
          projectedCoordsVN2000.push([Number(x), Number(y)]);
          projectedCoordsESPG.push(coordsPrj);
        }
      }

      if (!invalid) {
        alert("Tọa độ không hợp lệ!");
        return;
      }
      // kiểm tra tọa độ cuối so với tọa độ đầu
      const [firstCoordinate] = projectedCoordsESPG;
      const lastCoordinate = projectedCoordsESPG[projectedCoordsESPG.length - 1];
      if (firstCoordinate[0] !== lastCoordinate[0] || firstCoordinate[1] !== lastCoordinate[1]) {
        projectedCoordsESPG.push(firstCoordinate);
      }
      const geometry = new MultiPolygon([[projectedCoordsESPG]]);
      const feature = new Feature<MultiPolygon>();
      feature.setGeometry(geometry);

      feature.setProperties(hienTrangSdd);
      feature.setProperties({ created_at: new Date().toISOString() });
      feature.setStyle(
        () =>
          new Style({
            // fill: new Fill({ color: "yellow" }),
            stroke: new Stroke({ color: "#FF0000", width: 2 }),
          }),
      );
      const ht = [...data];
      const hienTrangFull: HienTrangSDD = { ...hienTrangSdd, id: Date.now() };
      if (!hienTrangFull.DienTich) {
        const area = feature.getGeometry()?.getArea();
        hienTrangFull.DienTich = area ? +parseFloat(area + "").toFixed(3) : 0;
      }
      ht.push({ hienTrang: hienTrangFull, coordinates });
      setData(ht);
      const featureVN2000 = feature.clone();
      featureVN2000.setGeometry(new MultiPolygon([[projectedCoordsVN2000]]));
      onAdd(hienTrangFull, coordinates);
      refreshHienTrang();
    },
    [coordinates, data, hienTrangSdd, onAdd, refreshHienTrang],
  );

  const onAddCoordinates = useCallback(() => {
    const coords = [...coordinates];
    coords.push({ x: "", y: "" });
    setCoordinates(coords);
  }, [coordinates]);

  const onRemoveCoordinates = useCallback(
    (index: number) => {
      const coords = coordinates.filter((_, i) => i !== index);
      setCoordinates(coords);
    },
    [coordinates],
  );

  const onChangeCoordinateInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, index: number) => {
      const { value, name } = evt.target;
      const coords = [...coordinates];
      if (name === "x" || name === "y") coords[index][name] = value;
      setCoordinates(coords);
    },
    [coordinates],
  );

  const handleDelete = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, hienTrang: HienTrangSDD) => {
      evt.stopPropagation();
      const hienTrangs = data.filter((f) => f.hienTrang.id && f.hienTrang.id !== hienTrang.id);
      setData(hienTrangs);
      setSelected(undefined);
      onDelete(hienTrang);
    },
    [data, onDelete],
  );
  const handleEdit = useCallback((dataTachGhep: DataTachGhep) => {
    setHienTrangSdd(dataTachGhep.hienTrang);
    setCoordinates(dataTachGhep.coordinates);
    setIsEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setIsEdit(false);
  }, []);

  const handleUpdateEdit = useCallback(() => {
    const index = data.findIndex((f) => f.hienTrang.id && f.hienTrang.id === hienTrangSdd.id);
    const dt = [...data];
    dt[index] = { hienTrang: hienTrangSdd, coordinates };
    setData(dt);
    onEdit(hienTrangSdd, coordinates);
    setIsEdit(false);
    refreshHienTrang();
  }, [coordinates, data, hienTrangSdd, onEdit, refreshHienTrang]);

  const handleTachGhepThua = useCallback(() => {
    onComplete(data).then((res) => {
      if (res) {
        setData([]);
        refreshHienTrang();
      }
    });
  }, [data, onComplete, refreshHienTrang]);

  const onChangeInputFile = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      const files = evt.target.files;
      if (!files || !files.length) {
        alert("Vui lòng chọn file!");
        return;
      }
      const wb = new Excel.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(files[0]);
      reader.onload = () => {
        const buffer = reader.result;
        if (buffer instanceof ArrayBuffer) {
          wb.xlsx.load(buffer).then((workbook) => {
            let jsonData: Array<DataTachGhep> = [];
            const sheet = workbook.getWorksheet(1);
            const firstRow = sheet.getRow(1);
            if (!firstRow.cellCount) return;
            // const keys = firstRow.values as Array<keyof HienTrangSDD>;
            const keys = [
              "SoTo",
              "SoThua",
              "DienTich",
              "KyHieuLoaiDat",
              "SoNha",
              "TenDuong",
              "MaPX",
              "GhiChu",
              "geometry",
            ];
            if (Array.isArray(keys)) {
              sheet.eachRow((row, rowNumber) => {
                if (rowNumber === 1) return;
                let values = row.values as Array<string>;
                let obj: { [key: string]: string } = {
                  id: `${Date.now()}${rowNumber}`,
                };
                for (let i = 0; i < keys.length; i++) {
                  const indexValue = i + 1;
                  let key = keys[i];
                  if (Array.isArray(values)) {
                    obj[key] = values[indexValue] || "";
                  }
                }
                const { geometry, ...rest } = obj;
                const geom = geometry.split(",").map((m) => {
                  const [x, y] = m.trim().split(" ");
                  return { x, y };
                });
                jsonData.push({
                  hienTrang: rest as any,
                  coordinates: geom,
                });
              });
            }
            if (!jsonData.length) {
              alert("Không có dữ liệu để tải lên!");
              return;
            }
            console.log(jsonData);
            onLoad(jsonData);
            setData(jsonData);
          });
        }
      };
    },
    [onLoad],
  );

  const onClickRow = useCallback(
    (hienTrang: HienTrangSDD, coordinates: Array<{ x: string; y: string }>, index: number) => {
      setSelected(index);
      onClick(hienTrang, coordinates);
    },
    [onClick],
  );

  return (
    <div className="form-biendong form">
      <div style={{ height: "60%" }}>
        <div className="property">
          <div style={{ textAlign: "right" }}>
            <span style={{ fontSize: "0.87rem", color: "#858585", marginRight: 6, fontStyle: "italic" }}>
              Nhập từ tệp tin excel. Vui lòng tải tệp tin mẫu{" "}
              <a href="/template.xlsm" download>
                tại đây
              </a>
            </span>

            <input type="file" id="upload" accept=".xl*" hidden onChange={onChangeInputFile} />
            <label
              htmlFor="upload"
              style={{
                display: "inline-block",
                color: "indigo",
                padding: "0.3rem",
                fontFamily: "sans-serif",
                borderRadius: "0.3rem",
                border: "1px solid indigo",
                cursor: "pointer",
                fontSize: 13,
              }}
            >
              <BsUpload /> Chọn tệp tin
            </label>
          </div>

          <div className="group">
            <div className="input">
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.SoTo}
                placeholder="Số tờ"
                name="SoTo"
                onChange={handleChange}
                required
              />
            </div>
            <div className="input">
              <InputFloatLabel
                value={hienTrangSdd.SoThua}
                placeholder="Số thửa"
                name="SoThua"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* group */}
          <div className="group">
            <div className="input">
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.DienTich}
                placeholder="Diện tích"
                name="DienTich"
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <SelectFloatLabel
                value={hienTrangSdd.KyHieuLoaiDat}
                placeholder="Mục đích sử dụng đất"
                onChange={handleChangeSelect}
                name="KyHieuLoaiDat"
                required
              >
                <Option value={""}>Mục đích sử dụng đất</Option>
                {DomainHienTrang.map((dm) => (
                  <Option key={dm.code} value={dm.code}>
                    {dm.name}
                  </Option>
                ))}
              </SelectFloatLabel>
            </div>
          </div>
          {/* group */}
          <div className="group">
            <div className="input">
              <SelectDistrict
                value={hienTrangSdd.MaPX}
                placeholder="Phường/xã"
                name="MaPX"
                onChange={handleChangeSelect}
                required
              />
            </div>
            <div className="input">
              <InputFloatLabel
                value={hienTrangSdd.GhiChu}
                placeholder="Ghi chú"
                name="GhiChu"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="geometry">
          <h4>Tọa độ</h4>
          {coordinates.map(({ x, y }, index) => (
            <div className="group" key={"coord" + index}>
              <div className="order-ht">{index + 1}</div>
              <div className="inputGroup">
                <InputFloatLabel
                  value={x}
                  placeholder="Tọa độ x"
                  name="x"
                  onChange={(evt) => onChangeCoordinateInput(evt, index)}
                />
              </div>
              <div className="inputGroup">
                <InputFloatLabel
                  value={y}
                  placeholder="Tọa độ y"
                  name="y"
                  onChange={(evt) => onChangeCoordinateInput(evt, index)}
                />
              </div>
              <button
                type="button"
                className="btn-remove-ht"
                onClick={() => onRemoveCoordinates(index)}
                style={{ visibility: coordinates.length > 1 ? "visible" : "hidden" }}
              >
                <FcDeleteRow size={24} />
              </button>
            </div>
          ))}
          <div className="form-actions">
            <Button type="button" className="outline" onClick={onAddCoordinates}>
              Thêm tọa độ
            </Button>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "end", columnGap: 4, padding: 4 }}>
        {isEdit ? (
          <>
            <Button onClick={handleCancel} className="btn-danger outline" icon={<FcCancel />}>
              Hủy
            </Button>
            <Button onClick={handleUpdateEdit} icon={<MdOutlineUpdate />}>
              Cập nhật
            </Button>
          </>
        ) : (
          <Button onClick={addFeatureHienTrang} icon={<IoIosAdd />}>
            Thêm
          </Button>
        )}
      </div>

      <h5>Danh sách kết quả thửa ghép/tách</h5>
      <div style={{ maxHeight: "24%", overflowY: "auto" }}>
        <table>
          <thead>
            <tr>
              <th>Số tờ</th>
              <th>Số thửa</th>
              <th>
                Diện tích (m<sup>2</sup>)
              </th>
              <th>Mục đích SDĐ</th>
              <th>Phường xã</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={6} style={{ fontStyle: "italic", color: "#b0b0b0", textAlign: "center" }}>
                  Danh sách kết quả thửa tách/ghép đã được xử lý
                </td>
              </tr>
            )}
            {data.map((m, index) => (
              <tr
                key={m.hienTrang.id}
                onClick={() => onClickRow(m.hienTrang, m.coordinates, index)}
                className={classNames({ actived: index === selected })}
              >
                <td width={60}>{m.hienTrang.SoTo}</td>
                <td width={80}>{m.hienTrang.SoThua}</td>
                <td width={90}>{m.hienTrang.DienTich}</td>
                <td>{m.hienTrang.KyHieuLoaiDat}</td>
                <td width={90}>{m.hienTrang.MaPX}</td>
                <td width={80}>
                  <div style={{ display: "flex", columnGap: 4 }}>
                    <Button onClick={() => handleEdit(m)} icon={<AiOutlineEdit />} style={{ padding: 4 }}></Button>
                    <Button
                      onClick={(evt) => handleDelete(evt, m.hienTrang)}
                      icon={<AiOutlineDelete />}
                      // style={{ padding: 4, backgroundColor: "#F90716", borderColor: "#F90716", color: "#fff" }}
                      disabled={isEdit}
                      className="btn-danger"
                    ></Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="main-action">
        <Button className="btn-complete" onClick={handleTachGhepThua}>
          Hoàn tất
        </Button>
      </div>
    </div>
  );
};

export default TachGhepThua;
