import { FC } from "react";
import { TileLayer } from "../Layers";
import tileWMS from "../Source/tile-wms";

interface HienTrangSuDungDatProps {}

const featureType = "ht_sudungdat";

const HienTrangSuDungDat: FC<HienTrangSuDungDatProps> = () => {
  const source = tileWMS(featureType);
  return <TileLayer source={source} zIndex={1} />;
};

export default HienTrangSuDungDat;
