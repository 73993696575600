import Draw from "ol/interaction/Draw";
import Map from "ol/Map";
import Overlay from "ol/Overlay";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { LineString, Polygon } from "ol/geom";
import { Vector as VectorSource } from "ol/source";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Feature } from "ol";
import "./measure.css";
import MapBrowserEvent from "ol/MapBrowserEvent";
import VectorLayer from "ol/layer/Vector";
import { FaAngleDoubleRight, FaDrawPolygon, FaRulerHorizontal } from "react-icons/fa";
import Tooltip from "../../Shared/Tooltip";
import classNames from "classnames";
import { FcRuler } from "react-icons/fc";
import { formatNumber } from "../../common/utils";

enum TypeSelect {
  length = "length",
  area = "area",
}

type Props = {
  map: Map;
  onStartMeasure: () => void;
  onEndMeasure: () => void;
};

function Measure({ map, onEndMeasure, onStartMeasure }: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [typeSelect, setTypeselect] = useState("Polygon");
  const [isDrawing, setIsDrawing] = useState(false);
  const sketch = useRef<Feature | null>(null);
  const [typeSelected, setTypeselected] = useState("length");

  // let helpTooltipElement: HTMLElement;
  const overlays = useRef<Array<Overlay>>([]);
  // let measureTooltipElement: HTMLElement;
  const source = useRef(new VectorSource());
  const [vector] = useState(
    new VectorLayer({
      displayInLayerSwitcher: false,
      source: source.current,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "#FFF600",
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: "#FFF600",
          }),
        }),
      }),
      zIndex: 10,
    } as any),
  );

  const tooltip = useRef<{
    helpTooltipElement?: HTMLElement;
    measureTooltipElement?: HTMLElement;
    helpTooltip?: Overlay;
    measureTootip?: Overlay;
  }>({
    helpTooltipElement: document.createElement("div"),
  });

  const [helpTooltip] = useState(
    new Overlay({
      // element: Tooltip.current.helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left",
    }),
  );
  const draw = useRef<Draw>();

  // let helpTooltip: Overlay;

  const measureTooltip = useRef<Overlay>();
  const listener = useRef<any>();

  /**
   * Message to show when the user is drawing a polygon.
   * @type {string}
   */
  const continuePolygonMsg = "Click để tiếp tục vẽ vùng";

  /**
   * Message to show when the user is drawing a line.
   * @type {string}
   */
  const continueLineMsg = "Click để tiếp tục vẽ đường";

  /**
   * Handle pointer move.
   */
  const pointerMoveHandler = (evt: MapBrowserEvent<UIEvent>) => {
    if (evt.dragging) {
      return;
    }
    /** @type {string} */
    let helpMsg = "Click để bắt đầu vẽ";

    if (sketch.current) {
      const geom = sketch.current.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = continueLineMsg;
      }
    }
    helpTooltip.setPosition(evt.coordinate);
    if (tooltip.current.helpTooltipElement) {
      tooltip.current.helpTooltipElement.innerHTML = helpMsg;
      tooltip.current.helpTooltipElement.classList.remove("hidden");
    }
  };

  // const handlePointerMover = useRef<EventsKey>();

  // const draw = useRef<undefined | Draw>(); // global so we can remove it later

  /**
   * Format length output.
   */
  const formatLength = (line: any) => {
    const length = getLength(line);
    let output;
    if (length > 100) {
      output = `${formatNumber(length / 1000)} km`;
    } else {
      output = `${formatNumber(length)} m`;
    }
    return output;
  };

  /**
   * Format area output.
   */
  const formatArea = (polygon: Polygon) => {
    const area = getArea(polygon);
    let output;
    if (area > 10000) {
      output = `${formatNumber(area / 1000000)} km<sup>2</sup>`;
    } else {
      output = `${formatNumber(area)} m<sup>2</sup>`;
    }
    return output;
  };

  /**
   * Creates a new help tooltip
   */
  function createHelpTooltip() {
    if (tooltip.current.helpTooltipElement) {
      tooltip.current.helpTooltipElement.parentNode?.removeChild(tooltip.current.helpTooltipElement);
    }

    tooltip.current.helpTooltipElement = document.createElement("div");
    tooltip.current.helpTooltipElement.className = "ol-tooltip hidden";
    // helpTooltip = new Overlay({
    //   element: Tooltip.current.helpTooltipElement,
    //   offset: [15, 0],
    //   positioning: "center-left",
    // });
    helpTooltip.setElement(tooltip.current.helpTooltipElement);
    map.addOverlay(helpTooltip);
  }

  /**
   * Creates a new measure tooltip
   */
  function createMeasureTooltip() {
    if (tooltip.current) {
      if (tooltip.current.measureTooltipElement)
        tooltip.current.measureTooltipElement.parentNode?.removeChild(tooltip.current.measureTooltipElement);

      tooltip.current.measureTooltipElement = document.createElement("div");
      tooltip.current.measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
      measureTooltip.current = new Overlay({
        element: tooltip.current.measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center",
        stopEvent: false,
        insertFirst: false,
      });
      overlays.current.push(measureTooltip.current);
      map.addOverlay(measureTooltip.current);
    }
  }

  const addInteraction = (typeSelect: string = typeSelected) => {
    const type = typeSelect === "area" ? "Polygon" : "LineString";
    draw.current = new Draw({
      source: source.current,
      type,
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 0, 0, 0.5)",
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: "rgba(0, 0, 0, 0.7)",
          }),
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
        }),
      }),
    });
    map.addInteraction(draw.current);

    createMeasureTooltip();
    createHelpTooltip();

    draw.current.on("drawstart", (evt: any) => {
      // set sketch
      sketch.current = evt.feature;
      let tooltipCoord = evt.coordinate;

      listener.current = sketch.current?.getGeometry()?.on("change", (e: any) => {
        const geom = e.target;
        let output = "";
        if (geom instanceof Polygon) {
          output = formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        } else if (geom instanceof LineString) {
          output = formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        if (tooltip.current && tooltip.current.measureTooltipElement) {
          tooltip.current.measureTooltipElement.innerHTML = output;
        }

        measureTooltip.current?.setPosition(tooltipCoord);
      });
    });

    draw.current.on("drawend", () => {
      if (tooltip.current.measureTooltipElement)
        tooltip.current.measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
      measureTooltip.current?.setOffset([0, -7]);
      // unset sketch
      sketch.current = null;
      // unset tooltip so that a new one can be created
      tooltip.current.measureTooltipElement = undefined;
      createMeasureTooltip();
      unByKey(listener.current);
    });
  };

  /**
   * Let user change the geometry type.
   */
  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setTypeselected(value);
    // if (handlePointerMover.current)
    //   handlePointerMover.current.target.removeEventListener(
    //     "pointermove",
    //     pointerMoveHandler,
    //   );
    map.on("pointermove", pointerMoveHandler);

    map.getViewport().addEventListener("mouseout", () => {
      tooltip.current?.helpTooltipElement?.classList.add("hidden");
    });
    draw.current && map.removeInteraction(draw.current);
    addInteraction(value);
  };

  useEffect(() => {
    // setIsDrawing(isOpen);
    if (!isDrawing) {
      source.current.clear();
      map.getViewport().addEventListener("mouseout", () => {
        tooltip.current?.helpTooltipElement?.classList.add("hidden");
      });
      draw.current && map.removeInteraction(draw.current);
    }
  }, [isDrawing, map]);

  useEffect(() => {
    if (isDrawing) {
      addInteraction();
      map.on("pointermove", pointerMoveHandler);
    } else {
      map.getViewport().addEventListener("mouseout", () => {
        tooltip.current?.helpTooltipElement?.classList.add("hidden");
      });
      draw.current && map.removeInteraction(draw.current);
      for (const overlay of overlays.current) {
        map.removeOverlay(overlay);
      }
      map.removeOverlay(helpTooltip);
    }
  }, [isDrawing, map]);

  useEffect(() => {
    map.addLayer(vector);
    return () => {
      map.removeLayer(vector);
    };
  }, [map, vector]);

  const onMeasure = useCallback(() => {
    setIsDrawing((prev) => {
      if (prev) {
        onEndMeasure();
      } else {
        onStartMeasure();
      }
      return !prev;
    });
  }, [onEndMeasure, onStartMeasure]);

  return (
    <div className={classNames("measure", { drawing: isDrawing })}>
      <Tooltip text={isDrawing ? "Hủy đo lường" : "Đo lường"} direction="left">
        <button type="button" className={classNames("btn-control")} onClick={onMeasure}>
          {isDrawing ? <FaAngleDoubleRight size={12} /> : <FcRuler />}
        </button>
      </Tooltip>
      <form className="form-inline">
        <Tooltip text="Đo khoảng cách" direction="bottom">
          <label htmlFor="html" className={classNames({ active: typeSelected === TypeSelect.length })}>
            <input
              type="radio"
              id="html"
              name="measure"
              onChange={onChange}
              value={TypeSelect.length}
              checked={typeSelected === TypeSelect.length}
            />
            <FaRulerHorizontal color={typeSelected === TypeSelect.length ? "white" : "#000"} />
          </label>
        </Tooltip>
        <Tooltip text="Đo diện tích" direction="bottom">
          <label
            htmlFor="css"
            aria-controls="bottom-left"
            className={classNames({ active: typeSelected === TypeSelect.area })}
          >
            <input
              type="radio"
              id="css"
              name="measure"
              value={TypeSelect.area}
              onChange={onChange}
              checked={typeSelected === TypeSelect.area}
            />
            <FaDrawPolygon color={typeSelected === TypeSelect.area ? "white" : "#000"} />
          </label>
        </Tooltip>
        <br />
      </form>
    </div>
  );
}

export default Measure;
