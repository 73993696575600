import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { MultiPolygon } from "ol/geom";
import { ChangeEvent, ChangeEventHandler, FC, FormEvent, useCallback, useState } from "react";
import { FcAddRow, FcDeleteRow } from "react-icons/fc";
import { LayerName } from "../../../common/constants";
import { HienTrangSDD } from "../../../common/definitions";
import { DomainHienTrang } from "../../../common/domain";
import useWriteTransaction from "../../../services/transaction.service";
import Button from "../../../Shared/Button/Button";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import SelectDistrict from "../../../Shared/select/SelectDistrict";
import SelectDomainSdd from "../../../Shared/select/SelectDomainSdd";
import SelectFloatLabel, { Option } from "../../../Shared/select/SelectFloatLabel";
import "./styles.css";

const placeholderCoord = ["Tọa độ x", "tọa độ y"];
type Coordinates = { x: string; y: string };

interface AddHienTrangProps {}

const AddHienTrang: FC<AddHienTrangProps> = () => {
  const [hienTrangSdd, setHienTrangSdd] = useState<Partial<HienTrangSDD>>({});
  const [coordinates, setCoordinates] = useState<Array<{ x: string; y: string }>>([
    { x: "615030.4894000003", y: "1214140.1959000006" },
    { x: "615004.3896000003", y: "1214121.7210000008" },
    { x: "615002.2622999996", y: "1214120.2152999993" },
    { x: "614977.6704000002", y: "1214163.6919999998" },
    { x: "614979.6478000004", y: "1214164.6807000004" },
    { x: "614984.4472000003", y: "1214167.0807000007" },
    { x: "615008.9157999996", y: "1214179.3184999991" },
    { x: "615013.8598999996", y: "1214170.2827000003" },
    { x: "615021.8938999996", y: "1214155.6010999996" },
    { x: "615023.3147999998", y: "1214152.8916999996" },
    { x: "615025.7861000001", y: "1214148.5313000008" },
    { x: "615030.4894000003", y: "1214140.1959000006" },
  ]);
  const transaction = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setHienTrangSdd({ ...hienTrangSdd, [name]: value });
  };

  const handleChangeSelect = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = evt.target;
      setHienTrangSdd({ ...hienTrangSdd, [name]: value });
    },
    [hienTrangSdd],
  );

  const addFeatureHienTrang = useCallback(
    (evt: FormEvent<HTMLFormElement>) => {
      evt.preventDefault();
      if (coordinates.length < 3) {
        alert("Vui lòng nhập ít nhất 3 tọa độ!");
        return;
      }
      const projectedCoords: Coordinate[] = [];
      let invalid = true;
      for (let i = 0; i < coordinates.length; i++) {
        const { x, y } = coordinates[i];
        if (x && y) {
          if (isNaN(Number(x)) || isNaN(Number(y))) {
            invalid = false;
            break;
          }

          projectedCoords.push([+x, +y]);
        }
      }

      if (!invalid) {
        alert("Tọa độ không hợp lệ!");
        return;
      }
      // kiểm tra tọa độ cuối so với tọa độ đầu
      const [firstCoordinate] = projectedCoords;
      const lastCoordinate = projectedCoords[projectedCoords.length - 1];
      if (firstCoordinate[0] !== lastCoordinate[0] || firstCoordinate[1] !== lastCoordinate[1]) {
        projectedCoords.push(firstCoordinate);
      }
      const geometry = new MultiPolygon([[projectedCoords]]);
      const feature = new Feature();
      feature.setGeometry(geometry);

      feature.setProperties(hienTrangSdd);
      feature.setProperties({ created_at: new Date().toISOString() });
      transaction
        .addFeatures([feature])
        .then(() => {
          alert("Thêm mới thành công!");
          setHienTrangSdd({});
        })
        .catch((e) => {
          console.log("Error");
        });
    },
    [coordinates, hienTrangSdd],
  );

  const onAddCoordinates = useCallback(() => {
    const coords = [...coordinates];
    coords.push({ x: "", y: "" });
    setCoordinates(coords);
  }, [coordinates]);

  const onRemoveCoordinates = useCallback(
    (index: number) => {
      const coords = coordinates.filter((_, i) => i !== index);
      setCoordinates(coords);
    },
    [coordinates],
  );

  const onChangeCoordinateInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, index: number) => {
      const { value, name } = evt.target;
      const coords = [...coordinates];
      coords[index][name as keyof Coordinates] = value;
      setCoordinates(coords);
    },
    [coordinates],
  );

  return (
    <>
      <h3 style={{ marginTop: 12, padding: "4px 120px" }}>Thêm mới thửa đất</h3>
      <form className="form-hientrang form" onSubmit={addFeatureHienTrang}>
        <div className="formControl">
          <div className="property">
            <h4>Thuộc tính</h4>
            <div className="input">
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.SoTo}
                placeholder="Số tờ"
                name="SoTo"
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <InputFloatLabel
                value={hienTrangSdd.SoThua}
                placeholder="Số thửa"
                name="SoThua"
                onChange={handleChange}
              />
            </div>

            <div className="input">
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.DienTich}
                placeholder="Diện tích"
                name="DienTich"
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <InputFloatLabel
                value={hienTrangSdd.TenDuong}
                placeholder="Tên đường"
                name="TenDuong"
                onChange={handleChange}
              />
            </div>

            <div className="input">
              <InputFloatLabel value={hienTrangSdd.SoNha} placeholder="Số nhà" name="SoNha" onChange={handleChange} />
            </div>
            <div className="input">
              <SelectDistrict
                value={hienTrangSdd.MaPX}
                placeholder="Phường/xã"
                name="MaPX"
                onChange={handleChangeSelect}
              />
            </div>

            <div className="input">
              <SelectFloatLabel
                value={hienTrangSdd.KyHieuLoaiDat}
                placeholder="Mục đích sử dụng đất"
                onChange={handleChangeSelect}
                name="KyHieuLoaiDat"
              >
                <Option value={""}>Mục đích sử dụng đất</Option>
                {DomainHienTrang.map((dm) => (
                  <Option value={dm.code}>{dm.name}</Option>
                ))}
              </SelectFloatLabel>
            </div>
          </div>
          <div className="geometry">
            <h4>Tọa độ</h4>
            {coordinates.map(({ x, y }, index) => (
              <div className="group" key={"coord" + index}>
                <div className="order-ht">{index + 1}</div>
                <div className="inputGroup">
                  <InputFloatLabel
                    value={x}
                    placeholder="Tọa độ x"
                    name="x"
                    onChange={(evt) => onChangeCoordinateInput(evt, index)}
                  />
                </div>
                <div className="inputGroup">
                  <InputFloatLabel
                    value={y}
                    placeholder="Tọa độ y"
                    name="y"
                    onChange={(evt) => onChangeCoordinateInput(evt, index)}
                  />
                </div>
                <button
                  type="button"
                  className="btn-remove-ht"
                  onClick={() => onRemoveCoordinates(index)}
                  style={{ visibility: coordinates.length > 1 ? "visible" : "hidden" }}
                >
                  <FcDeleteRow size={24} />
                </button>
              </div>
            ))}
            <div className="form-actions">
              <Button type="button" className="outline" onClick={onAddCoordinates}>
                Thêm tọa độ
              </Button>
            </div>
          </div>
        </div>
        <div style={{ float: "right", marginTop: "12px" }}>
          <Button type="submit">Lưu</Button>
        </div>
      </form>
    </>
  );
};

export default AddHienTrang;
