import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { LayerName } from "../../../common/constants";
import { useParams } from "react-router-dom";
import { HienTrangSDD } from "../../../common/definitions";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import Button from "../../../Shared/Button/Button";
import FormatGeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";
import SelectDistrict from "../../../Shared/select/SelectDistrict";
import SelectDomainSdd from "../../../Shared/select/SelectDomainSdd";
import useWriteTransaction from "../../../services/transaction.service";
import SelectFloatLabel, { Option } from "../../../Shared/select/SelectFloatLabel";
import { DomainHienTrang } from "../../../common/domain";

interface EditHienTrangProps {}

const EditHienTrang: FC<EditHienTrangProps> = () => {
  const writeTraction = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);
  const [hienTrangSdd, setHienTrangSdd] = useState<Partial<HienTrangSDD>>({});
  const [feature, setFeature] = useState<Feature>();

  const { id } = useParams();

  const fid = useMemo(() => id, [id]);

  const updateFeature = useCallback(
    (e: any) => {
      e.preventDefault();
      if (fid && feature) {
        feature?.setProperties(hienTrangSdd);
        feature.setGeometryName("geom");
        const ftn = new Feature();
        ftn.setProperties(hienTrangSdd);
        ftn.setId(fid);
        writeTraction.updateFeatures([ftn]).then(() => {
          alert("Cập nhật thành công!");
        });
      }
    },
    [fid, feature, hienTrangSdd],
  );

  const styles = {
    from: {
      margin: "100px 300px",
    },
    group: {
      display: "flex",
      width: "100%",
      gap: 16,
    },
    input: {
      width: "50%",
    },
  };

  useEffect(() => {
    if (fid) {
      writeTraction.getFeature(fid).then(({ features }) => {
        const [_feature] = features;
        const geoJsonFormat = new FormatGeoJSON();
        const featureReaded = geoJsonFormat.readFeature(_feature);
        setHienTrangSdd(_feature.properties);
        setFeature(featureReaded);
      });
    }
  }, []);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setHienTrangSdd({ ...hienTrangSdd, [name]: value });
  };

  const handleChangeSelect = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = evt.target;
      setHienTrangSdd({ ...hienTrangSdd, [name]: value });
    },
    [hienTrangSdd],
  );

  return (
    <form style={styles.from}>
      {hienTrangSdd && (
        <>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.SoTo}
                placeholder="Số tờ"
                name="SoTo"
                onChange={handleChange}
              />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={hienTrangSdd.SoThua}
                placeholder="Số thửa"
                name="SoThua"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel
                type="number"
                value={hienTrangSdd.DienTich}
                placeholder="Diện tích"
                name="DienTich"
                onChange={handleChange}
              />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={hienTrangSdd.TenDuong}
                placeholder="Tên đường"
                name="TenDuong"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel value={hienTrangSdd.SoNha} placeholder="Số nhà" name="SoNha" onChange={handleChange} />
            </div>
            <div style={styles.input}>
              <SelectDistrict value={hienTrangSdd.MaPX} name="MaPX" onChange={handleChangeSelect} />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <SelectFloatLabel
                name="KyHieuLoaiDat"
                placeholder="Mục đích sử dụng đất"
                value={hienTrangSdd.KyHieuLoaiDat}
                onChange={handleChangeSelect}
              >
                <Option value={""}>Mục đích sử dụng đất</Option>
                {DomainHienTrang.map((dm) => (
                  <Option value={dm.code}>{dm.name}</Option>
                ))}
              </SelectFloatLabel>
            </div>
            <div style={styles.input}>
              <InputFloatLabel value={hienTrangSdd.GhiChu} name="GhiChu" onChange={handleChange} />
            </div>
          </div>

          <div style={{ float: "right", marginTop: "12px" }}>
            <Button onClick={updateFeature}>Lưu</Button>
          </div>
        </>
      )}
    </form>
  );
};

export default EditHienTrang;
