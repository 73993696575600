import { Map } from "ol";
import { useEffect } from "react";
import jsPDF from "jspdf";
import saveAs from "file-saver";
import PrintDialog from "ol-ext/control/PrintDialog";
import CanvasTitle from "ol-ext/control/CanvasTitle";
import "./control-print.css";
import Style from "ol/style/Style";
import Text from "ol/style/Text";

type Props = {
  map?: Map;
};

const ControlPrintDialog = ({ map }: Props) => {
  useEffect(() => {
    if (map) {
      PrintDialog.addLang("vi", {
        cancel: "Hủy",
        copied: "Đã sao chép",
        custom: "Tùy chỉnh",
        errorMsg: "Đã xảy ra lỗi",
        legend: "Bật/ tắt chú giải",
        margin: "Khoản cách lề",
        orientation: "Hướng giấy",
        mapTitle: "Bật/ tắt tiêu đề",
        portrait: "Dọc",
        landscape: "Ngang",
        saveas: "Lưu bản đồ",
        north: "Bật/ tắt la bàn",
        printBt: "In",
        scale: "Tỷ lệ",
        saveLegend: "Lưu chú giải",
        size: "Kích thước",
        title: "In bản đồ",
      });

      const printControl = new PrintDialog({ lang: "vi" });
      printControl.setSize("A4");
      printControl.setMargin(5);
      printControl.setTarget("printEl");
      map.addControl(printControl);

      map.addControl(
        new CanvasTitle({
          title: "Thay đối tiêu đề",
          style: new Style({
            text: new Text({ font: '20px "Lucida Grande",Verdana,Geneva,Lucida,Arial,Helvetica,sans-serif' }),
          }),
        }),
      );

      // custom label select print map
      const optionValues = ["Sao chép ảnh", "Lưu ảnh dưới dạng jpeg", "Lưu ảnh dưới dạng png", "Lưu ảnh dưới dạng pdf"];
      const optionEl = document.querySelectorAll("li.ol-saveas > select > option");
      optionEl.forEach((option) => {
        const { value } = option.attributes[0];
        if (value) {
          option.textContent = optionValues[+value];
        }
      });

      printControl.on(["print", "error"] as any, (e: any) => {
        // Print success
        if (e.image) {
          if (e.pdf) {
            // Export pdf using the print info
            const pdf = new jsPDF({
              orientation: e.print.orientation,
              unit: e.print.unit,
              format: e.print.size,
            });
            pdf.addImage(
              e.image,
              "JPEG",
              e.print.position[0],
              e.print.position[0],
              e.print.imageWidth,
              e.print.imageHeight,
            );
            pdf.save(e.print.legend ? "legend.pdf" : "map.pdf");
          } else {
            // Save image as file
            e.canvas.toBlob(
              (blob: any) => {
                const name = (e.print.legend ? "legend." : "map.") + e.imageType.replace("image/", "");
                saveAs(blob, name);
              },
              e.imageType,
              e.quality,
            );
          }
        } else {
          console.warn("No canvas to export");
        }
      });
    }
  }, [map]);

  return <div id="printEl" className="btn-control" style={{ position: "absolute", bottom: 20, right: 10 }}></div>;
};

export default ControlPrintDialog;
