import { useEffect, useState } from "react";
import { HienTrangSDD, SoNha } from "../../../common/definitions";
import useIntegrateAPI from "../../../services/integrate-api";

interface InfoSoNhaProps {
  data?: HienTrangSDD;
}

const InfoSoNha: React.FC<InfoSoNhaProps> = ({ data }) => {
  const [dataSoNha, setDataSoNha] = useState<Array<SoNha>>([]);
  const { getSoNha } = useIntegrateAPI();
  useEffect(() => {
    if (data) {
      const { SoTo, SoThua, MaPX } = data;
      getSoNha(SoTo, SoThua, MaPX).then(setDataSoNha);
    }
  }, [data, getSoNha]);
  return (
    <div style={{ paddingTop: 6 }}>
      {dataSoNha.length > 0 && (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th colSpan={3}>Thông tin số nhà</th>
            </tr>
            <tr>
              <th>Số nhà</th>
              <th>Tên đường</th>
              <th>Ấp/khu phố</th>
            </tr>
          </thead>
          <tbody>
            {dataSoNha.map((dt, index) => (
              <tr key={index}>
                <td>{dt.SoNha}</td>
                <td>{dt.TenDuong}</td>
                <td>{dt.ApKhuPho}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InfoSoNha;
