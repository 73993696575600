import jwtDecode from "jwt-decode";
import { useCallback } from "react";
import {
  GiayChungNhanQSDD,
  GiayPhepXayDung,
  NhuCauChuyenDoiMDSDD,
  ResultApi,
  SearchSoNha,
  SoNha,
} from "../common/definitions";
import { dmPhuongXa } from "../common/domain";
import { intergateAPI } from "../common/utils";
import { varConfig } from "../common/var-config";

const useIntegrateAPI = () => {
  const expiredToken = useCallback((token: string) => {
    const decoded = jwtDecode<{ exp: number }>(token);
    return Date.now() > decoded.exp * 1000;
  }, []);

  const authorization = useCallback(async () => {
    let token = localStorage.getItem("Token");
    if (!token || expiredToken(token)) {
      const {
        data: {
          Data: { AccessToken },
        },
      } = await intergateAPI.post<{ Success: boolean; StatusCode: number; Data: { AccessToken: string } }>(
        `/Sys_Account/Login`,
        {
          username: varConfig.usernameApi,
          password: varConfig.passwordApi,
        },
      );
      localStorage.setItem("Token", AccessToken);
      token = AccessToken;
    }
    intergateAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }, [expiredToken]);

  // useEffect(() => {
  //   authorization();
  // }, [authorization]);

  const logGis = useCallback(
    async (params: { tenPhuongXa: string; soTo: number | string; soThua: string; soNha: string; tenDuong: string }) => {
      const { soThua, soTo, soNha, tenDuong, tenPhuongXa } = params;
      const data = {
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        idLog: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        huyen: "Huyện Củ Chi",
        ngayTim: new Date().toISOString(),
        userName: "anonymous",
        to: `${soTo}`,
        thua: soThua,
        soNha,
        tenPhuongXa: tenPhuongXa ? dmPhuongXa[tenPhuongXa] : "",
        tenDuong,
      };
      await authorization();
      intergateAPI.post("/Por_LogGis", data);
    },
    [authorization],
  );

  const getGiayPhepXayDung = useCallback(
    async (soTo: number, soThua: string, maPX: string) => {
      try {
        if (!soTo || !soThua || !maPX) return;
        await authorization();
        const { data } = await intergateAPI.post<ResultApi<GiayPhepXayDung>>("/Por_GPXD/SearchMaPX", {
          soTo: soTo.toString(),
          soThua,
          maPX,
        });
        return data.Data[0];
      } catch (error) {
        throw Error("Error!");
      }
    },
    [authorization],
  );

  const getNhuCauChuyenDoiMDSDD = useCallback(
    async (soTo: number, soThua: string, maPX: string) => {
      try {
        if (!soTo || !soThua || !maPX) return;
        await authorization();
        const { data } = await intergateAPI.post<ResultApi<NhuCauChuyenDoiMDSDD>>("/Por_NCCDMDSDD/SearchMaPX", {
          soTo: soTo.toString(),
          soThua,
          maPX,
        });
        return data.Data[0];
      } catch (error) {
        throw Error("Error!");
      }
    },
    [authorization],
  );

  const getGiayChungNhanQSDD = useCallback(
    async (soTo: number, soThua: string, maPX: string) => {
      try {
        if (!soTo || !soThua || !maPX) return;
        await authorization();
        const { data } = await intergateAPI.post<ResultApi<GiayChungNhanQSDD>>("/Por_GCNQSDD/SearchMaPx", {
          soTo: soTo.toString(),
          soThua,
          maPX,
        });
        return data.Data[0];
      } catch (error) {
        throw Error("Error!");
      }
    },
    [authorization],
  );

  const getSoNha = useCallback(
    async (soTo: number, soThua: string, maPX: string) => {
      try {
        if (!soTo || !soThua || !maPX) return [];
        await authorization();
        const { data } = await intergateAPI.post<ResultApi<SoNha>>("/Por_GisSoNha/SearchMaPX", {
          soTo: soTo.toString(),
          soThua,
          maPX,
        });
        return data.Data;
      } catch (error) {
        throw Error("Error!");
      }
    },
    [authorization],
  );

  const searchSoNha = useCallback(
    async (params: { soNha?: string; tenDuong?: string; maPX?: string }) => {
      try {
        await authorization();
        const { data } = await intergateAPI.post<ResultApi<SearchSoNha>>("/Por_GisSoNha/SearchSoNha", params);
        return data.Data;
      } catch (error) {
        throw Error("Error!");
      }
    },
    [authorization],
  );

  return { logGis, getGiayPhepXayDung, getNhuCauChuyenDoiMDSDD, getGiayChungNhanQSDD, getSoNha, searchSoNha };
};

export default useIntegrateAPI;
