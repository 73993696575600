import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackComponent from "../BackComponent";
import useWriteTransaction from "../../../services/transaction.service";
import { LayerName } from "../../../common/constants";
import useQuery from "../../../hooks/useQuery";
import { QuyHoachSDD } from "../../../common/definitions";
import Detail, { RowDetails } from "../Detail";
import InfoQHCSuDungDat from "./InfoQHCSuDungDat";
import { MultiPolygon } from "ol/geom";
import { readFeature } from "../../../common/map.util";
import InfoQHTNMT from "./InfoQHTNMT";
import { Feature, Map } from "ol";
import { dmLoaiQuyHoach } from "../../../common/domain";

enum LoaiQuyHoach {
  QuyHoachChung,
  QuyHoachTNMT,
  QuyHoachPhanKhu,
}

interface InfoQuyHoachProps {
  map: Map;
  domainQhpk: { [k: string | number]: any };
}

const InfoQuyHoach: React.FC<InfoQuyHoachProps> = ({ map, domainQhpk }) => {
  const navigate = useNavigate();
  const { searchParams } = useQuery();
  const writeTransaction = useWriteTransaction<QuyHoachSDD>(LayerName.QUY_HOACH);
  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);
  const [feature, setFeature] = useState<Feature<MultiPolygon>>();
  const [loaiQuyHoach, setLoaiQuyHoach] = useState(LoaiQuyHoach.QuyHoachPhanKhu);
  console.log(domainQhpk);
  const [rowDetails, setRowDetails] = useState<RowDetails>([]);

  useEffect(() => {
    const rows: RowDetails = [
      { name: "MucDichSuDungDat", title: "Mục đích sử dụng đất", domain: domainQhpk },
      { name: "DienTich", title: "Diện tích", unit: { __html: "m<sup>2</sup>" }, type: "number" },
      { name: "TenCongTrinhXayDung", title: "Tên công trình xây dựng" },
      { name: "GhiChu", title: "Ghi chú" },
      { name: "LoaiQuyHoach", title: "Loại quy hoạch", domain: dmLoaiQuyHoach },
      { name: "MaSo", title: "Ký hiệu ô" },
      { name: "MaOPho", title: "Mã ô phố" },
      { name: "MaQuyUoc", title: "Mã quy ước" },
      { name: "ChucNang", title: "Chức năng" },
      { name: "DanSo", title: "Dân số", unit: { __html: " người" } },
      { name: "TangCao", title: "Tầng cao" },
      { name: "MatDo", title: "Mật độ xây dựng", unit: { __html: "%" } },
      { name: "HeSoSDD", title: "Hệ số sử dụng đất" },
    ];
    setRowDetails(rows);
  }, [domainQhpk]);

  const getQuyHoach = useCallback(() => {
    if (fid) {
      writeTransaction.getFeature(fid).then(({ features }) => {
        const [feature] = features;
        const featureReaded = readFeature<MultiPolygon>(feature);
        setFeature(featureReaded);
      });
    }
  }, [fid, writeTransaction]);

  useEffect(() => {
    getQuyHoach();
  }, []);

  const onClickQuyHoach = useCallback((type: LoaiQuyHoach) => {
    if (type === LoaiQuyHoach.QuyHoachChung) {
    }
    setLoaiQuyHoach(type);
  }, []);

  const onBack = useCallback(() => {
    const geom = feature?.getGeometry();
    if (geom) {
      map.getView().fit(geom.getExtent());
    }
    setLoaiQuyHoach(LoaiQuyHoach.QuyHoachPhanKhu);
  }, [feature, map]);

  return (
    <>
      {feature !== undefined && (
        <>
          {loaiQuyHoach === LoaiQuyHoach.QuyHoachPhanKhu && (
            <>
              <BackComponent
                title="Thông tin ô chức năng"
                onBack={() => {
                  navigate(-1);
                }}
              />
              <Detail rows={rowDetails} data={feature.getProperties()} />
              <div className="detail-wrapper">
                <div className="detail-group">
                  <div className="detail-label">Quy hoạch chung</div>
                  <div className="detail-text">
                    <div className="more-detail" onClick={() => onClickQuyHoach(LoaiQuyHoach.QuyHoachChung)}>
                      Xem chi tiết
                    </div>
                  </div>
                </div>
                <div className="detail-group">
                  <div className="detail-label"> Quy hoạch của Tài nguyên môi trường</div>
                  <div className="detail-text">
                    <div className="more-detail" onClick={() => onClickQuyHoach(LoaiQuyHoach.QuyHoachTNMT)}>
                      Xem chi tiết
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {loaiQuyHoach === LoaiQuyHoach.QuyHoachChung && (
            <>
              <BackComponent title="Thông tin quy hoạch chung sử dụng đất" onBack={onBack} />
              <InfoQHCSuDungDat feature={feature} map={map} />
            </>
          )}
          {loaiQuyHoach === LoaiQuyHoach.QuyHoachTNMT && (
            <>
              <BackComponent title="Thông tin quy hoạch sử dụng đất Tài nguyên môi trường" onBack={onBack} />{" "}
              <InfoQHTNMT feature={feature} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default InfoQuyHoach;
