import Button from "../../../Shared/Button/Button";
import SLDParser from "geostyler-sld-parser";
import { Rule } from "geostyler-style";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import instanceAxios from "../../../common/utils";
import JSZip from "jszip";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";

interface AddIconProps {
  onAfterSave: () => void;
  onCancel: () => void;
}

const stylesName = "congtrinhtienich";
let ruleTextSymbolizer: string | undefined = "";

const initRule = {
  code: "",
  image: "",
  base64: "",
  name: "",
};

const AddIcon: React.FC<AddIconProps> = ({ onAfterSave, onCancel }) => {
  const [rule, setRule] = useState<{ base64: string; image: string; code: string; name: string }>(initRule);
  const [parser] = useState(new SLDParser());
  const anotherRules = useRef<string | undefined>();

  const onChangeInput = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = evt.target;
      setRule({ ...rule, [name]: value });
    },
    [rule],
  );

  const toBase64 = useCallback(
    (file: any): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }),
    [],
  );

  const onChangeInputFile = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      const files = evt.target.files;
      if (files && files.length) {
        const file = files[0];
        const base64 = await toBase64(file);
        setRule({ ...rule, image: file.name, base64 });
      }
    },
    [rule, toBase64],
  );

  const handleAdd = useCallback(() => {
    const { code, image, base64, name } = rule;
    instanceAxios.get(`rest/workspaces/cuchi/styles/${stylesName}.sld`).then(({ data }) => {
      const ruleTextSymbolizer = data.split("<Rule>").pop()?.split("</Rule>")?.[0];
      parser.readStyle(data).then(({ output }) => {
        if (output) {
          const ruleNew: Rule = {
            name,
            scaleDenominator: { max: 16000 },
            filter: ["==", "MaQuyUoc", code],
            symbolizers: [
              {
                kind: "Icon",
                image,
              },
            ],
          };
          output.rules.unshift(ruleNew);

          parser
            .writeStyle(output)
            .then(({ output: sld }) => {
              if (sld) {
                const zip = new JSZip();
                const slds = sld.split("</FeatureTypeStyle>");
                slds[0] += `<Rule>${ruleTextSymbolizer}</Rule>`;
                slds[0] += "</FeatureTypeStyle>";
                zip.file("congtrinhtienich.sld", slds.join(""));
                const data = base64.split(",").pop() as string;
                zip.file(image, data, { base64: true });

                zip.generateAsync({ type: "blob" }).then((content) => {
                  instanceAxios
                    .put(`/rest/workspaces/cuchi/styles/${stylesName}?raw=true`, content, {
                      headers: {
                        "Content-Type": "application/zip",
                      },
                    })
                    .then(({ status }) => {
                      if (status === 200) {
                        alert("Thêm chú giải thành công!");
                        setRule(initRule);
                        onAfterSave();
                      }
                    });
                });
              }
            })
            .catch((error) => console.log(error));
        }
      });
    });
  }, [onAfterSave, parser, rule]);

  return (
    <div style={{ textAlign: "right", padding: "12px 150px" }}>
      <div style={{ display: "flex", width: "100%", justifyContent: "center", columnGap: 12 }}>
        <div style={{ width: 200, textAlign: "center" }}>
          <img src={rule.base64} alt="" width={24} />
          <div>
            <input type="file" id="upload" accept="image/png" hidden onChange={onChangeInputFile} />
            <label
              htmlFor="upload"
              style={{
                display: "inline-block",
                backgroundColor: "indigo",
                color: "white",
                padding: "0.5rem",
                fontFamily: "sans-serif",
                borderRadius: "0.3rem",
                cursor: "pointer",
              }}
            >
              Chọn hình ảnh
            </label>
          </div>
        </div>

        <div style={{ width: 200 }}>
          <InputFloatLabel placeholder="Ký hiệu sử dụng đất" name="code" onChange={onChangeInput} value={rule.code} />
        </div>

        <div style={{ width: 600 }}>
          <InputFloatLabel
            placeholder="Tên công trình tiện ích"
            name="name"
            onChange={onChangeInput}
            value={rule.name}
          />
        </div>
      </div>
      <div style={{ paddingTop: 12 }}>
        <Button style={{ marginRight: 4 }} onClick={onCancel} className="outline">
          Hủy
        </Button>
        <Button onClick={handleAdd}>Lưu</Button>
      </div>
    </div>
  );
};

export default AddIcon;
