import { FeatureCollection, Geometry, MultiPolygon, GeoJsonProperties } from "geojson";
import { useCallback, useMemo } from "react";
import { WORKSPACES } from "../common/constants";
import instanceAxios from "../common/utils";

type WFSVendorParameters = {
  cql_filter?: string;
  srsName?: string;
  strict?: string;
  namespace?: string;
  featureid?: string;
  propertyName?: string;
  request: "GetFeature";
  version: "1.1.0";
  typeName: string;
  outputFormat: "json" | "text/xml";
  service?: "wfs" | "wms";
  maxFeatures?: string;
};
// https://gis.stackexchange.com/questions/214850/editing-wfs-layer
const wfsTransaction = (
  typeName: string,
  type: "Update",
  fid: string,
  wfsProperties: string,
) => `<wfs:Transaction service="WFS" version="1.0.0"
xmlns:topp="http://www.openplans.org/topp"
xmlns:ogc="http://www.opengis.net/ogc"
xmlns:wfs="http://www.opengis.net/wfs">
<wfs:${type} typeName="${typeName}">
  ${wfsProperties}
  <ogc:Filter>
    <ogc:FeatureId fid="${fid}"/>
  </ogc:Filter>
</wfs:${type}>
</wfs:Transaction>`;

const useTransaction = <T>(name: string) => {
  const typeName = `${WORKSPACES}:${name}`;

  const wfsVendorParameters: WFSVendorParameters = useMemo(
    () => ({
      request: "GetFeature",
      version: "1.1.0",
      service: "wfs",
      typeName,
      outputFormat: "json",
      maxFeatures: "500",
    }),
    [typeName],
  );

  const getFeatureById = useCallback(
    async (fid: string): Promise<FeatureCollection<MultiPolygon, T>> => {
      const parameters: WFSVendorParameters = {
        ...wfsVendorParameters,
        featureid: fid,
      };
      const search = new URLSearchParams(parameters).toString();
      const { data } = await instanceAxios.get<FeatureCollection<MultiPolygon, T>>(`/wfs?${search}`);
      return data;
    },
    [wfsVendorParameters],
  );

  const getAllFeature = useCallback(
    async (params: {
      filter?: string;
      propertyName?: Array<string>;
      bbox?: string;
    }): Promise<FeatureCollection<MultiPolygon, GeoJsonProperties>> => {
      const { filter, propertyName } = params;
      const parameters: WFSVendorParameters = {
        ...wfsVendorParameters,
        srsName: "EPSG:3857",
      };
      if (filter) parameters.cql_filter = filter;
      if (propertyName && propertyName.length) parameters.propertyName = propertyName.join(",");

      const search = new URLSearchParams(parameters).toString();
      const { data } = await instanceAxios.get(`/wfs?${search}`, { headers: { "Content-Type": "text/xml" } });

      return data;
    },
    [wfsVendorParameters],
  );

  const updateFeature = useCallback(
    async (fid: string, properties: Record<string, any>) => {
      try {
        let wfsProperties = "";
        const { geometry, ...rest } = properties;
        for (const [key, value] of Object.entries(rest)) {
          wfsProperties += `<wfs:Property>
                <wfs:Name>${key}</wfs:Name>
                <wfs:Value>${value || ""}</wfs:Value> 
              </wfs:Property>`;
        }
        const transaction = wfsTransaction(typeName, "Update", fid, wfsProperties);
        const result = await instanceAxios.post("wfs", transaction, { headers: { "Content-Type": "text/xml" } });
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(result.data, "text/xml");
        if (xmlDoc.getElementsByTagName("wfs:SUCCESS").length) {
          return true;
        }
        return false;
      } catch (err) {
        console.log(err);
      }
    },
    [typeName],
  );

  return {
    getAllFeature,
    getFeatureById,
    updateFeature,
  };
};

export default useTransaction;
