import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Feature, Map } from "ol";
import { and, equalTo } from "ol/format/filter";
import { FitOptions } from "ol/View";
import VectorLayer from "ol/layer/Vector";
import { Vector } from "ol/source";

import "./dia-chi.css";
import { LayerName, LAYER_ID } from "../../../common/constants";
import { HienTrangSDD, SearchSoNha } from "../../../common/definitions";
import { DomainPhuongXa } from "../../../common/domain";
import Button from "../../../Shared/Button/Button";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import SelectFloatLabel, { Option } from "../../../Shared/select/SelectFloatLabel";
import useWriteTransaction from "../../../services/transaction.service";
import { highLightStyle, readFeature } from "../../../common/map.util";
import useQuery from "../../../hooks/useQuery";
import useIntegrateAPI from "../../../services/integrate-api";
import { toastService } from "../../../services/toast.service";
import { unByKey } from "ol/Observable";

type DiaChiSearch = {
  soNha: string;
  tenDuong: string;
  maPhuongXa: string;
};

interface DiaChiComponentProps {
  map?: Map;
}

const DiaChiComponent: FC<DiaChiComponentProps> = ({ map }) => {
  const navigate = useNavigate();
  const { searchParams } = useQuery();
  const [result, setResult] = useState<Array<SearchSoNha>>();
  const { getFeatures: getHientTrangs } = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);
  const { getFeatures: getPhuongXas } = useWriteTransaction(LayerName.RANHGIOI_PHUONGXA);
  const { searchSoNha, logGis } = useIntegrateAPI();
  const { warning } = toastService();
  const [diaChiSearch, setDiaChiSearch] = useState<DiaChiSearch>({
    soNha: "",
    tenDuong: "",
    maPhuongXa: "",
  });

  const featureRef = useRef<Feature>();

  const getHighlightLayer = useCallback((map: Map) => {
    const layer = map
      .getAllLayers()
      .find((layer) => layer instanceof VectorLayer && layer.get("id") === LAYER_ID.HIGHLIGHT);
    return layer as VectorLayer<Vector>;
  }, []);

  const getRanhGioiQuanHuyen = useCallback(
    (maPhuongXa: string) => {
      if (map) {
        getPhuongXas({ filter: equalTo("MaPX", maPhuongXa) }).then(({ features }) => {
          const [feature] = features;

          if (feature) {
            const featureReaded = readFeature(feature);
            const extent = featureReaded.getGeometry()?.getExtent();
            const fitOptions: FitOptions = {
              duration: 300,
              minResolution: 18,
            };
            featureReaded.setStyle(highLightStyle);

            extent && map.getView().fit(extent, fitOptions);
            const highlightLayer = getHighlightLayer(map);
            if (highlightLayer && featureRef.current) {
              if (highlightLayer.getSource()?.hasFeature(featureRef.current)) {
                highlightLayer.getSource()?.removeFeature(featureRef.current);
              }
            }
            featureRef.current = featureReaded;
            highlightLayer.getSource()?.addFeature(featureRef.current);
          }
        });
      }
    },
    [getPhuongXas, getHighlightLayer, map],
  );

  useEffect(() => {
    if (!map) return;
    const highlightLayer = getHighlightLayer(map);
    const evtKey = map.on("moveend", (evt) => {
      const zoom = evt.map.getView().getZoom();
      if (zoom && zoom > 3 && featureRef.current) {
        highlightLayer.getSource()?.removeFeature(featureRef.current);
      }
    });
    return () => {
      unByKey(evtKey);
    };
  }, [getHighlightLayer, map]);

  const onSearch = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault();
      const { soNha, tenDuong, maPhuongXa } = diaChiSearch;
      if (soNha || tenDuong || maPhuongXa) {
        const diaChi: { soNha?: string; tenDuong?: string; maPX?: string } = {};
        if (soNha) diaChi.soNha = soNha;
        if (tenDuong) diaChi.tenDuong = tenDuong;
        if (maPhuongXa) diaChi.maPX = maPhuongXa;
        searchSoNha(diaChi).then((res) => {
          setResult(res);
        });
      } else {
        alert("Vui lòng nhập ít nhất 1 điều kiện tìm kiếm!");
      }
    },
    [diaChiSearch, searchSoNha],
  );

  const onChangeInput = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = evt.target;
      setDiaChiSearch({ ...diaChiSearch, [name]: value });
    },
    [diaChiSearch],
  );

  const onChangeSelect = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const { value, name } = evt.target;
      setDiaChiSearch({ ...diaChiSearch, [name]: value });
      getRanhGioiQuanHuyen(value);
    },
    [diaChiSearch, getRanhGioiQuanHuyen],
  );

  const onClickItemResult = useCallback(
    (item: SearchSoNha) => {
      const { SoTo, SoThua, MaPX, SoNha, TenDuong, TenPhuongXa } = item;
      if (!SoTo || !SoThua || !MaPX) {
        warning("Không tìm thấy thửa đất");
        return;
      }

      getHientTrangs({
        filter: and(equalTo("SoTo", SoTo), equalTo("SoThua", SoThua), equalTo("MaPX", MaPX)),
      }).then(({ features }) => {
        const [feature] = features;
        if (feature) {
          logGis({ soNha: SoNha, soThua: SoThua, soTo: SoTo, tenDuong: TenDuong, tenPhuongXa: TenPhuongXa });
          searchParams.set("fid", feature.id + "");
          navigate({
            pathname: "/map/place",
            search: searchParams.toString(),
          });
        } else {
          warning("Không tìm thấy vị trí thửa đất trên bản đồ");
        }
      });
    },
    [getHientTrangs, logGis, navigate, searchParams, warning],
  );

  const renderResultItem = useCallback(
    (resItem: SearchSoNha) => (
      <div className="box-item" onClick={() => onClickItemResult(resItem)}>
        <h4>{`Tờ ${resItem.SoTo} thửa ${resItem.SoThua}`}</h4>
        <p>Số nhà: {resItem.SoNha}</p>
        <p>Tên đường: {resItem.TenDuong}</p>
        <p>Phường/xã: {resItem.TenPhuongXa}</p>
      </div>
    ),
    [onClickItemResult],
  );

  return (
    <div
      style={{ height: "100%", overflowY: "auto", padding: "2px", margin: "-2px" }}
      className="custom-scrollbar address"
    >
      <form action="" className="search-box" onSubmit={onSearch}>
        <div className="group-control">
          <div className="form-control">
            <InputFloatLabel
              type="text"
              placeholder="Số nhà"
              onChange={onChangeInput}
              value={diaChiSearch.soNha}
              required
              name="soNha"
            />
          </div>
          <div className="form-control">
            <InputFloatLabel
              type="text"
              placeholder="Tên đường"
              onChange={onChangeInput}
              value={diaChiSearch.tenDuong}
              required
              name="tenDuong"
            />
          </div>
          <div className="form-control">
            <SelectFloatLabel placeholder="Tỉnh/thành phố">
              <Option value={""}>TP Hồ Chí Minh</Option>
            </SelectFloatLabel>
          </div>
          <div className="form-control">
            <SelectFloatLabel placeholder="Quận/huyện">
              <Option value={""}>Huyện Củ Chi</Option>
            </SelectFloatLabel>
          </div>
          <div className="form-control">
            <SelectFloatLabel
              onChange={onChangeSelect}
              placeholder="Phường/xã"
              name="maPhuongXa"
              value={diaChiSearch.maPhuongXa}
            >
              <Option value={""}>Tất cả</Option>
              {DomainPhuongXa.map((m) => (
                <Option value={m.code} key={m.code}>
                  {m.name}
                </Option>
              ))}
            </SelectFloatLabel>
          </div>
        </div>

        <div style={{ margin: "12px 0", display: "grid" }}>
          <Button onClick={() => {}} className="button-primary">
            Tìm kiếm
          </Button>
        </div>
      </form>
      <div className="div-result">
        {result && result.length === 0 ? (
          <div style={{ textAlign: "center", fontSize: 14, color: "#604f4f", padding: 12 }}>
            Không tìm thấy kết quả phù hợp
          </div>
        ) : (
          result && result.map((res) => renderResultItem(res))
        )}
      </div>
    </div>
  );
};

export default DiaChiComponent;
