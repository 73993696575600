import { toast, ToastOptions, ToastContent } from "react-toastify";

type ToastOptionsOverlap = Pick<ToastOptions, "position" | "autoClose">;

export function toastService(optionsOverlap?: ToastOptionsOverlap) {
  const toastOptions: ToastOptions = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...optionsOverlap,
  };

  const warning = (content: ToastContent) => {
    return toast.warning(content, toastOptions);
  };

  const success = (content: ToastContent) => {
    return toast.success(content, toastOptions);
  };

  return { warning, success };
}
