/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FcSearch, FcAbout, FcViewDetails } from "react-icons/fc";
import { URLSearchParamsInit, useNavigate, useParams } from "react-router-dom";

// css
import "./map.css";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "ol-ext/dist/ol-ext.css";

// openlayer
import TileLayer from "ol/layer/Tile";
import TileImage from "ol/source/TileImage";
import { View, Map, Feature } from "ol";
import * as olProj from "ol/proj";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { FeatureLike } from "ol/Feature";
import { Polygon } from "ol/geom";
import MultiPolygon from "ol/geom/MultiPolygon";
import { BaseLayerOptions, GroupLayerOptions } from "ol-layerswitcher";
import LayerGroup from "ol/layer/Group";
import { EventsKey } from "ol/events";
import { unByKey } from "ol/Observable";
import { getCenter } from "ol/extent";
import { FitOptions } from "ol/View";
import GeolocationButton from "ol-ext/control/GeolocationButton";
import { ImageWMS, TileWMS } from "ol/source";
import { contains as containsFilter } from "ol/format/filter";
import ExtLayerSwitcher from "ol-ext/control/LayerSwitcher";
import { intersects as filterIntesects } from "ol/format/filter";
import { CENTER, LayerName, PARAMS, SRSNAME, WORKSPACES, ZOOM } from "../../common/constants";
import { FeatureGeoJson, HienTrangSDD, QuyHoachSDD } from "../../common/definitions";
import PopupComponent from "../popup/PopupComponent";
import { ColorType } from "./layers";
import Measure from "../measure/Meaure";
import TabComponent, { TabItem } from "../Tabs/TabComponent";
import TabSearch from "../Tabs/TabSearch";
import { readFeature, intersectFeatures } from "../../common/map.util";
import instanceAxios, { formatNumber } from "../../common/utils";
import { varConfig } from "../../common/var-config";
import CanvasAttribution from "ol-ext/control/CanvasAttribution";
import { defaults } from "ol/control";
import ControlPrintDialog from "../control-print-dialog/ControlPrintDialog";
import COLOR from "./../../common/color.json";
import useWriteTransaction from "../../services/transaction.service";
import Point from "ol/geom/Point";
import MapBrowserEvent from "ol/MapBrowserEvent";
import LegendComponent from "../legend/Legend";
import permissions from "./mockup.json";
import layerGroups from "./group-layer.mockup.json";
import useQuery from "../../hooks/useQuery";
import RangeOpacityComponent from "../range-opacity/RangeOpacityComponent";
import ImageLayer from "ol/layer/Image";
import useTransactionHienTrang from "../../services/hienTrang.service";

export type MoTaHienTrang = {
  loaiDat: string;
  dienTich: string;
  color: string;
  type: "intersect" | "difference";
  id: string;
  index: number;
  kyHieuO: string;
};

type ContextValue = {
  map: undefined | Map;
  searchParams?: undefined | URLSearchParams;
  setSearchParams?: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined,
  ) => void;
};

enum TabEnum {
  search,
  info,
  legend,
}

const initValue: ContextValue = {
  map: undefined,
  searchParams: undefined,
};

export const ThemeContext = React.createContext(initValue);

function MapComponent() {
  const navigate = useNavigate();
  const { param } = useParams();
  const [map, setMap] = useState<Map>();
  const [content, setContent] = useState<Feature[]>([]);
  const [moTaHienTrangVoiQuyHoach, setMoTaHienTrangVoiQuyHoach] = useState<Array<MoTaHienTrang>>([]);
  const transactionHienTrang = useTransactionHienTrang();
  const transactionQuyHoach = useWriteTransaction<QuyHoachSDD>(LayerName.QUY_HOACH);
  const { searchParams } = useQuery();
  const selected = useMemo(
    () =>
      new Style({
        fill: new Fill({
          color: "rgba(0, 255, 198, 0.2)",
        }),
        stroke: new Stroke({
          color: "rgba(0, 255, 198, 1)",
          width: 4,
        }),
      }),
    [],
  );

  const selectStyle: any = useCallback(
    (feature: FeatureLike) => {
      const color = feature.get("Color");
      color && selected.getFill().setColor(color);
      return selected;
    },
    [selected],
  );

  const featureOverlay = useMemo(
    () =>
      new VectorLayer({
        source: new VectorSource(),
        style: selectStyle,
        properties: {
          id: "HIGHLIGHT-LAYER",
          title: "highLight",
        },
        zIndex: 9,
        // map
      }),
    [selectStyle],
  );

  const interactLayer = useMemo(
    () =>
      new VectorLayer({
        source: new VectorSource(),
        properties: {
          id: "interaction-layer",
          title: "Interact",
        },
        zIndex: 999,
        // map
      }),
    [],
  );

  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);

  const customHighlight = useCallback(
    async (id: string | null) => {
      featureOverlay?.getSource()?.clear();

      if (map && id && param === PARAMS.place) {
        const layerId = id.split(".")[0];
        let feature: FeatureGeoJson<HienTrangSDD> | FeatureGeoJson<QuyHoachSDD> | null = null;
        if (layerId === LayerName.HIEN_TRANG) {
          const { features } = await transactionHienTrang.getFeature(id);
          feature = features[0];
        }

        if (layerId === LayerName.QUY_HOACH) {
          const { features } = await transactionQuyHoach.getFeature(id);
          feature = features[0];
        }
        if (feature && feature.geometry) {
          const multiPolygon = (feature.geometry as any).coordinates;
          const ftt = new Feature({ geometry: new MultiPolygon(multiPolygon) });
          ftt.setProperties({ ...feature.properties });
          layerId === LayerName.QUY_HOACH && featureOverlay?.getSource()?.addFeature(ftt);
          const geometry = ftt.getGeometry();

          if (geometry) {
            const viewport = map.getViewport();
            const extent = geometry.getExtent();
            const center = getCenter(extent);
            const size = map.getSize();
            const fitOptions: FitOptions = {
              duration: 300,
            };

            map.getView().fit(extent, fitOptions);
            const breakPoint = 576;
            if (viewport.clientWidth < breakPoint) {
              if (size) map.getView().centerOn(center, size, [200, 150]);
            }
          }
          return ftt;
        }
      }
      return false;
    },
    [featureOverlay, map, param],
  );

  useEffect(() => {
    async function getFt() {
      const ft = await customHighlight(fid);
      if (ft && !content.length) setContent([ft]);
    }
    getFt();
  }, [content.length, customHighlight, fid]);

  const handleClickMap = useCallback(
    (evt: MapBrowserEvent<UIEvent>) => {
      const filter = containsFilter("geometry", new Point(evt.coordinate), SRSNAME);
      transactionHienTrang.getFeatures({ filter }).then(({ features }) => {
        const [feature] = features;
        // const featureReaded = readFeature(feature);
        // interactLayer.getSource()?.addFeature(featureReaded);
        if (feature.id) {
          searchParams.set("fid", feature.id as string);
          navigate({
            pathname: "/map/place",
            search: searchParams.toString(),
          });
        }
      });
    },
    [navigate, searchParams],
  );

  const handleInteraction = useCallback(
    async (fid: string | null) => {
      const moTaHienTrang: Array<MoTaHienTrang> = [];
      if (!fid || fid.includes("ht_sudungdat")) {
        interactLayer.getSource()?.clear();
      }

      if (fid && fid.includes("ht_sudungdat")) {
        const { features: featuresHienTrang } = await transactionHienTrang.getFeature(fid);
        if (featuresHienTrang.length) {
          const [featureHienTrang] = featuresHienTrang;
          const featureHienTrangReaded = readFeature(featureHienTrang);
          const geometryHienTrang = featureHienTrangReaded.getGeometry();

          if (geometryHienTrang) {
            const { features: featuresQuyHoach } = await transactionQuyHoach.getFeatures({
              filter: filterIntesects("geom", geometryHienTrang, SRSNAME),
            });
            if (featureHienTrang) {
              const featuresInteract = intersectFeatures(featureHienTrang, featuresQuyHoach);
              featuresInteract.forEach((f, i) => {
                const properties = f.getProperties() as QuyHoachSDD;
                f.setStyle([
                  new Style({
                    fill: new Fill({
                      color: "transparent",
                    }),
                    stroke: new Stroke({
                      color: "#000", // #00FFF0
                      width: 2,
                    }),
                    text: new Text({
                      font: "bold 18px sans-serif",
                      text: i + 1 + "",
                      fill: new Fill({ color: "blue" }),
                      // stroke: new Stroke({ color: "#000", width: 1 }),
                      offsetY: -25,
                      overflow: true,
                    }),
                  }),
                  new Style({
                    geometry: (feature) => {
                      let geometry = feature.getGeometry();
                      let geometryType = geometry?.getType();
                      return geometryType === "Polygon"
                        ? (geometry as Polygon)?.getInteriorPoint()
                        : geometryType === "MultiPolygon"
                        ? (geometry as MultiPolygon)?.getInteriorPoints()
                        : geometry;
                    },
                    image: new Icon({
                      anchor: [0.5, 46],
                      anchorXUnits: "fraction",
                      anchorYUnits: "pixels",
                      src: "/images/marker.png",
                    }),
                  }),
                ]);
                moTaHienTrang.push({
                  loaiDat: properties.MucDichSuDungDat,
                  dienTich: f.getGeometry() ? formatNumber((f.getGeometry() as MultiPolygon)?.getArea() || 0) : "",
                  // color: properties.Color.replace(/\d+[.]+\d*/, "1"),
                  color: COLOR[properties.MucDichSuDungDat as ColorType],
                  type: "intersect",
                  id: f.getId()?.toString() || "",
                  index: i + 1,
                  kyHieuO: properties.MaSo,
                });
              });
              interactLayer.getSource()?.addFeatures(featuresInteract);
            }
          }
        }
      }
      console.log(moTaHienTrang);

      setMoTaHienTrangVoiQuyHoach(moTaHienTrang);
    },
    [interactLayer],
  );

  useEffect(() => {
    if (map) {
      handleInteraction(fid);
    }
  }, [fid, handleInteraction, interactLayer, map]);

  const getLayers = useCallback(() => {
    return instanceAxios.get<{ layerGroups: { layerGroup: Array<{ name: string; href: string }> } }>(
      `/rest/workspaces/${WORKSPACES}/layergroups`,
      {
        headers: { "Content-Type": "application/json" },
      },
    );
  }, []);

  const createTileLayer = useCallback(
    (layer: {
      featureType: string;
      propertyNames: Array<string>;
      layerType: "image" | "tile";
      visible: boolean;
      title: string;
      cQLFilter?: string;
    }) => {
      const url = `${varConfig.geoServerUrl}/cuchi/wms`;
      const format = "image/png";
      const visible = layer.visible !== undefined ? layer.visible : true;
      const params: {
        FORMAT: string;
        VERSION: string;
        tiled?: boolean;
        STYLES: string;
        LAYERS: string;
        exceptions: string;
        CQL_FILTER?: string;
      } = {
        FORMAT: format,
        VERSION: "1.1.1",
        STYLES: "",
        LAYERS: `${WORKSPACES}:${layer.featureType}`,
        exceptions: "application/vnd.ogc.se_inimage",
      };
      if (layer.cQLFilter) {
        params.CQL_FILTER = layer.cQLFilter;
      }
      if (layer.layerType === "image") {
        return new ImageLayer({
          visible,
          source: new ImageWMS({
            ratio: 1,
            url,
            params,
          }),
          properties: {
            title: layer.title,
            id: layer.featureType,
          },
        });
      }

      return new TileLayer({
        visible,
        source: new TileWMS({
          url: `${varConfig.geoServerUrl}/cuchi/wms`,
          params: {
            ...params,
            tiled: true,
          },
          crossOrigin: "anonymous",
        }),
        properties: {
          title: layer.title,
          id: layer.featureType,
        },
      });
    },
    [],
  );

  const addBaseMap = useCallback((map: Map) => {
    // h = roads only
    // m = standard roadmap
    // p = terrain
    // r = somehow altered roadmap
    // s = satellite only
    // t = terrain only
    // y = hybrid
    const googleLayerSatellite = new TileLayer({
      visible: false,
      type: "base",
      baseLayer: true,
      title: "Ảnh vệ tinh",
      source: new TileImage({
        url: `${varConfig.basemapUrl}/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}`,
        crossOrigin: "anonymous",
      }),
    } as BaseLayerOptions);

    const googleLayerRoadmap = new TileLayer({
      title: "Đường phố",
      visible: true,
      baseLayer: true,
      type: "base",
      source: new TileImage({
        url: `${varConfig.basemapUrl}/vt/lyrs=m&x={x}&y={y}&z={z}&hl=vi`,
        crossOrigin: "anonymous",
      }),
    } as BaseLayerOptions);

    const baseMaps = new LayerGroup({
      title: "Bản đồ nền",
      layers: [googleLayerSatellite, googleLayerRoadmap],
      visible: true,
      openInLayerSwitcher: true,
    } as GroupLayerOptions);
    map.addLayer(baseMaps);
  }, []);

  const addInteractLayer = useCallback(
    (map: Map) => {
      const customLayers = new LayerGroup({
        fold: "open",
        properties: { name: "group-sdd" },
        displayInLayerSwitcher: false,
        layers: [featureOverlay, interactLayer],
        zIndex: 2,
      } as GroupLayerOptions);
      map.addLayer(customLayers);
    },
    [featureOverlay, interactLayer],
  );

  useEffect(() => {
    const view = new View({
      center: olProj.fromLonLat(CENTER),
      zoom: ZOOM,
      resolution: 42,
      minResolution: 0.06,
      maxResolution: 42,
    });

    const mapView = new Map({
      target: "map",
      view,
      controls: defaults({ zoom: false, attribution: false }),
    });
    mapView.setView(view);
    addBaseMap(mapView);
    getLayers().then((resultGr) => {
      const {
        layerGroups: {
          layerGroup: [lyrGr],
        },
      } = resultGr.data;
      if (lyrGr) {
        const { href } = lyrGr;
        instanceAxios
          .get<{
            layerGroup: { publishables: { published: Array<{ "@type": string; name: string; href: string }> } };
          }>(href, { headers: { "Content-Type": "application/json" } })
          .then((resultLyr) => {
            const {
              layerGroup: {
                publishables: { published },
              },
            } = resultLyr.data;
            let privileges = permissions["public"];
            const userInfo = document.cookie.replace(/(?:(?:^|.*;\s*)UserInfo\s*=\s*([^;]*).*$)|^.*$/, "$1");
            if (userInfo) {
              const userInforParse = JSON.parse(userInfo);
              privileges = userInforParse.PermFeatures;
            }
            layerGroups.layerGroups.forEach((lgr) => {
              const layers = [];
              for (const layer of published) {
                const [_, layerName] = layer.name.split(":");
                if (lgr.featureTypes.includes(layerName)) {
                  const layerAccess = privileges.find((f) => f.featureType === layerName);
                  if (layerAccess) {
                    const imageLayer = createTileLayer(layerAccess as any);
                    layers.push(imageLayer);
                  }
                }
              }
              layers.length &&
                mapView.addLayer(
                  new LayerGroup({
                    properties: { name: lgr.name },
                    layers: layers,
                    title: lgr.title,
                    openInLayerSwitcher: true,
                    zIndex: 1,
                  } as GroupLayerOptions),
                );
            });
            addInteractLayer(mapView);
          });
      }
    });

    var ctrl = new ExtLayerSwitcher({
      show_progress: false,
      reordering: false,
      mouseover: false,
    });
    ctrl.setHeader("<h4>Bảng điều khiển hiển thị lớp dữ liệu</h4>");
    mapView.addControl(ctrl);

    // Add control
    var geoloc = new GeolocationButton({
      title: "Vị trí của tôi",
      delay: 60000,
      className: "btn-control",
    });
    mapView.addControl(geoloc);
    mapView.addControl(new CanvasAttribution({ canvas: true }));
    mapView.once("loadend", () => {
      setMap(mapView);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isMeasure, setIsMeasure] = useState(false);

  useEffect(() => {
    let evtKeyClick: EventsKey;
    if (map && !isMeasure) {
      evtKeyClick = map.on("click", handleClickMap);
    }
    return () => {
      if (evtKeyClick) {
        unByKey(evtKeyClick);
      }
    };
  }, [map, handleClickMap, isMeasure]);

  const onStartMeasure = useCallback(() => {
    setIsMeasure(true);
  }, []);

  const onEndMeasure = useCallback(() => {
    setIsMeasure(false);
  }, []);

  return (
    <>
      <div id="map" />
      <TabComponent selected={TabEnum.search}>
        <TabItem titleBtn="Tìm kiếm" icon={<FcSearch />}>
          <TabSearch map={map} />
        </TabItem>
        <TabItem titleBtn="Thông tin" icon={<FcAbout />} observableShowValue={fid}>
          <PopupComponent map={map} data={content} desc={moTaHienTrangVoiQuyHoach} />
        </TabItem>
        <TabItem titleBtn="Ghi chú" icon={<FcViewDetails />}>
          <div style={{ overflowY: "auto", height: "100%" }} className="custom-scrollbar">
            <LegendComponent />
          </div>
        </TabItem>
      </TabComponent>
      <ControlPrintDialog map={map} />
      {map !== undefined && (
        <>
          <Measure map={map} onEndMeasure={onEndMeasure} onStartMeasure={onStartMeasure} />
          <RangeOpacityComponent map={map} />
        </>
      )}
      <div id="switcher" />
    </>
  );
}

export default MapComponent;
