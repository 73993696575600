import { useContext, useEffect } from "react";
import MapContext from "../Map/MapContext";
import OLTileLayer from "ol/layer/Tile";
import { Options } from "ol/layer/BaseTile";
import TileSource from "ol/source/Tile";
import { useLayerGroupContext } from "../LayerGroup/LayerGroupContext";

const TileLayer = ({ source, visible, zIndex = 0, ...rest }: Options<TileSource>) => {
  const { map } = useContext(MapContext);
  const { layerGroup } = useLayerGroupContext();

  useEffect(() => {
    if (!map) return;

    let tileLayer = new OLTileLayer({
      ...rest,
      source,
      zIndex,
      visible,
    });

    if (layerGroup) {
      const layers = layerGroup.getLayers();
      layers.push(tileLayer);
      layerGroup.setLayers(layers);
      return;
    }

    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);

    return () => {
      if (map && !layerGroup) {
        map.removeLayer(tileLayer);
      }
    };
  }, [layerGroup, map, rest, source, visible, zIndex]);

  return null;
};

export default TileLayer;
