import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { LayerName } from "../../../common/constants";
import { dmPhuongXa, DomainPhuongXa } from "../../../common/domain";
import TableComponent, { FilterTable, TableCols } from "../../../components/table/TableComponent";
import Button from "../../../Shared/Button/Button";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";

interface ListHienTrangProps {}

const ListQuyHoach: FC<ListHienTrangProps> = () => {
  const styles = {
    groupFilter: {
      display: "flex",
      width: "100%",
      gap: 16,
    },
  };
  const [filter] = useState<Array<FilterTable>>([{ propertyName: "TenCongTrinhXayDung", value: "", operator: "like" }]);

  // const onChange = useCallback(
  //   (index: number, event: ChangeEvent<HTMLInputElement>) => {
  //     const filterClone = [...filter];
  //     const { value } = event.target;
  //     filterClone[index].value = value;
  //     setFilter(filterClone);
  //   },
  //   [filter],
  // );

  const columns: TableCols = [
    { name: "MucDichSuDungDat", label: "Mục đích sử dụng đất" },
    { name: "DienTich", label: "Diện tích" },
    { name: "TenCongTrinhXayDung", label: "Tên công trình xây dựng" },
    { name: "LoaiQuyHoach", label: "Loại quy hoạch" },
    { name: "MaSo", label: "Mã số" },
    { name: "MaOPho", label: "Mã ô phố", width: "100px" },
    { name: "MaQuyUoc", label: "Mã quy ước" },
    { name: "ChucNang", label: "Chức năng", width: "100px" },
    { name: "DanSo", label: "Dân số", width: "100px" },
    { name: "TangCao", label: "Tầng cao", width: "100px" },
    { name: "MatDo", label: "Mật độ (%)", width: "100px" },
    { name: "HeSoSDD", label: "Hệ số sử dụng đất", width: "100px" },
  ];
  return (
    <div className="container">
      {/* <div style={styles.groupFilter}>
        {filter.map((m, i) => (
          <InputFloatLabel
            placeholder={m.propertyName}
            name={m.propertyName}
            onChange={(evt) => onChange(i, evt)}
            value={m.value}
          />
        ))}
      </div>
      <Button style={{ float: "right", marginTop: 12 }}>Tìm kiếm</Button> */}
      <TableComponent columns={columns} layerName={LayerName.QUY_HOACH} filter={filter} title="Danh sách thửa đất" />
    </div>
  );
};

export default ListQuyHoach;
