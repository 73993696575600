import { FeatureCollection } from "geojson";
import { equalTo } from "ol/format/filter";
import { MultiPolygon } from "geojson";
import { FC, useEffect, useMemo, useState } from "react";
import { LayerName } from "../../common/constants";
import { HienTrangSDD } from "../../common/definitions";
import useQuery from "../../hooks/useQuery";
import useWriteTransaction from "../../services/transaction.service";
import { dmPhuongXa } from "../../common/domain";

interface LogBienDongProps {}

const LogBienDong: FC<LogBienDongProps> = () => {
  const { getFeatures, getFeature } = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);
  const { searchParams } = useQuery();
  const fid = useMemo(() => searchParams.get("fid"), [searchParams]);
  const [data, setData] = useState<Array<HienTrangSDD>>([]);
  useEffect(() => {
    if (fid) {
      const [_, id] = fid.split(".");
      getFeature(fid).then(({ features }) => {
        const [feature] = features;
        if (feature) {
          const { parent_id } = feature.properties;
          const promises: Array<Promise<FeatureCollection<MultiPolygon, HienTrangSDD>>> = [
            getFeatures({ filter: equalTo("parent_id", id) }),
          ];
          // tách thửa
          // getFeatures({ filter: equalTo("parent_id", id) }).then(({ features }) => {
          //   const hienTrang = features.map((m) => m.properties);
          //   setData(hienTrang);
          // });
          // ghép thửa
          if (parent_id) {
            promises.push(getFeature(`${LayerName.HIEN_TRANG}.${parent_id}`));
            // getFeature(`${LayerName.HIEN_TRANG}.${parent_id}`).then(({ features }) => {});
          }
          Promise.all(promises).then(([featuresTachThua, featuresGhepThua]) => {
            let hienTrangs: Array<HienTrangSDD> = [];
            if (featuresGhepThua && featuresGhepThua.features.length > 0) {
              hienTrangs = featuresGhepThua.features.map((m) => m.properties);
            }
            if (featuresTachThua && featuresTachThua.features.length > 0) {
              hienTrangs = featuresTachThua.features.map((m) => m.properties);
            }
            setData(hienTrangs);
          });
        }
      });
    }
  }, [fid, getFeature, getFeatures]);

  return (
    <div>
      {data.length > 0 && (
        <h4 style={{ textAlign: "center", marginTop: 28, marginBottom: 12 }}>Thông tin biến động thửa đất</h4>
      )}
      <div style={{ fontSize: 14 }}>
        {data.length > 1 && (
          <>
            <p>Thửa đất được ghép từ những thửa sau:</p>
            <ol style={{ fontSize: 14, marginLeft: 20 }}>
              {data.map((dt) => (
                <li style={{ padding: "4px 0" }}>
                  Tờ số <strong>{dt.SoTo}</strong> - thửa <strong>{dt.SoThua}</strong> - {dmPhuongXa[dt.MaPX]}
                </li>
              ))}{" "}
            </ol>
          </>
        )}
        {data.length === 1 &&
          `Thửa đất được tách từ thửa có số tờ ${data[0].SoTo}, số thửa ${data[0].SoThua}, ${dmPhuongXa[data[0].MaPX]}`}
      </div>
    </div>
  );
};

export default LogBienDong;
