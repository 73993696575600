import WFS, { WriteTransactionOptions } from "ol/format/WFS";
import React, { ChangeEvent, FC, InputHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { LayerName, SRSNAME, WORKSPACES } from "../../../common/constants";
import { varConfig } from "../../../common/var-config";
import { equalTo } from "ol/format/filter";
import { useParams } from "react-router-dom";
import instanceAxios from "../../../common/utils";
import { FeatureCollection, Geometry } from "geojson";
import { HienTrangSDD, QuyHoachSDD } from "../../../common/definitions";
import useTransaction from "../../../services/transaction";
import InputFloatLabel from "../../../Shared/input/InputFloatLabel";
import Button from "../../../Shared/Button/Button";
import FormatGeoJSON from "ol/format/GeoJSON";
import { Feature } from "ol";

interface EditQuyHoachProps {}

const EditQuyHoach: FC<EditQuyHoachProps> = () => {
  const formatWfs = new WFS();
  const formatGML: WriteTransactionOptions = {
    srsName: SRSNAME,
    featureNS: varConfig.geoServerUrl,
    featureType: LayerName.QUY_HOACH,
    featurePrefix: WORKSPACES,
    nativeElements: [],
  };
  const transaction = useTransaction<QuyHoachSDD>(LayerName.QUY_HOACH);
  const [quyHoachSdd, setQuyHoachSdd] = useState<Partial<QuyHoachSDD>>({});
  const [feature, setFeature] = useState<Feature>();

  const { id } = useParams();

  const fid = useMemo(() => id, [id]);

  const updateFeature = useCallback(
    (e: any) => {
      e.preventDefault();
      if (fid && feature) {
        const ftn = new Feature();
        ftn.setProperties(quyHoachSdd);
        ftn.setId(fid);
        const featureRequest = formatWfs.writeTransaction([], [ftn], [], formatGML);
        const xs = new XMLSerializer();
        const payload = xs.serializeToString(featureRequest);
        return instanceAxios
          .post<
            FeatureCollection<Geometry, QuyHoachSDD> & {
              numberMatched: number;
              numberReturned: number;
              timeStamp: string;
              totalFeatures: number;
            }
          >("/wfs", payload, { headers: { "Content-Type": "text/xml" } })
          .then(({ status }) => {
            if (status === 200) {
              alert("Cập nhật thành công!");
            }
          });
      }
    },
    [fid, feature, quyHoachSdd, formatWfs, formatGML],
  );

  const styles = {
    from: {
      margin: "100px 300px",
    },
    group: {
      display: "flex",
      width: "100%",
      gap: 16,
    },
    input: {
      width: "50%",
    },
  };

  useEffect(() => {
    if (fid) {
      transaction.getFeatureById(fid).then(({ features }) => {
        const [_feature] = features;
        const geoJsonFormat = new FormatGeoJSON();
        const featureReaded = geoJsonFormat.readFeature(_feature);
        setQuyHoachSdd(_feature.properties);
        setFeature(featureReaded);
      });
    }
  }, []);

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    setQuyHoachSdd({ ...quyHoachSdd, [name]: value });
  };

  return (
    <form style={styles.from}>
      {quyHoachSdd && (
        <>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.MucDichSuDungDat}
                placeholder="Mục đích sử dụng đất"
                name="MucDichSuDungDat"
                onChange={handleChange}
              />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.DienTich}
                placeholder="Diện tích"
                name="DienTich"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.TenCongTrinhXayDung}
                placeholder="Tên công trình xây dựng"
                name="TenCongTrinhXayDung"
                onChange={handleChange}
              />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.LoaiQuyHoach}
                placeholder="Loại quy hoạch"
                name="LoaiQuyHoach"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel value={quyHoachSdd.MaSo} placeholder="Mã số" name="MaSo" onChange={handleChange} />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.MaOPho}
                placeholder="Mã ô phố"
                name="MaOPho"
                onChange={handleChange}
              />
            </div>

            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.ChucNang}
                placeholder="Chức năng"
                name="ChucNang"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel value={quyHoachSdd.DanSo} placeholder="Dân số" name="DanSo" onChange={handleChange} />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.TangCao}
                placeholder="Tầng cao"
                name="TangCao"
                onChange={handleChange}
              />
            </div>

            <div style={styles.input}>
              <InputFloatLabel value={quyHoachSdd.MatDo} placeholder="Mật độ" name="MatDo" onChange={handleChange} />
            </div>
          </div>
          <div style={styles.group}>
            <div style={styles.input}>
              <InputFloatLabel value={quyHoachSdd.MaSo} placeholder="Mã số" name="MaSo" onChange={handleChange} />
            </div>
            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.HeSoSDD}
                placeholder="Hệ số sử dụng đất"
                name="HeSoSDD"
                onChange={handleChange}
              />
            </div>

            <div style={styles.input}>
              <InputFloatLabel
                value={quyHoachSdd.MaQuyUoc}
                placeholder="Mã quy ước"
                name="MaQuyUoc"
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ float: "right", marginTop: "12px" }}>
            <Button onClick={updateFeature}>Lưu</Button>
          </div>
        </>
      )}
    </form>
  );
};

export default EditQuyHoach;
