import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./tooltip.css";

type DirectionTooltip = "left" | "right" | "top" | "bottom";
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
  direction?: DirectionTooltip;
  xsDirection?: DirectionTooltip;
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const breakPoint = 480;

const Tooltip: React.FunctionComponent<Props> = ({
  children,
  text,
  direction = "right",
  className,
  xsDirection,
  ...props
}) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const handleWindowResize = useCallback(() => {
    setWindowSize(getWindowSize());
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const poptipClassName = useMemo(
    () => `cell poptip--${windowSize.innerWidth < breakPoint && xsDirection !== undefined ? xsDirection : direction}`,
    [direction, windowSize.innerWidth, xsDirection],
  );

  return (
    <div {...props} className={classNames(poptipClassName, className)} aria-controls={text}>
      {children}
    </div>
  );
};

export default Tooltip;
