import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import instanceAxios from "../../common/utils";
import "./legend.css";

interface LegendComponentProps {}

enum LegendType {
  CV,
  TT,
  CTTI,
}

type InfoLegend = {
  title?: string;
  color?: string;
};

// const legendOptions = {
//   REQUEST: "GetLegendGraphic",
//   VERSION: "1.0.0",
//   FORMAT: "image/png",
//   WIDTH: "40",
//   HEIGHT: "40",
//   LAYER: "cuchi:congtrinh_tienich",
//   LEGEND_OPTIONS:
//     "fontName:Times NewRoman;fontAntiAliasing:true;fontColor:0x000033;fontSize:14;bgColor:0xFFFFEE;dpi:180",
// };

const LegendComponent: React.FC<LegendComponentProps> = () => {
  const [cv4605Legend, setCV4605Legend] = useState<Array<InfoLegend>>([]);
  const [tt75Legend, setTT75Legend] = useState<Array<InfoLegend>>([]);
  const [legendType, setLegendType] = useState<LegendType>(LegendType.CV);
  // const [resource, setResource] = useState<Array<string>>([]);

  // const createLegendUrl = useCallback(() => {
  //   const featureTypes = ["ht_sudungdat", "quyhoachsddcaphuyen", "contrinhtienich"];
  //   const searchParams = featureTypes.map(
  //     (ft) => `${varConfig.geoServerUrl}/wms?${new URLSearchParams({ ...legendOptions, LAYER: `cuchi:${ft}` })}`,
  //   );

  //   setResource(searchParams);
  //   // return `${varConfig.geoServerUrl}/wms?${searchParams}`;
  // }, []);

  const getSldQhpkSuDungDat = useCallback(() => {
    instanceAxios.get(`rest/workspaces/cuchi/styles/qhpk_sudungdat.sld`).then(({ data }) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(data, "text/xml");
      const rules = xml.querySelectorAll("Rule");
      const infos: Array<InfoLegend> = [];
      rules.forEach((rule) => {
        const titleEls = rule.querySelector("Name");
        const colorEls = rule.querySelector("PolygonSymbolizer")?.querySelector("Fill")?.querySelector("CssParameter");
        if (titleEls && colorEls) {
          infos.push({
            title: titleEls.textContent || "",
            color: colorEls.textContent || "",
          });
        }
      });
      setCV4605Legend(infos);
    });
  }, []);

  const getSldSddCapHuyen = useCallback(() => {
    instanceAxios.get(`rest/workspaces/cuchi/styles/quyhoachsddcaphuyen.sld`).then(({ data }) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(data, "text/xml");
      const rules = xml.querySelectorAll("Rule");
      const infos: Array<InfoLegend> = [];
      rules.forEach((rule) => {
        const titleEls = rule.querySelector("Name");
        const fillElement = rule.querySelector("PolygonSymbolizer")?.querySelector("Fill");
        const colorEls =
          fillElement?.querySelector("CssParameter")?.querySelector("Literal") ||
          fillElement?.querySelector("CssParameter");
        if (titleEls && colorEls) {
          infos.push({
            title: titleEls.textContent || "",
            color: colorEls.textContent || "",
          });
        }
      });
      setTT75Legend(infos);
    });
  }, []);

  useEffect(() => {
    getSldQhpkSuDungDat();
    getSldSddCapHuyen();
    // createLegendUrl();
  }, [getSldQhpkSuDungDat, getSldSddCapHuyen]);

  return (
    <div className="legend-qhpk">
      <div className="legend-title">
        <div
          className={classNames("legend-title-item", { active: legendType === LegendType.CV })}
          onClick={() => setLegendType(LegendType.CV)}
        >
          Quy hoạch phân khu/chi tiết
        </div>
        <div
          className={classNames("legend-title-item", { active: legendType === LegendType.TT })}
          onClick={() => setLegendType(LegendType.TT)}
        >
          Quy hoạch Tài nguyên môi trường
        </div>
        <div
          className={classNames("legend-title-item", { active: legendType === LegendType.CTTI })}
          onClick={() => setLegendType(LegendType.CTTI)}
        >
          Công trình, tiện ích
        </div>
      </div>
      <div className={classNames("legend-content", { active: legendType === LegendType.CV })}>
        <div className="legend-info">
          Phân loại chức năng sử dụng đất dựa theo quy định trong Công văn số 4605/SQHKT-QLTHQH-PC của Sở Quy hoạch -
          Kiến trúc, thành phố Hồ Chí Minh
        </div>
        {cv4605Legend.map((m, i) => (
          <div className="legend-item" key={"lg+" + i}>
            <div className="legend-item-color" style={{ backgroundColor: m.color }}></div>
            <div className="legend-item-text">{m.title}</div>
          </div>
        ))}
      </div>
      <div className={classNames("legend-content", { active: legendType === LegendType.TT })}>
        <div className="legend-info">
          Phân loại chức năng sử dụng đất dựa theo quy định trong Phụ lục III của Thông tư số 75/2015/TT-BTNMT ngày 28
          tháng 12 năm 2015 của Bộ trưởng Bộ Tài nguyên và Môi trường
        </div>
        {tt75Legend.map((m, i) => (
          <div className="legend-item" key={"lg+" + i}>
            <div className="legend-item-color" style={{ backgroundColor: m.color }}></div>
            <div className="legend-item-text">{m.title}</div>
          </div>
        ))}
      </div>
      <div className={classNames("legend-content", { active: legendType === LegendType.CTTI })}>
        <img
          src="https://qhdt.lsat.vn/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=40&HEIGHT=40&LAYER=cuchi:congtrinh_tienich&LEGEND_OPTIONS=fontName:arial;fontSize:14;dpi:110"
          alt="building legend"
        />
      </div>
    </div>
  );
};

export default LegendComponent;
