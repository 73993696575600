import React, { useCallback, useEffect, useState } from "react";
import { Domain } from "../../common/domain";
import useDistrict from "../../services/district.service";
import SelectFloatLabel, { Option, SelectFloatLabelProps } from "./SelectFloatLabel";

interface SelectDistrictProps extends SelectFloatLabelProps {}

const SelectDistrict: React.FC<SelectDistrictProps> = (props) => {
  const [dmPhuongXas, setDmPhuongXas] = useState<Array<Domain>>([]);
  const district = useDistrict();
  const getDistrict = useCallback(() => {
    district.getDistrict().then(setDmPhuongXas);
  }, [district]);

  useEffect(() => {
    getDistrict();
  }, []);

  return (
    <SelectFloatLabel {...props} placeholder="Phường/xã">
      <Option value={""}>Chọn phường/xã</Option>
      {dmPhuongXas.map((px) => (
        <Option key={px.code} value={px.code}>
          {px.name}
        </Option>
      ))}
    </SelectFloatLabel>
  );
};

export default SelectDistrict;
