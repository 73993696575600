import { FC } from "react";
import { formatNumber } from "../../common/utils";
import "./popup.css";

type RowDetail = {
  title: string;
  name: string;
  unit?: { __html: string };
  type?: "number" | "text";
  domain?: { [key: string | number]: any };
};

export type RowDetails = Array<RowDetail>;

interface DetailProps {
  rows: RowDetails;
  data: { [k: string]: any };
}

const Detail: FC<DetailProps> = ({ rows, data }) => {
  return (
    <div className="detail-wrapper">
      {rows.map((row, i) => (
        <div className="detail-group" key={i + row.name}>
          <div className="detail-label">{row.title}</div>
          <div className="detail-text">
            {row.type === "number"
              ? formatNumber(data[row.name])
              : row.domain
              ? row.domain[data[row.name]]
              : data[row.name]}
            {data[row.name] && row.unit ? <span dangerouslySetInnerHTML={row.unit} /> : ""}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Detail;
