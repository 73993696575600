import { Feature as olFeature, Map, View } from "ol";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./export.css";
import jsPdf from "./pdfjs";
import TileLayer from "ol/layer/Tile";
import { varConfig } from "../../common/var-config";
import { LayerName, SRSNAME } from "../../common/constants";
import { ImageWMS, OSM } from "ol/source";
import useWriteTransaction from "../../services/transaction.service";
import { intersects as filterIntesects } from "ol/format/filter";
import { readFeature } from "../../common/map.util";
import { Vector } from "ol/layer";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import { Geometry, MultiPolygon, Polygon } from "ol/geom";
import ImageLayer from "ol/layer/Image";
import { FeatureGeoJson, HienTrangSDD, QHCSuDungDat, QuyHoachSDD } from "../../common/definitions";
import { dmLoaiQuyHoach, dmMucDichSdd, dmPhuongXa } from "../../common/domain";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import * as turf from "@turf/turf";
import { useParams } from "react-router-dom";
import autoTable from "jspdf-autotable";
import { formatNumber } from "../../common/utils";
import { Icon, Image } from "ol/style";
import logo from "../../assets/logo192.png";

interface ExportInfoQuyHoachProps {}

const width = Math.round((360 * 150) / 25.4);
const height = Math.round((300 * 150) / 25.4);
const stylesHienTrang = "ht_sudungdat_print";
const stylesQuyHoach = "qhpk_sudungdat_print";

const ExportInfoQuyHoach: FC<ExportInfoQuyHoachProps> = () => {
  const [map, setMap] = useState<Map>();
  const [mapImage, setMapImage] = useState("");
  const pdf = jsPdf;
  const { id } = useParams();
  const transactionHienTrang = useWriteTransaction<HienTrangSDD>(LayerName.HIEN_TRANG);
  const transactionQuyHoach = useWriteTransaction<QuyHoachSDD>(LayerName.QUY_HOACH);
  const [thongTinThuaDat, setThongTinThuaDat] = useState<HienTrangSDD & { tenDoAn: string; loaiQuyHoach: number }>();
  const [thongTinQuyHoach, setThongTinQuyHoach] = useState<Array<string[]>>();

  const fid = useMemo(() => id, [id]);

  const [highlightLayer] = useState<Vector<VectorSource>>(
    new Vector({
      source: new VectorSource(),
    }),
  );

  const [layerHienTrang] = useState(
    new ImageLayer({
      visible: false,
      source: new ImageWMS({
        ratio: 1,
        url: `${varConfig.geoServerUrl}/cuchi/wms`,
        params: {
          FORMAT: "image/png",
          VERSION: "1.1.1",
          STYLES: stylesHienTrang,
          LAYERS: `cuchi:${LayerName.HIEN_TRANG}`,
          exceptions: "application/vnd.ogc.se_inimage",
          CQL_FILTER: "active=true",
        },
        crossOrigin: "anonymous",
      }),
    }),
  );

  const highlightFeature = useCallback(
    (feature: olFeature<Geometry>, map: Map) => {
      feature.setStyle(
        new Style({
          fill: new Fill({
            color: "transparent",
          }),
          stroke: new Stroke({
            color: "blue", // #00FFF0
            width: 5,
          }),
        }),
      );
      highlightLayer.getSource()?.addFeature(feature);
      const geometry = feature.getGeometry();
      if (geometry) {
        const extent = geometry.getExtent();
        if (extent) {
          map?.getView().fit(extent);
        }
      }
    },
    [highlightLayer],
  );
  const createImageLayer = useCallback((bbox: string, layerName: LayerName, style?: string) => {
    return new ImageLayer({
      source: new ImageWMS({
        ratio: 1,
        url: `${varConfig.geoServerUrl}/cuchi/wms`,
        params: {
          FORMAT: "image/png",
          VERSION: "1.1.1",
          STYLES: style,
          LAYERS: `cuchi:${layerName}`,
          exceptions: "application/vnd.ogc.se_inimage",
          bbox,
        },
        crossOrigin: "anonymous",
      }),
    });
  }, []);

  const interactFeatures = useCallback(
    (feature: FeatureGeoJson<HienTrangSDD>, features: FeatureGeoJson<QuyHoachSDD>[]) => {
      const intersects: olFeature<MultiPolygon>[] = [];
      features.forEach((feat) => {
        const intersection = turf.intersect(feature, feat);
        if (intersection) intersection.properties = feat.properties;
        intersects.push(readFeature(intersection) as olFeature<MultiPolygon>);
      });
      return intersects;
    },
    [],
  );

  const mapRendercomplete = useCallback((map: Map) => {
    map.once("rendercomplete", () => {
      const mapCanvas = document.createElement("canvas");
      mapCanvas.width = width;
      mapCanvas.height = height;
      const mapContext = mapCanvas.getContext("2d");
      if (mapContext) {
        Array.prototype.forEach.call(document.querySelectorAll(".ol-layer canvas"), (canvas) => {
          if (canvas.width > 0) {
            const opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            const transform = canvas.style.transform;
            // Get the transform parameters from the style's transform matrix
            const matrix = transform
              .match(/^matrix\(([^\(]*)\)$/)[1]
              .split(",")
              .map(Number);
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
            mapContext.drawImage(canvas, 0, 0);
          }
        });
        setMapImage(mapCanvas.toDataURL("image/jpeg"));
      }
    });
  }, []);

  const getThuaDat = useCallback(() => {
    if (fid && map) {
      transactionHienTrang.getFeature(fid).then(({ features }) => {
        if (features.length) {
          const [featureHienTrang] = features;
          const featureHienTrangReaded = readFeature(featureHienTrang);
          const geometryHienTrang = featureHienTrangReaded.getGeometry();

          const printSize = [width, height];
          map.setSize(printSize);
          const bbox = geometryHienTrang?.getExtent().join(",") + "," + SRSNAME;
          map.addLayer(createImageLayer(bbox, LayerName.QUY_HOACH, "qhpk_sudungdat_print"));
          // map.addLayer(createImageLayer(bbox, LayerName.HIEN_TRANG, "ht_sudungdat_print"));
          layerHienTrang.getSource()?.updateParams({ bbox });
          layerHienTrang.setVisible(true);
          map.addLayer(highlightLayer);
          highlightFeature(featureHienTrangReaded, map);
          if (geometryHienTrang) {
            transactionQuyHoach
              .getFeatures({
                filter: filterIntesects("geom", geometryHienTrang, SRSNAME),
                featureTypes: [LayerName.DO_AN],
              })
              .then(({ features }) => {
                const doan = features.find((f) => f.id && f.id.toString().includes(LayerName.DO_AN));
                if (doan) {
                  setThongTinThuaDat({
                    ...featureHienTrang.properties,
                    tenDoAn: (doan.properties as any).TenDoAn,
                    loaiQuyHoach: doan.properties.LoaiQuyHoach,
                  });
                }
                const quyHoachFeatures = features.filter((f) => f.id && f.id !== doan?.id);
                const featuresInteract = interactFeatures(featureHienTrang, quyHoachFeatures);
                const thongTinQh: Array<string[]> = [];
                featuresInteract.forEach((f, i) => {
                  f.setStyle(
                    new Style({
                      fill: new Fill({
                        color: "transparent",
                      }),
                      stroke: new Stroke({
                        color: "blue", // #00FFF0
                        width: 5,
                      }),
                      text: new Text({
                        font: "bold 56px sans-serif",
                        text: i + 1 + "",
                        fill: new Fill({ color: "blue" }),
                        stroke: new Stroke({ color: "#000", width: 1 }),
                        overflow: true,
                      }),
                    }),
                  );
                  const { MucDichSuDungDat } = f.getProperties() as QuyHoachSDD;
                  const soTT = i + 1 + "";
                  const dienTich = formatNumber(f.getGeometry()?.getArea() || 0);
                  const chucNang = dmMucDichSdd[MucDichSuDungDat];
                  thongTinQh.push([soTT, `Khoảng ${dienTich}`, chucNang]);
                });
                setThongTinQuyHoach(thongTinQh);
                highlightLayer.getSource()?.addFeatures(featuresInteract);
                mapRendercomplete(map);
              });
          }
        }
      });
    }
  }, [createImageLayer, fid, highlightFeature, interactFeatures, map, mapRendercomplete]);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    getThuaDat();
  }, [getThuaDat]);

  useEffect(() => {
    if (map && mapImage && thongTinThuaDat && thongTinQuyHoach) {
      const soTo = `+ Số tờ: ${thongTinThuaDat.SoTo}`;
      const soThua = `+ Số thửa: ${thongTinThuaDat.SoThua}`;
      const dienTich = `+ Diện tích: ${formatNumber(thongTinThuaDat.DienTich)}m2`;
      const soNha = `+ Số nhà: ${thongTinThuaDat.SoNha || ""}`;
      const tenDuong = `+ Tên đường: ${thongTinThuaDat.TenDuong || ""}`;
      const diaChi = `+ Địa chỉ: ${dmPhuongXa[thongTinThuaDat.MaPX]}, huyện Củ Chi, thành phố Hồ Chí Minh`;
      const doAn = `+ Thuộc ${dmLoaiQuyHoach[thongTinThuaDat.loaiQuyHoach]}: Đồ án "${thongTinThuaDat.tenDoAn || ""}"`;
      const thongTinHienTrang = [soTo, soThua, dienTich, soNha, tenDuong, diaChi, doAn];
      const width = 440;
      const margin = 30;
      const marginText = margin + 10;
      const font = "TimesNewRoman";
      const fontSize = 14;
      const gapRow = 14;
      const maxWidth = (moreMargin: number = 0): number => width - margin * 2 - moreMargin;
      pdf.setFont(font);
      pdf.setFontSize(22);
      pdf.text("THÔNG TIN QUY HOẠCH", 120, 50, { align: "justify", renderingMode: "fillThenStroke" });

      pdf.setFontSize(15);
      pdf.text("I. Thông tin chung", margin, 80, { align: "left", renderingMode: "fillThenStroke" });

      pdf.setFontSize(14);
      let y = 100;
      for (let i = 0; i < thongTinHienTrang.length; i++) {
        pdf.text(thongTinHienTrang[i], marginText, y, { maxWidth: maxWidth(10) });
        y += gapRow;
      }

      pdf.setFontSize(15);
      pdf.text("II. Thông tin quy hoạch sử dụng đất", margin, 230, { align: "left", renderingMode: "fillThenStroke" });
      pdf.addImage(mapImage, "JPEG", margin, 250, maxWidth(), 300);

      autoTable(pdf, {
        head: [["STT", "Diện tích (m2)", "Chức năng"]],
        body: thongTinQuyHoach,
        theme: "grid",
        startY: 660,
        styles: { font, fontSize, textColor: "#000" },
      });

      if (iframeRef.current) {
        iframeRef.current.src = pdf.output("datauristring");
      }
    }
  }, [map, pdf, mapImage, thongTinThuaDat, thongTinQuyHoach]);

  useEffect(() => {
    const control = new ScaleLine({
      units: "metric",
      bar: true,
      steps: 4,
      text: true,
      minWidth: 140,
    });
    const resolution = 0.1;
    const map = new Map({
      controls: defaultControls().extend([control]),
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        layerHienTrang,
        // highlightLayer,
      ],
      target: "mapEl",
      view: new View({
        center: [0, 0],
        resolution,
        minResolution: resolution,
        maxResolution: resolution,
      }),
    });
    map.once("loadend", () => {
      setMap(map);
    });
  }, []);

  return (
    <>
      <iframe ref={iframeRef} src="" title="pdf" className="iframe-pdf"></iframe>
      <div id="mapEl" />
    </>
  );
};

export default ExportInfoQuyHoach;
