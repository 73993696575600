import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LayerName } from "../../../common/constants";
import { dmPhuongXa, DomainPhuongXa } from "../../../common/domain";
import TableComponent, { FilterTable, TableCols } from "../../../components/table/TableComponent";
import Button from "../../../Shared/Button/Button";

interface ListHienTrangProps {}

const ListHienTrang: FC<ListHienTrangProps> = () => {
  const navigate = useNavigate();
  const [filter] = useState<Array<FilterTable>>([
    { propertyName: "SoTo", value: "", operator: "equal" },
    { propertyName: "SoThua", value: "", operator: "equal" },
    {
      propertyName: "MaPX",
      value: "",
      operator: "equal",
      type: "select",
      domain: DomainPhuongXa,
    },
  ]);

  // const onChange = useCallback(
  //   (index: number, event: ChangeEvent<HTMLInputElement>) => {
  //     const filterClone = [...filter];
  //     const { value } = event.target;
  //     filterClone[index].value = value;
  //     setFilter(filterClone);
  //   },
  //   [filter],
  // );

  const columns: TableCols = [
    { name: "SoTo", label: "Số tờ" },
    { name: "SoThua", label: "Số thửa" },
    { name: "KyHieuLoaiDat", label: "Ký hiệu loại đất" },
    { name: "DienTich", label: "Diện tích" },
    { name: "GhiChu", label: "Ghi chú" },
    { name: "MaPX", label: "Mã phường xã", width: "100px", domain: dmPhuongXa },
    { name: "SoNha", label: "Số nhà" },
    { name: "TenDuong", label: "Tên đường", width: "100px" },
    { name: "active", label: "Trạng thái", width: "70px" },
  ];
  return (
    <div className="container">
      {/* <div style={styles.groupFilter}>
        {filter.map((m, i) => (
          <InputFloatLabel
            placeholder={m.propertyName}
            name={m.propertyName}
            onChange={(evt) => onChange(i, evt)}
            value={m.value}
          />
        ))}
      </div>
      <Button style={{ float: "right", marginTop: 12 }}>Tìm kiếm</Button> */}
      <Button style={{ position: "absolute", top: 100, right: 150 }} onClick={() => navigate("./add")}>
        Thêm mới
      </Button>
      <Button
        className="outline"
        style={{ position: "absolute", top: 102, right: 250 }}
        onClick={() => navigate("./import")}
      >
        Nhập excel
      </Button>
      <TableComponent columns={columns} layerName={LayerName.HIEN_TRANG} filter={filter} title="Danh sách thửa đất" />
    </div>
  );
};

export default ListHienTrang;
