const varConfig = {
  basemapUrl: process.env.REACT_APP_MAP_URL || "https://mt1.google.com",
  geoServerUrl: process.env.REACT_APP_GIS_URL || "https://qhdt.lsat.vn/geoserver",
  apiUrl: process.env.REACT_APP_API || "/api",
  usernameGis: process.env.REACT_APP_USERNAME_GIS || "",
  passwordGis: process.env.REACT_APP_PASSWORD_GIS || "",
  usernameApi: process.env.REACT_APP_USERNAME_API || "map",
  passwordApi: process.env.REACT_APP_PASSWORD_API || "1",
};

export { varConfig };
