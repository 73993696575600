import LayerGroup from "ol/layer/Group";
import { createContext, useContext } from "react";

const LayerGroupContext = createContext<{ layerGroup?: LayerGroup }>({});

const useLayerGroupContext = () => {
  const context = useContext(LayerGroupContext);
  if (!context) {
    throw new Error("useLayerGroupsContext was used outside of its Provider");
  }

  return context;
};

export { LayerGroupContext as default, useLayerGroupContext };
