import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { LayerName, SRSNAME } from "../../../common/constants";
import { FeatureGeoJson, HienTrangSDD, QHSuDungDatTNMT } from "../../../common/definitions";
import { readFeature } from "../../../common/map.util";
import useWriteTransaction from "../../../services/transaction.service";
import { intersects, contains } from "ol/format/filter";
import { Feature } from "ol";
import Detail, { RowDetails } from "../Detail";
import BackComponent from "../BackComponent";
import useQuery from "../../../hooks/useQuery";
import { MultiPolygon } from "ol/geom";
import useSldStyle from "../../../services/sld-style.service";
import { Domain } from "../../../common/domain";

interface InfoQHTNMTProps {
  // loadEnd: (feature: Feature) => void;
  // onClose?: () => void;
  feature: Feature<MultiPolygon>;
}

const InfoQHTNMT: FC<InfoQHTNMTProps> = ({ feature }) => {
  const writeTransactionDoAn = useWriteTransaction<QHSuDungDatTNMT>(LayerName.QHSUDUNGDAT_TNMT);
  const [properties, setProperties] = useState<Partial<QHSuDungDatTNMT>>({});
  const { getDomain } = useSldStyle("quyhoachsddcaphuyen");
  const [rows, setRows] = useState<RowDetails>([]);

  useEffect(() => {
    const geometry = feature.getGeometry();
    if (geometry) {
      writeTransactionDoAn.getFeatures({ filter: intersects("geom", geometry, SRSNAME) }).then(({ features }) => {
        let feature: FeatureGeoJson<QHSuDungDatTNMT> | undefined;
        // const feature = features.find((f) => f.properties.MucDichSuDungQH !== "DGT");
        console.log({ orgin: geometry.getArea() });
        let minArea: number | undefined;
        for (let i = 0; i < features.length; i++) {
          const f = features[i];
          const area = readFeature(f).getGeometry()?.getArea();
          if (area && f.properties.MucDichSuDungQH !== "DGT") {
            let approx = geometry.getArea() - area;
            if (approx < 0) approx = -approx;
            if (!minArea) {
              minArea = approx;
              feature = f;
            }
            if (minArea && approx < minArea) {
              feature = f;
              minArea = approx;
            }
          }
        }
        if (feature) setProperties(feature.properties);
      });
    }
    getDomain().then((data) => {
      const domain = data.reduce((dmObj: { [k: string]: any }, dm: Domain) => {
        dmObj[dm.code] = dm.name;
        return dmObj;
      }, {});
      console.log(domain);

      const rows: RowDetails = [
        { name: "VungQuyHoachCapHuyenID", title: "Vùng quy hoạch quận huyện ID" },
        { name: "MaTinh", title: "Mã tỉnh" },
        { name: "TenVungQuyHoach", title: "Tên vùng quy hoạch" },
        { name: "MucDichSuDungQH", title: "Ký hiệu mục đích sử dụng quy hoạch" },
        { name: "MucDichSuDungQH", title: "Tên mục đích sử dụng quy hoạch", domain },
        { name: "NamQuyHoach", title: "Năm quy hoạch" },
        { name: "MaHuyen", title: "Mã huyện" },
        { name: "QuyHoachCapHuyenID", title: "Quy hoạch cấp quận huyện ID" },
        { name: "TyLeBanDo", title: "Tỷ lệ bản đồ" },
        { name: "NguonThanhLap", title: "Nguồn thành lập" },
        { name: "CoQuanDuyet", title: "Cơ quan duyệt" },
        { name: "CoQuanThamDinh", title: "Cơ quan thẩm định" },
        { name: "CoQuanLap", title: "Cơ quan lập" },
        { name: "DonViTuVan", title: "Đơn vị tư vấn" },
        { name: "NgayPheDuyet", title: "Ngày phê duyệt" },
        { name: "LoaiDatHienTrang", title: "Loại đất hiện trạng" },
      ];
      setRows(rows);
    });
  }, [feature, getDomain]);

  return (
    <>
      <Detail rows={rows} data={properties} />;
    </>
  );
};

export default InfoQHTNMT;
