import { useEffect, useState } from "react";
import BackComponent from "../BackComponent";
import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { HienTrangSDD, NhuCauChuyenDoiMDSDD } from "../../../common/definitions";
import useIntegrateAPI from "../../../services/integrate-api";

interface InfoDoAnProps {
  onClose?: (isOpen: boolean) => void;
  data?: HienTrangSDD;
}

const InfoNCCDMDSSD: React.FC<InfoDoAnProps> = ({ onClose, data }) => {
  const [nhuCauCd, setNhuCauCd] = useState<NhuCauChuyenDoiMDSDD>();
  const { getNhuCauChuyenDoiMDSDD } = useIntegrateAPI();
  useEffect(() => {
    if (data) {
      const { SoTo, SoThua, MaPX } = data;
      getNhuCauChuyenDoiMDSDD(SoTo, SoThua, MaPX).then(setNhuCauCd);
    }
  }, [data, getNhuCauChuyenDoiMDSDD]);

  return (
    <>
      <BackComponent title="Thông tin chuyển đổi mục đích SDĐ" onBack={() => onClose && onClose(false)} />
      <div className="detail-wrapper">
        <div className="detail-group">
          <div className="detail-label">Mã hồ sơ</div>
          <div className="detail-text">{nhuCauCd?.MaHS}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Tên chủ sử dụng</div>
          <div className="detail-text">{nhuCauCd?.TenChuSuDung}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Địa chỉ thường trú</div>
          <div className="detail-text">{nhuCauCd?.DiaChiThuongTru}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Địa điểm</div>
          <div className="detail-text">{nhuCauCd?.DiaDiem}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Địa chỉ thửa đất</div>
          <div className="detail-text">{nhuCauCd?.DiaChiThuaDat}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số tờ</div>
          <div className="detail-text">{nhuCauCd?.SoTo}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số thửa</div>
          <div className="detail-text">{nhuCauCd?.SoThua}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">
            Diện tích (m<sup>2</sup>)
          </div>
          <div className="detail-text">{nhuCauCd?.DienTich}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Phường/xã</div>
          <div className="detail-text">{nhuCauCd?.TenPhuongXa}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Loaị đất hiện trạng theo giấy chứng nhận</div>
          <div className="detail-text">{nhuCauCd?.LoaiDatHienTrangTheoGCN}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Nhu cầu chuyển đổi mục đích</div>
          <div className="detail-text">{nhuCauCd?.NhuCauChuyenMucDich}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Thông tin quy hoạch</div>
          <div className="detail-text">{nhuCauCd?.ThongTinQuyHoach}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Giấy chứng nhận</div>
          <div className="detail-text">{nhuCauCd?.GCN}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Số điện thoại</div>
          <div className="detail-text">{nhuCauCd?.SoDienThoai}</div>
        </div>
        <div className="detail-group">
          <div className="detail-label">Ghi chú</div>
          <div className="detail-text">{nhuCauCd?.GhiChu}</div>
        </div>
      </div>
    </>
  );
};

export default InfoNCCDMDSSD;
