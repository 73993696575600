import axios from "axios";
import { varConfig } from "./var-config";

const instanceAxios = axios.create({
  baseURL: varConfig.geoServerUrl,
});

if (varConfig.usernameGis && varConfig.passwordGis) {
  instanceAxios.defaults.auth = {
    username: varConfig.usernameGis,
    password: varConfig.passwordGis,
  };
}

export default instanceAxios;

//https://blog.abelotech.com/posts/number-currency-formatting-javascript/
export function formatNumber(num: number, separator: string = ".", digits: number = 2) {
  return num
    .toFixed(digits)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
}

export const intergateAPI = axios.create({
  baseURL: varConfig.apiUrl,
});

export const hexToRgbA = (hex: string) => {
  let c: any;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
  throw new Error("Bad Hex");
};

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export const rgbToHex = (r: number, g: number, b: number) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
