import { FC } from "react";
import { NavLink, Outlet, Link } from "react-router-dom";

const Layout: FC<any> = ({ children }) => (
  <div style={{ height: "100%", overflow: "auto" }}>
    <header className="header">
      <h1>Huyện củ chi</h1>
      <div className="dropdown">
        <button>Lớp dữ liệu</button>
        <div
          style={{
            borderColor: "rgba(0, 0, 0, 0.3) transparent transparent",
            borderStyle: "solid",
            borderWidth: "8px 8px 0px",
            height: "0px",
            width: "0px",
          }}
        ></div>

        <div className="dropdown__content">
          <ul>
            <li>
              <Link to={"/manage/hien-trang"}>Hiện trạng sử dụng đất</Link>
            </li>
            <li>
              <Link to={"/manage/quy-hoach"}>Quy hoạch sử dụng đất</Link>
            </li>
            <li>
              <Link to={"/manage/cong-van-4605"}>Chú giải QHPK sử dụng đất </Link>
            </li>
            <li>
              <Link to={"/manage/thong-tu-75"}>Chú giải quy hoạch sử dụng đất TNMT</Link>
            </li>
            <li>
              <Link to={"/manage/tien-ich"}>Chú giải công trình tiện ích</Link>
            </li>
            <li>
              <Link to={"/manage/bien-dong-thua"}>Ghép/tách thửa</Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <main>
      <Outlet />
    </main>
  </div>
);
export default Layout;
