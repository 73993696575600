import { FC } from "react";
import { TileLayer } from "../Layers";
import tileImage from "../Source/tile-image";

interface GoogleRoadmapProps {}

const url = "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}";

const GoogleRoadmap: FC<GoogleRoadmapProps> = () => {
  const source = tileImage(url);
  return <TileLayer source={source} />;
};

export default GoogleRoadmap;
