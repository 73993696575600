import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import ExportInfoQuyHoach from "./components/export/ExportInfoQuyHoach";
import MapComponent from "./components/map/MapComponent";
import Layout from "./Layout";
import ListCongVan from "./manage/chu-giai/cong-van-4605/list";
import ListThongTu from "./manage/chu-giai/thong-tu-75/list";
import ListTienIch from "./manage/chu-giai/tien-ich/list";
import AddHienTrang from "./manage/hien-trang-sdd/add/AddHienTrang";
import ImportHienTrang from "./manage/hien-trang-sdd/add/ImportHienTrang";
import EditHienTrang from "./manage/hien-trang-sdd/edit/EditHienTrang";
import ListHienTrang from "./manage/hien-trang-sdd/list/ListHienTrang";
import EditQuyHoach from "./manage/qhqk-sdd/edit/EditQuyHoach";
import ListQuyHoach from "./manage/qhqk-sdd/list/ListQuyHoach";
import MapHienTrang from "./manage/tach-ghep-thua/MapHienTrang";
import TachGhepThua from "./manage/tach-ghep-thua/TachGhepThua";
import TestMap from "./manage/test/Map";
import MapPage from "./upgrade/MapPage";

const Routes = () => {
  const routes: RouteObject[] = [
    { path: "/", element: <Navigate to="/map" /> },
    {
      path: "map",
      children: [
        { index: true, element: <MapComponent /> },
        { path: ":param", element: <MapComponent /> },
        { path: ":param/export/:id", element: <ExportInfoQuyHoach /> },
      ],
    },
    {
      path: "/manage",
      element: <Layout />,
      children: [
        {
          path: "hien-trang",
          children: [
            { index: true, element: <ListHienTrang /> },
            { path: ":id", element: <EditHienTrang /> },
            { path: "add", element: <AddHienTrang /> },
            { path: "import", element: <ImportHienTrang /> },
          ],
        },
        {
          path: "quy-hoach",
          children: [
            { index: true, element: <ListQuyHoach /> },
            { path: ":id", element: <EditQuyHoach /> },
          ],
        },
        {
          path: "cong-van-4605",
          element: <ListCongVan />,
        },
        {
          path: "thong-tu-75",
          element: <ListThongTu />,
        },
        {
          path: "tien-ich",
          element: <ListTienIch />,
        },
        {
          path: "bien-dong-thua",
          element: <MapHienTrang />,
        },
      ],
    },
    {
      path: "cmap",
      element: <MapPage />,
    },
  ];

  return useRoutes(routes);
};

export default Routes;
